@import '../../../../assets/styles/index.scss';

.discussion-group-item {
  padding: 8px 12px;
  color: $neutral-1000;
  cursor: pointer;
  @include lineClamp(1);
  border-radius: 8px;

  &--is-active {
    @include bodyTwo;
    @include semiBoldTypography;
    color: $primary-600;
    background: $primary-100;
  }
}
