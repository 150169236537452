@import '../../../assets/styles/index.scss';

@mixin barAnimation {
  animation: movingBar 1.5s ease-in infinite;

  @keyframes movingBar {
    0% {
      left: 0px;
      width: 120px;
      visibility: visible;
    }
    90% {
      left: 300px;
      width: 20px;
      visibility: visible;
    }
    91% {
      left: 300px;
      width: 25px;
      visibility: hidden;
    }
    100% {
      left: -100px;
      width: 100px;
      visibility: hidden;
    }
  }
}

.page-loader {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $background-100;
  &--is-visible {
    @include flexCenterContent;
  }

  &__content {
    @include flexCol;
    align-items: center;
    gap: 24px;
    max-width: 271px;
    &__logo {
      max-width: 256px;
    }
    &__title {
      color: $neutral-1000;
      text-align: center;
      &--highlight {
        color: $primary-600;
      }
    }
    &__loading-container {
      position: relative;
      width: 100%;
      height: 4px;
      border-radius: 100px;
      background: $primary-200;
      overflow: hidden;
      &__bar {
        position: absolute;
        top: 0;
        height: 100%;
        border-radius: 100px;
        background: $primary-600;
        @include barAnimation;
      }
    }
  }
}
