@import '../../../assets/styles/index.scss';

.dropdown-component {
  position: absolute;
  @include flexCol;
  min-width: 200px;
  display: none;
  border-radius: 8px;
  border: 1px solid $primary-200;
  top: 100%;
  right: 10px;
  overflow: hidden;
  box-shadow: $shadow-100;
  .dropdown-component__card {
    justify-content: flex-start;
  }
  &--is-open {
    display: flex;
  }
}
