.detatils {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    margin: 0 !important;
  }
  ul {
    li {
      margin: 0 !important;
    }
  }
}
