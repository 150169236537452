.report {
  :global(.emptyContainerBoxes) {
    p {
      margin: 0;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .titleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .title {
      font-size: 16px;
      margin: 0;
    }
  }
}
