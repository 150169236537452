@import '../../../../../../assets/styles/index.scss';

.submitted-application-card {
  @include flexCol;
  gap: 8px;
  color: $neutral-1000;
  height: 100%;

  &__header {
    @include flexRow;
    align-items: center;
    justify-content: space-between;

    &__left {
      @include flexRow;
      align-items: center;
      gap: 16px;

      &__user-info {
        @include flexCol;
        gap: 4x;

        &__app-no {
          color: $neutral-800;
        }
      }
    }

    &__stage {
      @include flexRow;
      align-items: center;
      gap: 8px;
    }
  }

  &__external-user {
    padding: 4px 8px;
    width: fit-content;
    background: $primary-100;
    color: $primary-600;
  }

  &__footer {
    @include flexRow;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    button {
      width: 107px;
    }
  }
}
