@import '../../../../assets/styles/index.scss';

.discussion-messages {
  @include flexCol;
  padding: 16px;
  gap: 12px;
  height: inherit;
}

@media screen and (min-width: $desktop) {
  .chat-messages {
    padding: 24px;
  }
}
