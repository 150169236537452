.forInvestSec {
    padding-top: 140px;
    padding-bottom: 80px;

    .content_main {
        h2 {
            font-size: 24px;
            font-weight: 700;
            color: #303030;
            margin-bottom: 14px;
        }

        p {
            color: #303030;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            margin-bottom: 40px;
        }
    }

    .img_box {
        img {
            width: 100%;
            height: auto;
        }
    }
}

.btnBox {
    :global(.btn) {
        border: none;
        background-color: #ff5924;
        color: #fff;
        border-radius: 12px;
        font-size: 20px;
        font-weight: 500;
        padding: 18px 35px;
        display: inline-flex;
        align-items: center;
        gap: 7px;
        box-shadow: 0px 30px 30px 0px #ff59241a;
        text-decoration: none;

        &:active {
            background-color: #ff5924;
            color: #fff;
        }
    }
}

.video_testimonial {
    padding-bottom: 60px;

    .video_box {
        width: 100%;
        height: 240px;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 20px;

        video {
            width: 100%;
            height: 100%;
        }
    }

    .content_box {
        padding-left: 24px;
        margin-bottom: 20px;

        h2 {
            font-size: 24px;
            font-weight: 700;
            color: #303030;
            margin-bottom: 14px;
        }

        ol {
            margin-bottom: 0px;
            list-style: none;
            padding-left: 18px;

            li {
                font-weight: 500;
                color: #303030;
                margin-bottom: 9px;
                line-height: 22px;

                span {
                    font-weight: 600;
                    font-size: 16px;
                }
            }
        }
    }
}

// <<<<<<<<<<media query>>>>>>>>>

@media only screen and (max-width: 991px) {
    .forInvestSec {
        padding-top: 130px;
        padding-bottom: 45px;

        .content_main {
            h2 {
                margin-bottom: 8px;
            }

            p {
                margin-bottom: 27px;
            }
        }
    }

    .btnBox {
        :global(.btn) {
            font-size: 16px;
            padding: 12px 27px;
        }
    }

    .video_testimonial {
        padding-bottom: 40px;
        .content_box {
            padding-left: 2px;
        }
    }
}