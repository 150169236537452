@import '../../../../assets/styles/index.scss';

.manage-access-modal {
  .modal-component {
    height: 550px;
  }

  &__content {
    flex: 1;
    overflow-y: hidden !important;
    .manage-access {
      @include flexCol;
      gap: 16px;
      height: 462px;

      .tabs-component {
        height: 100%;
      }

      &__buttons {
        @include flexRow;
        justify-content: center;
        gap: 8px;
        margin-top: auto;

        button {
          width: 150px;
        }
      }
    }
  }
}
