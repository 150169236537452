@import '../../../assets/styles/index.scss';

.idea-skeleton {
  @include flexCol;
  gap: 16px;

  &__breadcrumbs {
    @include flexRow;
    align-items: center;
    gap: 8px;
    color: $neutral-1100;
  }
}

@media screen and (min-width: $tablet) {
  .idea-skeleton {
    gap: 24px;
  }
}
