.onboard {
  .titleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .title {
      font-size: 16px;
      margin: 0;
    }
  }
  .container {
    .inputFields {
      margin: 0 auto;
      margin-bottom: 14px;
    }
    .logolabel {
      label {
        font-family: 'Manrope', sans-serif;
        font-size: 12.8px;
        line-height: 17.48px;
        letter-spacing: 0em;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .imageUploadBox {
      border: 2px solid #fa4616;
      min-height: 50px;
      max-height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      margin-bottom: 10px;
      z-index: 1;
      .uploadContent {
        text-align: center;
        .uploadLabel {
          .profileImage {
            height: 25px;
            width: 25px;
            border-radius: 50%;
          }
          .contentBox {
            .addLogoIcon {
              height: 25px;
              width: 25px;
            }
          }
          cursor: pointer;
          .uploadInput {
            display: none;
          }
        }
        .uploadTitle {
          font-size: 16px;
          margin-top: 14px;
        }
      }
    }

    .logoBox {
      margin: 7px auto;
      .box {
        height: 130px;
        width: 138px;
        border-radius: 6px;
        background-color: #eff0f5;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 24px;
        position: relative;
        cursor: pointer;

        .upload_img_remove {
          position: absolute;
          right: -8px;
          top: -10px;
          z-index: 9;
          border: none;
          background: none;
          padding: 0;
          line-height: 24px;

          :global(svg) {
            width: 20px;
            height: 20px;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border-radius: 50%;
            padding: 3px;
            color: #dd2828;
          }
        }

        .img_content_showName {
          width: 100%;
          height: 100%;
          background: linear-gradient(#65beac, #3e8575);
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
        }

        &.v2 {
          background-color: #fff;
          border: 1px solid red solid #eff0f5;
        }

        input[type='file'] {
          display: none;
        }

        .boxImage {
          border-radius: 20px;
          width: 60px;
          height: 60px;
          object-fit: cover;
          fill: #7f8699;
        }

        .bottomLogo {
          position: absolute;
          bottom: 0;
          right: 0;
          transform: translate(50%, 30%);
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
          background-color: #fff;
          border-radius: 10px;
          height: 31px;
          width: 31px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px;
          border-radius: 50%;

          img {
            width: 14px;
            cursor: pointer !important;
          }

          input {
            font-size: 2px;
            position: absolute;
            top: 50%;
            left: 0;
            opacity: 0;
          }
        }
      }

      .upload {
        font-weight: 600;
        font-size: 18px;
        color: #7f8699;
      }

      .boxDiv {
        width: 100%;
      }

      h2 {
        font-size: 18px;
        font-weight: 500;
      }

      h4 {
        color: #7f8699;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .teamCard {
    background-color: #fffbf9;
    border-radius: 8px;
    padding: 6px 10px;
    height: auto;
    min-height: 80px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #ffe5d5;
    margin: 12px 0;
    position: relative;
    .removeIcon {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-10px, 3px);
    }
    &.active {
      border: 1px solid #fa4616;
    }
    .profile {
      display: flex;
      gap: 10px;
      row-gap: 14px;
      align-items: center;
      margin-top: 10px;
      .teamDetails {
        width: 63%;
      }
    }
    .addRemoveButton {
      display: flex;
      width: 80%;
      align-content: center;
      justify-content: space-between;
      margin: 6px auto;
      span {
        font-size: 18px;
        color: #fa4616;
        background: #fff;
        border: 1px solid #ffe5d5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30px;
        min-width: 30px;
        cursor: pointer;
      }
    }
  }
  .teamContainer {
    max-height: 210px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5px;
    .emptyMessage {
      font-size: 13px;
      font-weight: 500;
      margin: 0;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background: #f8f8f8;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fa4616;
      border-radius: 100px;
    }
  }

  :global(.insideCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 12px 16px;
    margin-bottom: 10px;
  }
}
