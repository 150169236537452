@import '../../../assets/styles/index.scss';

.create-edit-group {
  @mixin membersGridStyling {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media screen and (min-width: $desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__modal {
    gap: 16px !important;
  }

  &__content {
    @include flexCol;
    gap: 16px;
  }

  &__form {
    @include flexCol;
    gap: 16px;
  }

  &__users {
    @include membersGridStyling;
    height: 192px;
    overflow-y: scroll;
    @include customScroll;

    &--Edit {
      height: 159px;
    }
  }

  &__buttons {
    @include flexRow;
    justify-content: center;
    gap: 8px;

    &__close,
    &__create-group {
      width: 150px;
    }
  }
}
