.radiusSlider {
  display: flex;
  align-items: center;
  gap: 10px;

  .label {
    font-family: 'Manrope', sans-serif;
    font-size: 12.8px;
    line-height: 17.48px;
    letter-spacing: 0em;
    color: black;
    font-weight: 600;
  }
  span {
    color: #f4552d;
  }
  :global(.css-15gm0yv-MuiSlider-root) {
    color: #f4552d !important;
  }
  :global(.MuiSlider-root) {
    :global(.MuiSlider-rail) {
      color: #fa4616;
      height: 10px;
    }

    :global(.MuiSlider-track) {
      color: #fa4616;
      height: 10px;
    }

    :global(.MuiSlider-thumb) {
      width: 16px;
      height: 16px;

      &:before {
        background-image: #9d2909;
      }

      :global(.MuiSlider-valueLabelOpen) {
        top: 50px;
        background: transparent;

        &:before {
          content: '';
          display: none;
        }

        :global(.MuiSlider-valueLabelCircle) {
          :global(.MuiSlider-valueLabelLabel) {
            font-size: 16px;
            color: #9d2909;

            &::after {
              content: '';
            }
          }
        }
      }
    }
  }
}
