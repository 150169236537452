@import '../../../assets/styles/index.scss';

@mixin editProfileGridStyle {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.add__global-modal {
  .modal-component{
    position: relative;

  }
  .modal-component__content {
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 44px;
  }
}

.add__global {
  &__form {
    @include flexCol;
    gap: 12px;

    &__container-crate-form {
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: calc(100vh - 220px);
    }

    &__fields {
      max-height: 364px;
      overflow-y: scroll;
      @include flexCol;
      gap: 16px;
      @include customScroll;
      padding-right: 8px;
      &__grid {
        @include editProfileGridStyle;
        &__field {
          @include flexCol;
          gap: 16px;
          color: $neutral-1100;
          input[type='date']::-webkit-calendar-picker-indicator {
            display: none;
          }
        }
      }

      &__address {
        @include flexCol;
        gap: 16px;
        color: $neutral-1100;
        &__grid {
          @include editProfileGridStyle;
        }
      }

      input {
        @include hideInputNumberFieldArrow;
      }
    }

    &__buttons {
      @include flexRow;
      justify-content: center;
      gap: 8px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      background: #fff;
      button {
        width: 150px;
      }
    }
  }
}
