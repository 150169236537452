@import '../../../assets/styles/index.scss';

.discoverMainCard {
  @include flexCol;
  gap: 7px;
  color: $neutral-1000;
  height: 100%;

  &__banner {
    width: 100%;
    height: 107px;
    max-width: 100%;
    max-height: 107px;
    object-fit: contain;
  }
  &__fake-image {
    width: 100%;
    height: 107px;
    background: rgb(0 0 0 / 32%);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
    }
  }

  &__content {
    @include flexCol;
    gap: 4px;

    &__heading {
      font-size: 14px;
      font-weight: 700;
      line-height: 19.84px;
      color: #1d1d1d;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    &__labelling {
      font-size: 12px;
      font-weight: 600;
      line-height: 16.39px;
      color: #1d1d1d;
      display: flex;
      align-items: center;
      gap: 4px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      &__logo {
        max-height: 11.67px;
        max-width: 11.67px;
        height: 11.67px;
        width: 11.67px;
        margin-right: 2px;
        margin-bottom: 3px;

        &__date {
          max-height: 11.67px;
          max-width: 11.67px;
          height: 11.67px;
          width: 11.67px;
          margin-right: 3.5px;
          margin-left: 1px;
          margin-bottom: 3px;
        }
        &__org {
          max-height: 11.67px;
          max-width: 11.67px;
          height: 11.67px;
          width: 11.67px;
          margin-right: 3.5px;
          margin-bottom: 3px;
        }
      }

      &__user {
        font-weight: 600;
        color: #ff6239;
        &__redirect {
          color: #ff6239;
          cursor: pointer;
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }

    &__para {
      color: #1d1d1d;
      font-size: 12px;
      font-weight: 500;
      margin: 8px 0;
      line-height: 16.39px;
      // overflow: hidden;
      // -o-text-overflow: ellipsis;
      // text-overflow: ellipsis;
      height: 54px;
      // display: -webkit-box;
      // -webkit-line-clamp: 3;
      // -webkit-box-orient: vertical;

      &__see-more {
        color: #6e6e6e;
      }
    }
    &__button-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      a {
        text-decoration: none;
        color: inherit;
        width: 50%;
        display: block;
        font-size: 12px;
      }
    }
  }

  &__applyBtn {
    height: 37.87px;

    &__Share {
      width: 50%;
      height: 37.87px;

      span {
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: 'center';
        gap: 3px;
        svg {
          height: 16px;
          width: 16px;
          display: block;
        }
      }
    }

    span {
      font-size: 12px;
      font-weight: 600;
    }
  }
}
