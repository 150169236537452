@import '../../../assets/styles/index.scss';

.create-comment {
  position: relative;
  @include flexRow;
  gap: 10px;
  align-items: center;

  .commentEmojiPicker{
    position: absolute;
    top: 50px;
    right: 20px;
    z-index: 9
  }

  &__fake-error{
    opacity: 0;
  }

  &__header {
    @include flexRow;
    gap: 10px;
    width: 100%;

    .input-field-component {
      flex: 1;
    }
  }

  &__emojiBtn {
    svg {
      width: 23px;
      height: auto;
      cursor: pointer;

      &:hover {
        path {
          stroke: $primary-600
        }
      }
    }
  }

  &__buttons {
    @include flexRow;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    button {
      width: 110px;
    }
  }
}