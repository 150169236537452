@import '../../assets/styles/index.scss';

.apply-to-program-external {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;

  &__body {
    @include flexCol;
    gap: 16px;
    padding: 16px 32px;

    &__step {
      @include flexCol;
      justify-content: space-between;
      gap: 16px;

      &__component {
        @include flexCol;
        gap: 16px;
        width: 100%;

        &__header {
          @include flexRow;
          align-content: center;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 16px;

          button {
            width: 162px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .apply-to-program-external {
    max-width: 1440px;
    margin: 0 auto;
    &__body {
      gap: 24px;
      padding: 32px 64px;

      &__step {
        @include flexRow;
        gap: 32px;

        &__component {
          gap: 24px;
          width: 900px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .apply-to-program-external {
    &__body {
      padding: 16px;
    }
  }
}
