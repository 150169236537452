@use '../../assets/styles/index.scss' as styles;

.dashboard {
  overflow: hidden;
  height: 100%;
  @include styles.mainPageScroll;
  &__body {
    // margin-right: 58px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    padding: 16px;
    margin-right: 0;
    margin-top: 89px;
    // margin-left: 2px;

    &__feed {
      @include styles.flexCol;
      gap: 24px;

      &__items {
        width: 100%;
        @include styles.flexCol;
        gap: 24px;
      }
      &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        &__filters {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 10px;
          width: 100%;
          button {
            width: auto;
            padding: 0 13px !important;
          }

          &__date-field {
            position: relative;
            &__input {
              position: absolute;
              bottom: 0;
              opacity: 0;
              pointer-events: none;
              width: 1px;
              height: 1px;
            }
          }

          p {
            margin: 0;
          }
        }
        &__add-button-container{
          button{
            padding:0 14px;
          }
        }
      }
    }
  }
  &__cards {
    @include styles.flexCol;
    gap: 24px;
  }
}

@media (max-width: 768px) {
  .dashboard {
    &__body {
      margin-top: 160px;
      display: flex;
      flex-direction: column;
      &__feed {
        &__actions {
          flex-direction: column;
        }
      }
    }
  }
}
@media screen and (min-width: styles.$desktop) {
  .dashboard {
    &__body {
      display: grid;
      grid-template-columns: 60.7% 36.2%;
      padding: 45px 32px 32px 32px;
      margin-top: 89px;
      // margin-right: 61px;
      margin-right: max(61px, calc((100vw - 1287px) / 2) - 63px);
    }
    &__newBody {
      padding: 16px 30px 16px 32px;
      gap: 32px;
    }
  }
}
