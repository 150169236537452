@import '../../../../../assets/styles/index.scss';

.discussion-messages-header {
  @include flexRow;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;

  &__left {
    @include flexCol;
    justify-content: flex-start;
    gap: 8px;

    &__heading {
      width: 100%;
      text-align: left;
    }

    &__members {
      @include flexRow;
      cursor: pointer;

      &__more-users {
        text-decoration: underline;
      }
    }
  }

  &__right {
    @include flexRow;
    align-items: center;
    gap: 8px;

    .dropdown-component {
      min-width: 124px;
    }
  }
}
