@import '../../../assets/styles/index.scss';

.like-component {
  @include flexRow;
  align-items: center;
  padding: 8px 16px;
  gap: 4px;
  border-radius: 100px;
  cursor: pointer;
  color: $neutral-700;
  @include hoverOrange;

  span {
    @include flexRow;
    align-items: center;
    gap: 4px;
    line-height: normal;
    font-size: 14px;
    font-weight: 600;
  }

  &--is-active {
    background: $primary-200;
    color: $primary-600;
    svg path {
      stroke: $primary-600;
    }
    
  }
}
