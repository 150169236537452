.teams {
  .chatHeading {
    font-size: 14px;
    margin: 0;
    margin-bottom: 10px;
  }
  .activityList {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 0 8px;
    li {
      margin-bottom: 4px;
      font-size: 16px;
    }
  }
  .TrashIcon {
    position: absolute;
    top: 0px;
    right: 28px;
    .dots{
      height: 18px;
      width: 18px;
    }
  }
  .TeamColumn {
    position: relative;
  }
  .deactivatedTeam {
    opacity: 0.5; // Example: dim the card to indicate deactivated status
  }

  .deactivatedText {
    color: red; // Or any color you want to signify "Deactivated"
  }

  .chatBox {
    // min-height: 250px;
    padding: 8px;
    .emptyBox {
      font-size: 13px;
      font-weight: 500;
      margin: 0;
    }
    .teamCard {
      background-color: #fffbf9;
      border-radius: 8px;
      padding: 8px 10px;
      height: 100px;
      width: 100%;
      border: 1px solid #ffe5d5;
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 14px;
      cursor: pointer;
    }
  }

  :gloabl(.transparent) {
    background: transparent !important;
  }
  :global(.insideCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 12px;
    margin-bottom: 10px;
  }
}
