@import '../../../../../assets/styles/index.scss';

.advance-application {
  &__form {
    @include flexCol;
    gap: 16px;

    &__buttons {
      @include flexRow;
      align-self: flex-end;
      gap: 16px;

      button {
        width: 150px;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .advance-application__modal {
    width: 600px !important;
  }
}
