@import '../../../assets/styles/index.scss';

.typography {
  &__display-1 {
    @include displayOne;
  }
  &__display-2 {
    @include displayTwo;
  }
  &__display-3 {
    @include displayThree;
  }
  &__heading-1 {
    @include headingOne;
  }
  &__heading-2 {
    @include headingTwo;
  }
  &__heading-3 {
    @include headingThree;
  }
  &__subHeading-1 {
    @include subHeadingOne;
  }
  &__subHeading-2 {
    @include subHeadingTwo;
  }
  &__body-1 {
    @include bodyOne;
    
  }
  &__body-2 {
    // @include bodyTwo;
    @include captionTypography;
  }
  &__body-3 {
    @include bodyTwo;
  }
  &__caption {
    @include captionTypography;
  }
  &__regular {
    @include regularTypography;
  }
  &__medium {
    @include mediumTypography;
  }
  &__semiBold {
    @include semiBoldTypography;
  }
  &_semisemibold{
    font-weight: 600
  }
  &__bold {
    @include boldTypography;
  }
  font-family: 600;
}
