@import '../../assets/styles/index.scss';

.onboarding-user-type {
  @include flexCol;
  width: 100%;
  gap: 16px;

  &__heading {
    color: $neutral-1000;
    &--highlight {
      color: $primary-600;
    }
  }

  &__select-type {
    @include flexCol;
    gap: 8px;
    &__label {
      color: $neutral-1100;
      span {
        color: $neutral-1000;
      }
    }

    &__options {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }
}
