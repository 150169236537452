@import '../../../../assets/styles/index.scss';

.create-company,
.view-company,
.my-company {
  .modal-component {
    gap: 24px;
  }
  .create-company-add-edit-service {
    &__form {
      @include flexCol;
      gap: 24px;

      &__buttons {
        @include flexRow;
        justify-content: flex-end;
        gap: 16px;
        button {
          width: 150px;
        }
      }
    }
  }
}
