@import '../../../assets/styles/index.scss';

.idea-card {
  @include flexCol;
  padding: 0;
  cursor: pointer;

  &__content {
    @include flexCol;
    padding: 16px;
    gap: 10px;
    flex: 1;

    &__header {
      @include flexRow;
      justify-content: space-between;

      &__cards {
        @include flexRow;
        gap: 12px;

        &__status {
          @include flexCenterContent;
          padding: 4px 8px;
          background: $primary-100;
          color: $primary-600;
        }
      }

      &__idea-owner {
        @include flexRow;
        gap: 8px;
        color: $neutral-1000;
        align-items: center;

        &__profile-pic {
          height: 24px;
          width: 24px;
          h3 {
            font-size: 12px;
          }
        }
      }

      &__dropdown {
        height: 24px;
        width: 24px;
        @include flexCenterContent;
        cursor: pointer;
        position: relative;
      }
    }

    &__title {
      color: $neutral-1100;
      @include lineClamp(1);
    }

    &__description {
      @include lineClamp(3);
      text-align: left;
      color: $neutral-1000;
      word-break: break-all;
    }

    &__row {
      @include flexRow;
      align-items: center;
      justify-content: space-between;
      &__status {
        @include flexCenterContent;
        padding: 4px 8px;
        background: $primary-100;
        color: $primary-600;
      }
      &__saved-time {
        text-align: right;
        color: $neutral-700;
        margin-left: auto;
      }
    }

    &__place-bottom {
      margin-top: auto;
    }
  }

  &__collaborators {
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    color: $black-100;
    background: $primary-100;

    &__pictures {
      @include flexRow;

      &__picture,
      &__remaining {
        height: 32px;
        width: 32px;
      }

      &__picture {
        border: 1px solid $neutral-200;
      }

      &__remaining {
        @include flexCenterContent;
        background: $primary-200;
        border-radius: 50%;
        color: $primary-600;
      }
    }
  }

  &__accept-idea {
    @include flexRow;
    align-items: center;
    gap: 16px;
    padding: 16px;
    color: $black-100;
    background: $primary-100;

    button {
      width: 120px;
    }
  }
}
