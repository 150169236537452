@import '../../../assets/styles/index.scss';

.invite-mentor-investor {
  &__modal {
    width: 550px !important;
  }

  &__content {
    @include flexCol;
    gap: 16px;
  }

  &__form {
    @include flexCol;
    gap: 16px;

    &__buttons {
      @include flexRow;
      gap: 24px;
    }
  }
}
