@import '../../../assets/styles/index.scss';

.popup-component-outer {
  @include modalPositioning;
  .popup-component {
    @include flexCol;
    align-items: center;
    gap: 24px;
    padding: 16px;
    border-radius: 16px;
    box-shadow: $shadow-200;
    width: 90%;
    background: $white-100;

    &__header {
      width: 100%;
      @include flexCol;
      align-items: center;
      &__close {
        align-self: flex-end;
        cursor: pointer;
        svg {
          height: 12px;
          width: 12px;
        }
      }
    }

    &__title,
    &__description {
      text-align: center;
      color: $neutral-1100;
    }

    &__button {
      width: fit-content;
      padding: 0 60px;
      cursor: pointer;
    }
  }

  #load {
    margin-top: 20px;
    width: 5em;
    transform-origin: center;
    animation: rotate4 2s linear infinite;
   }
   
   circle {
    fill: none;
    stroke: #FA4616;
    stroke-width: 4;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash4 1.5s ease-in-out infinite;
   }
   
   @keyframes rotate4 {
    100% {
     transform: rotate(360deg);
    }
   }
   
   @keyframes dash4 {
    0% {
     stroke-dasharray: 1, 200;
     stroke-dashoffset: 0;
    }
   
    50% {
     stroke-dasharray: 90, 200;
     stroke-dashoffset: -35px;
    }
   
    100% {
     stroke-dashoffset: -125px;
    }
   }
   

  @media screen and (min-width: $tablet) {
    .popup-component {
      width: 450px;
      // height: 250px;
    }
  }
}
