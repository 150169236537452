@import '../../../../../assets/styles/index.scss';

.apply-program-application-form-step {
  @include flexCol;
  gap: 24px;

  &__form {
    @include flexCol;
    gap: 16px;

    &__footer {
      @include flexRow;
      align-items: center;
      gap: 8px;
      margin-top: 24px;

      button {
        width: 140px;
      }
    }
  }
}
