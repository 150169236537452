.uploadImage {
  max-height: 100px;
  max-width: 100px;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  border: 1px solid #a4a4a4;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  img {
    height: 100%;
    width: 100%;
  }
}
.uploadImage2 {
    max-width: 1920px;
    max-height: 1080px;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    border: 1px solid #a4a4a4;
    border-radius: 8px;
    padding: 12px;
    object-fit: cover;
    align-items: center;
    justify-content: center;

    img {
        height: 100%;
        width: 100%;
      }
}

.companyCard{
  width: 100%;
  border: 1px solid #FFE5D5;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  .description{

  }
}
