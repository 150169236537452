@import '../../../../assets/styles/index.scss';

.idea-details-details {
  @include flexCol;
  gap: 16px;
  color: $neutral-1100;
  margin-bottom: 20px;
  .button {
    width: fit-content; // Another way to size to content
    padding: 8px 16px; // Explicit padding if needed
  }
  &__people-access {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__fields {
    @include flexCol;
    gap: 16px;
  }
}