@import '../../../assets/styles/index.scss';

.settings-forgot-password-modal {
  &__modal {
    width: 95%;
  }

  &__form {
    @include flexCol;
    gap: 12px;

    &__verification-code {
      @include flexCol;
      gap: 12px;
      &__input label {
        font-size: 12.8px;
        line-height: 17.48px;
      }
      &__resend-code {
        @include flexRow;
        justify-content: space-between;
        color: $primary-600;
        &__link {
          cursor: pointer;
          text-decoration: $primary-200 underline 0.1px;
          &--disabled {
            color: $neutral-600;
            pointer-events: auto;
          }
        }
      }
    }

    &__buttons {
      @include flexRow;
      justify-content: flex-end;
      gap: 24px;

      button {
        width: 160px;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .settings-forgot-password-modal {
    &__modal {
      width: 550px !important;
    }
  }
}
