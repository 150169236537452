@import '../../assets/styles/index.scss';

// .footer {
//   width: 100%;
//   height: 70px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: $primary-600;
//   color: $white-200;
// }
// .btnarea {
//   a {
//     text-decoration: none;
//     color: #000;
//     cursor: pointer;
//     &:hover {
//       color: #fff;
//     }
//   }
// }
.footerMain {
  border-bottom: 8px solid #FF5924;

  .footerBorder {
    padding-bottom: 70px;
    border-top: 1px solid #D4D4D4;
  }

  .footerLogo {
    img {
      cursor: pointer;
      width: 160px;
      height: auto;
    }
  }

  .footerList {
    margin-bottom: 20px;

    h3 {
      color: #303030;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 15px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 6px;
        font-weight: 500;

        .btns {
          font-size: 16px;
          text-decoration: none;
          color: #303030;
          font-weight: 500;
          transition: .3s;

          &:hover {
            color: #FF5924;
          }
        }
      }
    }
  }

  .footerBottom {
    display: flex;
    align-items: center;
    gap: 14px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 25px 0px 40px;

    p {
      margin: 0;
      line-height: normal;
      font-size: 16px;
      font-weight: 500;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      gap: 12px;

      li {
        a {
          text-decoration: none;
          color: #303030;
          font-size: 24px;
          transition: .3s;

          &:hover {
            color: #FF5924;
          }
        }
      }
    }
  }
}

// <<<<<<<<<<media query>>>>>>>>>

@media only screen and (max-width: 991px) {
  .footerMain {
    .footerBorder {
      padding-bottom: 35px;
    }

    .footerLogo {
      margin-bottom: 20px;
    }

    .footerList {
      h3 {
        margin-bottom: 8px;
      }

      ul {
        li {
          margin-bottom: 4px;
        }
      }
    }
    .footerBottom{
      margin: 10px 0px 32px;
      gap: 10px;
    }
  }
}