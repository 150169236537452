.onboard {
  .titleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .title {
      font-size: 16px;
      margin: 0;
    }
  }
  .inputFields {
    margin: 0 auto;
    margin-bottom: 14px;
  }
  .milestonelist {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      .nestedList {
        padding: 0;
        padding-left: 20px;
        margin: 0;
        list-style: none;
        margin-bottom: 12px;
        li {
          margin-bottom: 4px;
          &.add {
              color: #fa4616;
              display: flex;
              align-items: center;
              gap: 4px;
            &:hover{
                text-decoration: underline;
                cursor: pointer;
            }
          }
        }
      }
    }
  }
  :global(.insideCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 12px 24px;
    margin-bottom: 10px;
  }
}
