@import '../../../../assets/styles/index.scss';

.view-idea-documents {
  @include flexCol;
  gap: 16px;

  &__header {
    @include flexRow;
    justify-content: space-between;

    button {
      width: 114px;
    }
  }

  &__no-docs {
    color: $neutral-1100;
  }

  &__documents {
    display: grid;
    gap: 16px;
    grid-template-columns: minmax(0, 1fr);
  }

  &__select-file {
    display: none;
  }
}

@media screen and (min-width: $tablet) {
  .view-idea-documents {
    &__documents {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@media screen and (min-width: $desktop) {
  .view-idea-documents {
    gap: 24px;

    &__documents {
      gap: 24px;
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
