@import '../../../assets/styles/index.scss';

.chat-list {
  padding: 0;
  height: inherit;
  // overflow: scroll;
  display: none;

  &--show-on-mobile {
    display: block;
  }

  &__heading {
    @include flexCol;
    gap: 8px;
    padding: 12px;

    &__chatLabel {
      @include flexRow;
      justify-content: space-between;
      gap: 16px;
      align-items: center
    }

    &__editIcon {
      color: #fa4616;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.2s ease-out;
      --webkit-transition: all 0.2s ease-out;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #ffe5d5;
        color: #fa4616;
      }
    }
  }

  &__users {
    @include flexCol;
    @include mainPageScroll;
    margin-right: 4px;
    gap: 8px;
    padding: 0px 6px 6px 8px;
    height: calc(100dvh - 383px);
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  &__not-initiated {
    @include flexCol;
    @include flexCenterContent;
    gap: 16px;
    padding: 16px;

    &>* {
      text-align: center;
    }
  }

  &__allBtnGroup {
    @include flexRow;
    padding: 6px 14px 6px 8px;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 7px;

    button {
      width: auto;
      font-size: 11.55px;
      padding: 4px 10px;
      height: 27px;
      border-radius: 9.62px;
    }
  }
}

.chatNameHead {
  color: #1D1D1D;
  font-size: 16px;
  line-height: 21.86px;
}

@media screen and (min-width: $desktop) {
  .chat-list {
    display: block;

    &__users {
      height: calc(100dvh - 383px);
    }

    &__not-initiated {
      height: calc(100dvh - 383px);
    }
  }
}