@import '../../../assets/styles/index.scss';

.user-card {
  box-shadow: $shadow-100;
  @include flexCol;
  gap: 8px;
  color: $neutral-1000;
  padding: 16px;

  &__header {
    @include flexRow;
    justify-content: space-between;

    &__user {
      @include flexCol;
      gap: 4px;
      cursor: pointer;

      &--is-company {
        @include flexRow;
        align-items: center;
        gap: 16px;

        img {
          object-fit: contain !important;
        }
      }

      &__profile-pic {
        width: 64px;
        height: 64px;
      }

      &__info {
        @include flexCol;
        gap: 4px;

        &__role {
          color: $neutral-800;
        }
      }
    }

    button {
      width: fit-content;
    }
  }

  &__introduction {
    @include textEllipsis;
  }
}
