.resourceContainer {
  .imageUploadBox {
    border: 2px solid #fa4616;
    min-height: 150px;
    max-height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    margin-bottom: 15px;

    cursor: pointer;
    z-index: 1;
    .uploadContent {
      text-align: center;
      .uploadLabel {
        cursor: pointer;
        .uploadInput {
          display: none;
        }
      }
      .uploadTitle {
        font-size: 16px;
        margin-top: 14px;
      }
    }
  }

  .uploadedBox {
    min-height: 150px;
    max-height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 12px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
    flex-direction: column;

    &.document {
      border: 2px solid #fa4616;

      .icon {
        min-height: 94px;
        max-height: 94px;
        object-fit: contain;
      }
      span {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: break-spaces;
        text-wrap: wrap;
        word-wrap: break-word;
        text-align: left;
        font-weight: 500;
      }
    }
    // z-index: 1;

    img {
      object-fit: cover;
      width: 5px;
      z-index: 1;
    }
    .icon {
      object-fit: cover;
      width: auto;
      z-index: 1;
      height: 100%;
      width: 100%;
      min-height: 150px;
      max-height: 150px;
      object-fit: cover;
      border-radius: 12px;
      position: relative;
    }

    .dotDropdown {
      position: absolute;
      top: 0;
      right: 0;
      // transform: translate(-41px, 10px);
      z-index: 99;
      .dots {
        height: 16px;
        width: 16px;
      }

      :global(.accordion) {
        :global(.accordion-header) {
          padding: 0;

          :global(.accordion-button) {
            padding: 8px 17px 5px 17px;
            background: #fff !important;
            .dotDropInside {
              span {
                margin-right: 5px;
              }
            }
            &:global(.collapsed) {
              &::after {
                content: '+';
                transform: rotate(0);
              }
            }

            &::after {
              display: flex;
              align-items: center;
              font-size: 20px;
              content: '-';
              background-image: none !important;
              transform: rotate(0);
              transition: content 3s;
            }
          }
        }
        :global(.accordion-body) {
          background: #fff !important;
          color: black !important;
          background: #fff !important;
          &:focus,
          &:active {
            outline: none;
            background: none;
          }
          &:focus {
            outline: none;
            background-color: transparent;
          }
          padding: 0;
          .itemList {
            list-style: none;
            padding: 5px 28px 0px 28px;
            margin: 0;
            overflow: hidden;
            max-width: 100%;
            &:focus,
            &:active {
              outline: none;
              background: none;
            }
            background: #fff !important;
            color: black !important;
            .item {
              font-size: 14px;
              display: block; /* Ensure the li elements are block-level elements */
              max-width: 100%; /* Prevent overflow */
              word-wrap: break-word; /* Ensure long words break to the next line */
              word-break: break-word; /* Break words if necessary */
              line-height: 1.5;
              margin-bottom: 4px;
              &:focus {
                outline: none;
                background-color: transparent;
              }

              &:last-child {
                margin-bottom: 0;
              }

              // &:hover{
              //   background-color: #fa471629;
              // }
            }
          }
        }
      }

      :global(.dropdown) {
        // position: absolute;
        // top: 100%;

        :global(.dropdown-toggle) {
          .dots {
            // position: absolute;
            // top: 0px !important;
            cursor: pointer;
            background: #fff !important;
          }
        }
        :global(.dropdown-menu) {
          z-index: 2 !important;
          padding: 5px 0px;
          // right: 5px !important;
          min-width: 190px !important;
          // position: relative;
          background: #fff !important;
          :global(.dropdown-item){
            &:active{
              background: none;
              color: #000;
            }
          }
        }
        :global(.btn) {
          background-color: transparent;
          border: none;
          background: none;

          &::after {
            display: none;
          }
        }

        .dotDropInside {
          display: flex;
          gap: 10px;
          align-items: center;
          margin: 0;
          font-size: 14px;
        }
      }

      .correctImage {
        height: 20px;
        width: 20px;
        opacity: 0.6;
      }
    }
  }
}
