@import '../../../../../assets/styles/index.scss';

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color:#fa4616;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid white;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

.styled-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 0.9em;
}

.action-button {
  background-color: #fa4616;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.action-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.action-button:hover:not(:disabled) {
  background-color: #007a5e;
}

.dynamic-field {
  @include flexCol;
  gap: 8px;

  @mixin checkboxRow {
    @include customCheckboxInput;
    @include flexRow;
    cursor: pointer;
    color: $neutral-900;
    align-items: center;
    gap: 8px;

    label {
      cursor: pointer;
    }
  }

  @mixin dropdownOptionsStyling {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;

    @media screen and (min-width: $desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__header {
    @include flexRow;
    justify-content: space-between;
    align-items: center;

    &__actions {
      @include flexRow;
      align-items: center;
      gap: 24px;

      &__required {
        @include checkboxRow;
      }
    }
  }

  &__field {
    @include flexCol;
    gap: 12px;

    &__character-limit-checkbox {
      @include checkboxRow;
    }

    &__character-limit {
      .input-component {
        width: 250px;
      }
    }

    &__dropdown-options {
      @include dropdownOptionsStyling;

      &__radio,
      &__checkbox {
        @include flexRow;
        align-items: center;
        gap: 8px;
        width: 200px;
        cursor: pointer;

        @include customRadioInput;

        label {
          cursor: pointer;
          flex: 1;
          margin-bottom: 3px;
        }

        input {
          cursor: pointer;
        }
      }

      &__ratio,
      &__checkbox {
        width: 200px;
      }
    }

    &__radio-check {
      width: 416px;
    }

    &__add-option {
      width: 132px;
    }
  }

  &__upload-file {
    @include flexCol;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid $neutral-500;
    padding: 12px;
    gap: 8px;
    cursor: pointer;
    color: $neutral-700;

    &__drag-drop {
      @include flexRow;
      gap: 8px;
    }

    &__browse {
      color: $primary-600;
    }
  }
}
