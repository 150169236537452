@import '../../../../../../assets/styles/index.scss';

.section-review {
  @include flexCol;
  padding: 16px;
  gap: 16px;

  &__fields {
    @include flexCol;
    gap: 24px;
  }
}
