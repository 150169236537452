@import '../../../assets/styles/index.scss';

.idea-hub-dashboard-card {
  @include flexRow;
  gap: 16px;
  padding: 8px;
  color: $neutral-1000;
  align-items: center;

  img {
    width: 77.85px;
  }

  &__right {
    @include flexRow;
    gap: 12px;
    align-items: center;

    &__heading {
      &--highlight {
        color: $primary-600;
        &__craftHeading{
          font-size: 16px;
          color: $primary-600;
        }
      }
      &__craftHeading{
        font-size: 16px;
        line-height: 21px;
      }
    }

    &__buttons {
      @include flexRow;
      flex-wrap: wrap;
      gap: 12px;

      button {
        @include flexRow;
        justify-content: center;
        align-items: center;
        gap: 6px;
        // width: 140px;
        width: 100px;
        padding: 0px;
  
        svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .idea-hub-dashboard-card {
    gap: 19px;
  }
}
@media screen and (max-width: $tablet) {
  .idea-hub-dashboard-card {
    &__right{
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
