@import '../../../../../assets/styles/index.scss';

.chat-messages-body {
  @include flexCol;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
  @include customScroll;
  padding: 0 5px 0 0;

  &::-webkit-scrollbar {
    width: 8px;
  }
}
