@import '../../assets/styles/index.scss';

.view-post-owned {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;
  &__body {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    padding: 16px;
    margin-top: 89px;
    &__content {
      @include flexCol;
      gap: 24px;
      overflow: visible;

      &__comments {
        @include flexCol;
        gap: 16px;
      }
    }
    &__cards {
      @include flexCol;
      gap: 32px;
    }
  }
}

@media (max-width: 768px){
  .view-post-owned{
    &__body{
      margin-top: 160px;
    }
  }
}

@media screen and (min-width: $desktop) {
  .view-post-owned {
    &__body {
      display: grid;
      grid-template-columns: 65% 32.5%;
      padding: 32px;
      // width: 95%;
      margin-right: max(61px, calc((100vw - 1287px) / 2) - 63px);
    }
  }
}
