@import '../../../../../../assets/styles/index.scss';

.add-edit-program-details-fields {
  &__modal {
    max-width: 599px;
  }
  &__content {
    @include flexCol;
    gap: 16px;

    &__fields {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;

      &__field {
        @include flexRow;
        align-items: center;
        cursor: pointer;
        gap: 8px;
        width: 100%;
        padding: 16px;
        border-radius: 8px;

        svg {
          height: 18px;
          width: 18px;

          path {
            stroke: $neutral-1100;
          }
        }

        &:hover {
          background: $primary-100;
        }

        &--is-active {
          background: $primary-200;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .add-edit-program-details-fields {
    &__modal {
      gap: 24px !important;
    }

    &__content {
      gap: 24px;

      &__fields {
        grid-template-columns: 1fr 1fr;
        gap: 24px;
      }
    }
  }
}
