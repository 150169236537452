.dotDropdown {
  :global(.dropdown) {
    :global(.dropdown-toggle) {
      border-radius: 100px;
      color: #fff;
      background: #fa4616;
      border: 1px solid #fa4616;
      padding: 5px 10px;
      height: 34px;
      line-height: 0px;
      font-size: 14px;
      font-weight: 500;

      &::after {
        display: none;
      }
    }
    :global(.dropdown-menu) {
      min-width: 280px;
      :global(.dropdown-item) {
        background-color: #fff !important;
        color: black;
      }
      .applicantCard {
        label {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .allSelect {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
        .applicantList {
          margin: 0;
          padding: 0;
          list-style: none;
          max-height: 180px;
          overflow-y: auto;
          margin-bottom: 12px;
          &::-webkit-scrollbar {
            width: 6px;
            height: 8px;
            background: #fff;
            border-radius: 100px;
          }
          &::-webkit-scrollbar-button {
            display: none;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #fa4616;
            border-radius: 100px;
          }
          li {
            margin-bottom: 1px;
            padding: 2px 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
      .nonPlatformUser {
        button {
          font-family: 'Manrope', sans-serif;
          font-size: 12.8px;
          line-height: 17.48px;
          letter-spacing: 0em;
          margin: 0;
          font-weight: 600;
          margin: 0;
          gap: 8px;
          height: 34px;
          padding: 0 16px;
          background: #fa4616;
          color: #ffffff;
          border: 2px solid #ffffff;
          width: 100%;
          border-radius: 100px;
          border: none;
          outline: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease-out;
          --webkit-transition: all 0.3s ease-out;
          text-wrap: nowrap;
          margin-top: 5px;
        }
      }
      .mentorList {
        margin: 0;
        padding: 0;
        list-style: none;
        max-height: 200px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 6px;
          height: 8px;
          background: #fff;
          border-radius: 100px;
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #fa4616;
          border-radius: 100px;
        }
        li {
          margin-bottom: 2px;
          padding: 3px 8px;
          &:hover {
            background: #fa47162d;
            border-radius: 6px;
          }
        }
      }
    }
  }
}
.headingContainer{
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 2px;
  margin-left: 8px;
  .loadingContainer{
    height: 24px;
    width: 24px;
    :global(.circle-loader-component){
      height: 100%;
      width: 100%;
    }
  }
}
