.chartContainer {
  padding: 20px 18px 20px 0px;
  margin: 0 auto;
}
.legendList{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 14px;
}

:global(.recharts-wrapper) {
  width: 100% !important;
  :global(.recharts-text) {
    &:global(.recharts-cartesian-axis-tick-value) {
      font-size: 12px;
    }
  }

  :global(.recharts-legend-wrapper) {
    bottom: -5px !important;
    width: 100% !important;
    :global(.recharts-default-legend) {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      padding-top: 10px;

      :global(.recharts-legend-item) {
        font-size: 14px;
      }
    }
  }
}
