@import '../../../assets/styles/index.scss';

.ih-d-manage-group-members {
  @mixin membersGridStyling {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media screen and (min-width: $desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__modal {
    gap: 16px !important;
  }

  &__content {
    @include flexCol;
    gap: 16px;
    height: 356px;
  }

  &__form {
    @include flexCol;
    gap: 16px;
    height: 100%;

    &__search-users {
      position: relative;

      &__container {
        position: absolute;
        z-index: 99;
        width: 100%;
        top: 81px;
        border: 1px solid $neutral-400;
        background: $background-100;
        border-radius: 8px;
        margin: 1px 0;
        padding: 12px;
        @include flexCol;
        gap: 16px;

        &__result {
          max-height: 168px;
          overflow-y: auto;
          @include customScroll;
          @include membersGridStyling;
        }
      }
    }

    &__users {
      @include membersGridStyling;
      max-height: 241px;
      overflow-y: auto;
      @include customScroll;
    }

    &__buttons {
      @include flexRow;
      justify-content: center;
      gap: 8px;
      margin-top: auto;

      &__close,
      &__create-group {
        width: 150px;
      }
    }
  }
}
