@import '../../assets/styles/index.scss';

.view-program {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;

  &__body {
    @include flexCol;
    gap: 24px;
    padding: 16px;
    margin-top: 89px;
  }
}

@media (max-width: 768px) {
  .view-program{
    &__body{
      margin-top: 160px;
    }
  }
}
@media screen and (min-width: $desktop) {
  .view-program {
    &__body {
      padding: 32px;
      // width: 95%;
      width :  calc(100vw - (300px + (max(85px, calc((100vw - 1287px) / 2) - 39px) * 2) - 32px));
    }
  }
}
