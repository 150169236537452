@font-face {
  font-family: 'Aeonik';
  src: url(../src/assets/aeonik-trial/NewAeonikTrials/AeonikTRIAL-Regular.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Aeonik';
  src: url(../src/assets/aeonik-trial/NewAeonikTrials/AeonikTRIAL-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Aeonik';
  src: url(../src/assets/aeonik-trial/NewAeonikTrials/AeonikTRIAL-Regular.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  background: #fffbf9;
  font-family: 'Aeonik', sans-serif !important;
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background: #fff;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #fa4616;
    border-radius: 100px;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.owl-nav {
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    width: 35px;
    height: 35px;
    border-radius: 50% !important;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
  }

  .owl-prev {
    left: -16px;
    background-color: #00000094 !important;

    &:hover {
      background-color: #000000 !important;
    }
    span {
      font-size: 25px;
      color: #ffff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -2px;
    }
  }

  .owl-next {
    right: -9px;
    background-color: #00000094 !important;

    &:hover {
      background-color: #000000 !important;
    }
    span {
      font-size: 25px;
      color: #ffff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -2px;
    }

    .disabled {
    }
  }
}
