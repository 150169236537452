@import '../../../../../../assets/styles/index.scss';

.dynamic-field {
  @include flexCol;
  gap: 16px;

  &__header {
    @include flexRow;
    justify-content: space-between;
    align-items: center;

    &__actions {
      @include flexRow;
      align-items: center;
      gap: 24px;
    }
  }

  &__upload-file {
    @include flexCol;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid $neutral-500;
    padding: 12px;
    gap: 8px;
    cursor: pointer;
    color: $neutral-700;

    &__drag-drop {
      @include flexRow;
      gap: 8px;
    }

    &__browse {
      color: $primary-600;
    }
  }

  &__files {
    @include flexRow;
    margin-top: 8px;
    gap: 16px;
    flex-wrap: wrap;
  }
}
