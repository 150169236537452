@use '../../../assets/styles/index.scss' as styles;
body {
  font-family: 'Manrope', sans-serif !important;
}

.ventureCardBanner {
  img {
    width: 100%;
    height: 316px;
    object-fit: cover;
    object-position: top;
    border-radius: 10.7px 10.7px 0px 0px;
  }
}

.ventureCardOuter {
  display: flex;
  gap: 10px;
  margin: 15px 0px 20px 15px;
  justify-content: space-between;
  align-items: flex-end;

  .ventureCardImageOuter {
    display: flex;
    gap: 15px;

    .ventureCardImage {
      img {
        width: 100px;
        height: 100px;
        border-radius: 7.29px;
        border: 3px solid #fff;
        margin-top: -45px;
      }
    }

    .ventureCardiTitle {
      h3 {
        font-size: 17px;
        font-weight: 700;
        color: #000;
        line-height: 23.5px;
        margin: 0px 0px 5px;
        @include styles.lineClamp(2)
      }
      h5 {
        font-size: 15px;
        font-weight: 500;
        color: #000;
        line-height: 20.56px;
        margin: 0;
        @include styles.lineClamp(1)
      }
    }
  }

  .upvoteButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
  }
}

.upvotesRow {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  margin: 0px 0px 10px;

  .upvotesCol {
    text-align: center;

    h6 {
      font-size: 12px;
      line-height: 16.39px;
      font-weight: 500;
      color: #353535;
      margin: 0px 0px 5px;
    }
    span {
      font-size: 16px;
      line-height: 21.86px;
      font-weight: 700;
      color: #000;
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: center;
    }
  }
}

.ventureAbout {
  ul, ol{
    li{
      margin: 0;
      padding: 0;
    }
  }
  img{
    width: 100%;
  }
  h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    margin: 0px 0px 5px;
  }

  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #000;

    a {
      color: #000;
    }
  }
}

.aboutTeamRow {
  display: flex;
  justify-content: space-around;
  margin: 20px 0px;

  .aboutTeamCol {
    h4 {
      font-size: 14px;
      font-weight: 700;
      color: #000;
      margin: 0px 0px 3px;
    }
    span {
      font-size: 13px;
      font-weight: 400;
      color: #000;
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  .ventureCardOuter {
    align-items: flex-start;
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .ventureCardOuter {
    align-items: flex-start;
    flex-direction: column;
  }
}
