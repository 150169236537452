.main_box {
    background-color: #fff;
}

.fillPassword {
    width: 50%;
    margin: 10px auto;
    background-color: #fff;

    .main_logo{
        
    }

    h3 {
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .formCmg {
        :global(.form-label){
            color: #000;
        }
        :global(.form-control) {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
            line-height: 35px;

            &:focus{
                border-color: #fa4616;
            }
        }
        :global(.btn){
            color: #fff;
            border: transparent;
            line-height: 40px;
            font-size: 18px ;
            background: #fa4616;
        }
    }
}