@import '../../../../assets/styles/index.scss';

.manage-profile-picture {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  .profile-picture {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    background: $primary-300;
    h3 {
      font-size: 20px;
    }
  }
  &__edit {
    position: absolute;
    @include flexCenterContent;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 32px;
    width: 32px;
    border-radius: 50%;
    cursor: pointer;
    @include hoverTransition;
    opacity: 0;
    background: $primary-200;
    svg path {
      stroke: $primary-600;
    }
  }
  &:hover {
    .manage-profile-picture__edit {
      opacity: 1;
    }
  }

  &__options {
    display: none;
    position: absolute;
    top: 50px;
    z-index: 9999;
    border: 1px solid $primary-200;
    border-radius: 8px;
    &--is-open {
      display: block;
    }
  }
}

@media screen and (min-width: $tablet) {
  .manage-profile-picture {
    width: 100px;
    height: 100px;
    .profile-picture {
      h3 {
        font-size: 40px;
      }
    }
    &__options {
      top: 100px;
    }
  }
}
