@import '../../../assets/styles/index.scss';

.idea-hub-my-ideas {
  @include flexCol;
  gap: 16px;

  &__heading {
    color: $black-100;
  }

  .confirm-modal {
    &__header__icon svg {
      height: 36.67px;
      width: 36.67px;
    }
  }

  &__ideas {
    display: grid;
    gap: 16px;
    grid-template-columns: minmax(0, 1fr);
  }
}

@media screen and (min-width: $tablet) {
  .idea-hub-my-ideas {
    &__ideas {
      gap: 32px;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
  }
}

@media screen and (min-width: $desktop) {
  .idea-hub-my-ideas {
    &__ideas {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    }
  }
}
