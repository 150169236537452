.outer_box {
  border: 1px solid #ffe5d5;
  border-radius: 16px;
  margin-bottom: 14px;
  position: relative;
  min-height: 268px;
  width: 100%;
  max-height: 268px;
  .actions {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-40%, 70%);
    svg {
      filter: brightness(7);
      cursor: pointer;
    }
  }
  .imageBox {
    padding: 4px;
    border-radius: 16px;
    width: 100%;
    height: 105px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }
  .companyDetails {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 12px;
    margin: 4px 0;
    .imgBox {
      height: 32px;
      width: 32px;
      border-radius: 100px;
      object-fit: cover;
      .fakelogo {
        height: 32px;
        width: 32px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ef643ed1;
        span {
          color: #fff;
          font-weight: 500;
          font-size: 14px;
          text-transform: uppercase;
          display: block;
          margin: 0;
        }
      }
      img {
        height: 100%;
        width: 100%;
        border-radius: 100px;
      }
    }
    .companyName {
      font-weight: 600;
      font-size: 14px;
      line-height: 19.12px;
      margin: 0;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .program_heading {
    padding: 0 12px;
    h4 {
      font-size: 16px;
      font-weight: 600;
      margin-top: 6px;
      line-height: 21.86px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    h5 {
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .marketplace_content {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
    p {
      font-family: Manrope;
      font-size: 13px;
      font-weight: 400;
      line-height: 17.48px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;

      &.zeroOpatity {
        opacity: 0;
      }
    }
  }
  .ctaButton {
    margin-bottom: 12px;
    display: flex;
    gap: 8px;
    button {
      width: auto;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
