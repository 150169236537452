.topHeading {
  display: flex;
  justify-content: space-between;
}
.actionButton {
  display: block;
  font-size: 12px;
  color: #fa4616;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fa4616;
  padding: 2px 10px;
  border-radius: 18px;
  gap: 2px;
  cursor: pointer;
}
.icon {
    margin-bottom: 2px;
}
