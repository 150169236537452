@import '../../../assets/styles/index.scss';

.program-discussion {
  display: grid;
  grid-template-columns: minmax(0, 23.23%) minmax(0, 75.27%);
  gap: 16px;
  flex: 1;
  height: 100%;
}

// @media screen and (min-width: $desktop) {
//   .program-discussion {
//   }
// }
