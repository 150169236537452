@import '../../assets/styles/index.scss';

.button-component {
  width: 100%;
  border-radius: 100px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
  --webkit-transition: all 0.3s ease-out;
  text-wrap: nowrap;

  &:disabled {
    cursor: not-allowed;
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  // Button variants
  &--primary {
    background: $primary-600;
    color: $white-100;
    border: 2px solid transparent;
    &:hover:not(:disabled) {
      border: 2px solid $primary-400;
    }
    &:disabled {
      background: $primary-400;
      svg path {
        stroke: $white-100;
      }
    }
  }
  &--ternary {
    padding: 0 10px !important;
    border-radius: 6px;
    background: $primary-600;
    color: $white-100;
    border: 2px solid transparent;
    &:hover:not(:disabled) {
      border: 2px solid $primary-400;
    }
    &:disabled {
      background: $primary-400;
      svg path {
        stroke: $white-100;
      }
    }
  }
  &--ternary-flat {
    border-radius: 6px;
    padding: 0 10px !important;
    background: #fff;
    color: $primary-600;
    border: 1px solid $primary-600;
    &:hover:not(:disabled) {
      border: 2px solid $primary-400;
    }
    &:disabled {
      background: $primary-400;
      svg path {
        stroke: $white-100;
      }
    }
  }

  &--secondary {
    background: $white-100;
    color: $primary-600;
    border: 1px solid $primary-600;
    &:hover:not(:disabled) {
      background: $primary-100;
    }
    &:disabled {
      border: 1px solid $primary-400;
      color: $primary-400;
      svg path {
        stroke: $primary-400;
      }
    }
  }

  &--tertiary {
    background: none;
    color: $primary-600;
    border: none;
    &:hover:not(:disabled) {
      background: $primary-100;
    }
    &:disabled {
      color: $primary-400;
      svg path {
        stroke: $primary-400;
      }
    }
  }

  &--active {
    background: $primary-200;
    color: $primary-600;
  }

  // Button sizes
  &--large {
    @include subHeadingTwo;
    @include semiBoldTypography;
    gap: 8px;
    height: 49px;
    padding: 0 24px;
    .button-loader {
      height: 18px;
      width: 18px;
    }
  }

  &--medium {
    @include subHeadingTwo;
    @include semiBoldTypography;
    gap: 8px;
    height: 45px;
    padding: 0 20px;
    .button-loader {
      height: 18px;
      width: 18px;
    }
  }

  &--small {
    @include bodyTwo;
    @include semiBoldTypography;
    gap: 8px;
    height: 39px;
    padding: 0 20px;
    .button-loader {
      height: 18px;
      width: 18px;
    }
  }

  &--extraSmall {
    @include captionTypography;
    @include semiBoldTypography;
    gap: 8px;
    height: 34px;
    padding: 0 16px;
    .button-loader {
      height: 18px;
      width: 18px;
    }
  }

  // Icon styles
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    &--primary {
      svg path {
        stroke: $white-100;
      }
    }
    &--secondary {
      svg path {
        stroke: $primary-600;
      }
    }
    &--tertiary {
      svg path {
        stroke: $primary-600;
      }
    }

    &--large {
      svg {
        height: 18px;
        width: 18px;
      }
    }
    &--medium {
      svg {
        height: 18px;
        width: 18px;
      }
    }
    &--small {
      svg {
        height: 13.5px;
        width: 13.5px;
      }
    }
    &--extraSmall {
      svg {
        height: 13.5px;
        width: 13.5px;
      }
    }
  }
}
