@import '../../../../assets/styles/index.scss';

.edit-idea-edit-details {
  @include flexCol;
  gap: 16px;
  margin-bottom: 20px;
  &__heading {
    color: $neutral-1100;
  }

  &__users {
    @include flexCol;
    gap: 8px;

    &__manage-access {
      @include flexRow;
      justify-content: space-between;
      align-items: center;

      button {
        width: 128px;
      }
    }
  }

  &__form {
    @include flexCol;
    gap: 16px;
  }
}
