@import '../../../../assets/styles/index.scss';

.program-file-card {
  @include flexRow;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  .program-file-card__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .remove-button {
    background-color: transparent;
    border: none;
    color: red; // Choose a color that contrasts with the background
    cursor: pointer;
    font-size: 16px; // Adjust size as needed
    margin-left: 8px; // Adjust spacing as needed
    padding: 0;
    
    &:hover {
      color: darkred; // Optional: change color on hover
    }
  }
  

  &__file {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    padding: 8px;
    @include flexCenterContent;
    background: $primary-200;

    svg path {
      stroke: $primary-600;
    }
  }

  &__text {
    max-width: 216px !important;
    @include textEllipsis;
  }
}
