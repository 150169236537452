.details {
  margin: 16px 0;

  .emptyItem {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
  }

  .heading {
    font-size: 14px;
    margin: 0;
    margin-bottom: 10px;
  }

  :global(.insideCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 15px;
    margin-bottom: 14px;
  }

  .actionHeading {
    font-size: 15px;
  }

  .button {
    justify-content: center;
    gap: 1px;
    width: auto;
    font-size: 12px;
  }

  .error {
    color: #eb340b;
    font-size: 15px;
  }

  .details {
    padding: 20px;
    background-color: #f9fafb; // Light background for the whole section
  }

  .insideCard {
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .milestonelist {
    list-style: none;
    padding: 0;
  }

  .milestoneItem {
    margin-bottom: 30px;
  }

  .milestoneBox {
    padding: 14px;
    background-color: #ffffff;
    border: 1px solid #e3e8ee;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .milestoneHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .label {
    font-weight: bold;
  }

  .mileStoneHeading {
    font-size: 15px;
    font-weight: 700;
    color: #333;
    max-width: 80%;
    min-width: 80%;
  }

  .milestonePercentage {
    font-size: 15px;
    font-weight: 500;
    color: #6c757d;
  }

  .divider {
    margin: 10px 0;
    border: none;
    border-bottom: 1px solid #e3e8ee;
  }

  .taskList {
    list-style: none;
    padding-left: 16px;
  }

  .taskItem {
    margin-bottom: 15px;
  }

  .taskBox {
    padding: 15px;
    background-color: #fffbf9;
    border-radius: 8px;
  }

  .taskHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .taskHeading {
    font-size: 15px;
    font-weight: 500;
    max-width: 80%;
    min-width: 80%;
  }

  .dateContainer {
    display: flex;
    font-size: 13px;
    font-style: italic;
    font-weight: 300;
  }

  .dateItem {
    padding-right: 10px;
  }

  .taskPercentage {
    font-size: 12px;
    color: #6c757d;
    padding-top: 3px;
  }

  .taskCommentsValue {
    display: block;
    max-width: 100%;
    word-wrap: break-word;
    white-space: normal;
  }

  .taskComments {
    margin-top: 10px;
    font-size: 14px;
    color: #6c757d;
  }

  .milestonelist {
    margin: 0;
    padding: 0px;
    list-style: none;

    li {
      margin-bottom: 5px;

      :global(.milestoneBox) {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          min-width: 50%;
          max-width: 50%;

          &.mileStoneHeading {
            font-size: 14px;
            font-weight: 700;
          }
        }

        :global(.milestonePercentageCompletion) {
          min-width: 80px;
        }

        .inputFields {
          // width: 25%;
          min-width: 50px;
          max-width: 100px;

          .comments {
            min-width: 80px;
          }
        }
      }

      .nestedList {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 12px;

        .task {
          margin-bottom: 4px;

          .taskHeading {
            font-size: 14px;
            font-weight: 600;
            padding-left: 15px;
          }
        }
      }
    }
  }

  .teamCard {
    background-color: #fffbf9;
    border-radius: 8px;
    padding: 6px 10px;
    height: auto;
    min-height: 80px;
    width: 100%;
    border: 1px solid #ffe5d5;
    display: flex;
    gap: 10px;
    row-gap: 14px;
    margin-bottom: 10px;
    align-items: center;

    .teamDetails {
      width: 63%;
    }
  }
}

// <<<<<<<<<<media query>>>>>>>>>

@media only screen and (max-width: 767px) {
  .details {
    .milestoneHeader {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .mileStoneHeading {
      max-width: 100%;
    }

    .dateContainer {
      justify-content: space-between;
    }

    .dateItem {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .taskList {
      padding-left: 12px;
    }

    .taskBox {
      padding: 10px;
    }

    .taskHeader {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .dateContainer {
      justify-content: space-between;
    }
  }
}