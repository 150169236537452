@import '../../../assets/styles/index.scss';

.label-value-component {
  @include flexCol;
  gap: 12px;
  padding: 12px;
  border-radius: 12px;

  &__label {
    color: $neutral-1000;
  }
  &__value {
    color: $neutral-1000;
    // word-break: break-all;
    p{
      margin: 0
    }
    &__linkedin {
      @include flexRow;
      align-items: center;
      img {
        height: 32px;
        width: 32px;
      }
      @include flexRow;
      gap: 12px;
    }
  }
}
