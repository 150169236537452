.single-chevron-icon {
  &--up {
    transform: rotate(180deg);
  }
  &--right {
    transform: rotate(270deg);
  }
  &--down {
    transform: rotate(0deg);
  }
  &--left {
    transform: rotate(90deg);
  }
}
