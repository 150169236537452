@import '../../../../../assets/styles/index.scss';

@mixin manageKeyMembersGridStyling {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  .infinite-scroll-component__footer {
    grid-column: span 1;
  }

  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
    .infinite-scroll-component__footer {
      grid-column: span 2;
    }
  }
}

.manage-access-evaluators {
  @include manageKeyMembersGridStyling;
  max-height: 213px;
  overflow-y: auto;
  @include customScroll;
}
