.CreateCompanyNowCard {
    display: flex;
    flex-direction: column;
    gap: 12px;
    

    .svgBox {
        position: relative;
        background: #FF835D;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 85px;
        overflow: hidden;
        margin: 4px 0px;

        span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 111px;
            height: 111px;
            border-radius: 50%;
            background: #D9D9D91A;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                position: absolute;
                width: 150px;
                height: 150px;
                background: #D9D9D912;
                border-radius: 50%;
            }

            &::after {
                content: "";
                position: absolute;
                width: 190px;
                height: 190px;
                background: #D9D9D90F;
                border-radius: 50%;
            }
        }
    }
}