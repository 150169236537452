.modalCts {
  // &.resources{
  //   :global(.modal-dialog) {
  //     max-width: 50%;
  //   }
  // }
  :global(.modal-dialog) {
    max-width: 500px;
    &.hide {
      display: none;
    }

    :global(.modal-content) {
      border: none;
      background-color: #ffffff;
      border-radius: 20px;
      overflow: hidden;

      :global(.modal-header) {
        text-align: center;
        color: #000;
        padding: 15px 20px;
        border-radius: 20px 20px 0px 0px;
      }
      :global(.modal-title) {
        font-weight: normal;
        text-align: center;
        width: 100%;
        background: #fa4616;
        color: #ffffff;
        font-size: 18px;
        padding: 8px;
        .closeIcon {
          padding: 0;
          border: none;
          width: 30px;
          height: 30px;
          // background: #fff;
          background-image: linear-gradient(var(--Main2), var(--Main3));
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          // color: #787474;
          color: #fa4616;
          position: absolute;
          right: 10px;
          top: 10px;

          &.v2 {
            top: 8px;
          }

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
      }

      :global(.modal-body) {
        padding: 25px;
        .viewRatingBox {
          background: #fffbf9;
          padding: 10px 20px;
          font-size: 16px;
          border-radius: 8px;
          border: 1px solid #ffe5d5;
          width: 100%;

          h5 {
            margin: 0;
            padding: 0;
            font-size: 16px;
          }
        }
        .evaluationForm {
          margin: 10px 0;
          background: #fffbf9;
          padding: 20px;
          border-radius: 8px;
          border: 1px solid #ffe5d5;
          width: 100%;
          position: relative;

          &.empty {
            padding: 130px;
            .emptyDetails {
              text-align: center;
              font-family: 'Manrope', sans-serif;
              font-size: 14px;
              line-height: 19.12px;
              letter-spacing: 0em;
              font-weight: 600;
              color: #fa4616;
            }
          }

          .deleteButton {
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            transform: translate(-70%, 50%);
          }

          :global(.form_label) {
            font-family: 'Manrope', sans-serif;
            font-size: 12.8px;
            line-height: 17.48px;
            letter-spacing: 0em;
            font-weight: 600;
            // margin-bottom: 8px;

            // &::after {
            //   content: '';
            //   color: #b91c1c;
            // }
          }

          .critiriaInputs {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 14px;
            margin-bottom: 10px;
            p {
              margin: 0;
            }

            .critriaInput {
              width: 100%;
            }

            .checkBox {
              margin-top: 20px;
            }
          }

          .ratingContainer {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 16px 0;
            .ratingBox {
              display: flex;
              flex-direction: column;
              width: 99.5%;

              .headingContainer {
                display: flex;
                align-items: center;
                gap: 10px;

                .checkBox {
                  margin-bottom: 7px;
                }
              }
            }

            .ratingCritiria {
              display: flex;
              align-items: center;
              gap: 17px;

              .formInput {
                color: #383838;
                border: 1px solid #898989;
                border-radius: 8px;
                width: 10%;
                padding: 0;
                outline: none;
                transition: all 0.3s ease-out;
                --webkit-transition: all 0.3s ease-out;

                font-family: 'Manrope', sans-serif;
                font-size: 12.8px;
                line-height: 17.48px;
                letter-spacing: 0em;
                font-weight: 400;
                height: 41px;
                padding: 0 16px;
                text-align: center;

                appearance: textfield;
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                &::-ms-reveal {
                  display: none;
                }
                &::-ms-clear {
                  display: none;
                }
              }
            }

            .progressContainer {
              width: 50%;
            }
          }

          .commentBox {
            margin-top: 10px;
            .headingContainer {
              display: flex;
              align-items: center;
              gap: 10px;
              margin-bottom: 5px;

              .checkBox {
                margin-bottom: 8px;
              }
            }
            .textAreaContainer {
              margin-top: 10px;
              width: 100%;
            }
          }
        }
        .submitSection {
          width: 100%;
          button {
            background: #fa4616;
            color: #ffffff;
            border: 2px solid #ffffff;
            font-family: 'Manrope', sans-serif;
            font-size: 12.8px;
            line-height: 17.48px;
            letter-spacing: 0em;
            margin: 0;
            font-weight: 600;
            margin: 0;
            gap: 8px;
            height: 34px;
            min-width: 150px;
            padding: 0 16px;
            border-radius: 100px;
            border: none;
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s ease-out;
            --webkit-transition: all 0.3s ease-out;
            text-wrap: nowrap;
            margin: 0 0 0 auto;
          }
        }
        .uploadedBox {
          min-height: 150px;
          max-height: 150px;
          width: 100%;
          display: flex;
          justify-content: center;
          border-radius: 12px;
          margin-bottom: 15px;
          position: relative;
          // overflow: hidden;
          flex-direction: column;
          &.document {
            border: 2px solid #fa4616;

            .icon {
              min-height: 94px;
              max-height: 94px;
              object-fit: contain;
            }
            span {
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              white-space: break-spaces;
              text-wrap: wrap;
              word-wrap: break-word;
              text-align: left;
              font-weight: 500;
            }
          }
          // z-index: 1;

          img {
            object-fit: cover;
            width: 5px;
            z-index: 1;
          }
          .icon {
            object-fit: cover;
            width: auto;
            z-index: 1;
            height: 100%;
            width: 100%;
            min-height: 150px;
            max-height: 150px;
            object-fit: cover;
            border-radius: 12px;
            &.docs {
              width: 50%;
              height: 50%;
              min-height: 100px;
              max-height: 100px;
            }
          }

          .dotDropdown {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(-30px, 2px);
            z-index: 99;

            :global(.accordion) {
              :global(.accordion-header) {
                padding: 0;

                :global(.accordion-button) {
                  padding: 8px 17px 5px 17px;
                  .dotDropInside {
                    span {
                      margin-right: 5px;
                    }
                  }
                  &:global(.collapsed) {
                    &::after {
                      content: '+';
                      transform: rotate(0);
                    }
                  }

                  &::after {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    content: '-';
                    background-image: none !important;
                    transform: rotate(0);
                    transition: content 3s;
                  }
                }
              }
              :global(.accordion-body) {
                background: #fff !important;
                color: black !important;
                &:focus,
                &:active {
                  outline: none;
                  background: none;
                }
                &:focus {
                  outline: none;
                  background-color: transparent;
                }
                padding: 0;
                .itemList {
                  list-style: none;
                  padding: 5px 28px 0px 28px;
                  margin: 0;
                  overflow: hidden;
                  max-width: 100%;
                  &:focus,
                  &:active {
                    outline: none;
                    background: none;
                  }
                  background: #fff !important;
                  color: black !important;
                  .item {
                    font-size: 14px;
                    display: block; /* Ensure the li elements are block-level elements */
                    max-width: 100%; /* Prevent overflow */
                    word-wrap: break-word; /* Ensure long words break to the next line */
                    word-break: break-word; /* Break words if necessary */
                    line-height: 1.5;
                    margin-bottom: 4px;
                    &:focus {
                      outline: none;
                      background-color: transparent;
                    }

                    &:last-child {
                      margin-bottom: 0;
                    }

                    // &:hover{
                    //   background-color: #fa471629;
                    // }
                  }
                }
              }
            }

            :global(.dropdown) {
              position: absolute;
              top: 100%;

              :global(.dropdown-toggle) {
                .dots {
                  position: absolute;
                  top: 0px !important;
                  cursor: pointer;
                }
              }
              :global(.dropdown-menu) {
                z-index: 2 !important;
                padding: 5px 0px;
                right: 5px !important;
                min-width: 190px !important;
                position: relative;
                .sidebarlist {
                  position: absolute;
                  top: 0;
                  right: 0;
                  min-width: 190px !important;
                  height: 100%;
                  transform: translateX(100%);
                  background: #fff;
                  opacity: 0;
                  transition: all 0.3s;

                  &.show {
                  }
                }
              }

              :global(.btn) {
                background-color: transparent;
                border: none;

                &::after {
                  display: none;
                }
              }

              .dotDropInside {
                display: flex;
                gap: 10px;
                align-items: center;
                margin: 0;
                font-size: 14px;
              }
            }

            .correctImage {
              height: 20px;
              width: 20px;
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .modalCts {
    :global(.modal-dialog) {
      max-width: 92%;
    }
  }
}
