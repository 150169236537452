@import '../../../../assets/styles/index.scss';

.idea-details-actions {
  @mixin ideaDetailsIconStyle {
    @include actionIconHoverBehavior;
    height: 40px;
    width: 40px;
    svg {
      height: 24px;
      width: 24px;
    }
  }

  @include flexRow;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &__label {
    @include flexRow;
    gap: 8px;
    color: $black-100;

    &__status {
      color: $primary-600;
      background: $primary-100;
      padding: 6px 16px;
      border-radius: 100px;
    }
  }

  &__actions {
    @include flexRow;
    gap: 24px;
    align-items: center;

    &__finalize-idea {
      width: 159px;
    }

    &__share {
      position: relative;

      .dropdown-component {
        top: calc(100% + 5px);
      }

      &__icon {
        @include ideaDetailsIconStyle;
      }
    }

    &__edit-icon {
      @include ideaDetailsIconStyle;
    }

    &__next,
    &__discard-idea,
    &__save-idea,
    &__rate-idea {
      width: 150px;
    }

    &__manage-idea {
      position: relative;
      @include flexCenterContent;
      height: 24px;
      width: 24px;
      cursor: pointer;

      .dropdown-component {
        top: calc(100% + 5px);
      }
    }
  }
}
