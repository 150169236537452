@import '../../../assets/styles/index.scss';

.chat-messages {
  @include flexCol;
  padding: 16px;
  gap: 12px;
  height: inherit;
  flex: 1;
  display: none;

  &__not-initiated,
  &__no-chat-selected {
    @include flexCol;
    @include flexCenterContent;
    gap: 16px;
    padding: 16px;
    margin: auto;

    & > * {
      text-align: center;
    }
  }

  &--show-chat-mobile {
    display: flex;
  }
}

@media screen and (min-width: $desktop) {
  .chat-messages {
    display: flex;
    padding: 24px;
  }
}
