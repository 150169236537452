@import '../../../../assets/styles/index.scss';

.input-component {
  position: relative;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 11px;
  &--disabled {
    background: $neutral-100;
  }
  &:hover:not(&--disabled) {
    border: 2px solid $primary-300;
  }
  &--focus {
    svg {
      path {
        stroke: $neutral-900 !important;
      }
    }
  }

  // Icon alignment
  &__icon {
    position: absolute;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--large {
      width: 13.5px;
      &.align-icon-left {
        left: 20.5px;
        right: auto;
      }
      &.align-icon-right {
        left: auto;
        right: 20.5px;
      }
    }

    &--medium {
      width: 13.5px;
      &.align-icon-left {
        left: 20.5px;
        right: auto;
      }
      &.align-icon-right {
        left: auto;
        right: 20.5px;
      }
    }

    &--small {
      width: 13px;
      &.align-icon-left {
        left: 18.5px;
        right: auto;
      }
      &.align-icon-right {
        left: auto;
        right: 18.5px;
      }
    }

    svg {
      path {
        stroke: $neutral-500;
      }
    }
  }

  &__input {
    color: $neutral-900; // What should be the color
    border: 1px solid $neutral-600; // some inconsistencies between variants
    border-radius: 8px;
    width: 100%;
    padding: 0;
    outline: none;
    transition: all 0.3s ease-out;
    --webkit-transition: all 0.3s ease-out;

    &:focus {
      border: 1px solid $neutral-900;
      &::placeholder {
        color: $neutral-900;
      }
    }
    &:hover:not(:disabled) {
      border: 1px solid $primary-300;
    }

    &--large {
      @include bodyTwo;
      @include regularTypography;
      height: 43px;
      padding: 0 16px;
      &.align-input-left.has-icon {
        padding-left: 42px;
      }
      &.align-input-right.has-icon {
        padding-right: 42px;
      }
      &::placeholder {
        @include bodyTwo;
        @include regularTypography;
        color: $neutral-600;
      }
    }
    &--medium {
      @include bodyTwo;
      @include regularTypography;
      height: 43px;
      padding: 0 16px;
      &.align-input-left.has-icon {
        padding-left: 42px;
      }
      &.align-input-right.has-icon {
        padding-right: 42px;
      }
      &::placeholder {
        @include bodyTwo;
        @include regularTypography;
        color: $neutral-600;
      }
    }
    &--small {
      @include captionTypography;
      @include regularTypography;
      height: 41px;
      padding: 0 16px;
      &.align-input-left.has-icon {
        padding-left: 36px;
      }
      &.align-input-right.has-icon {
        padding-right: 36px;
      }
      &::placeholder {
        @include captionTypography;
        @include regularTypography;
        color: $neutral-600;
      }
    }
    &::-ms-reveal {
      display: none;
    }
  }
}
