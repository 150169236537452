.details {
  margin-bottom: 16px;

  .emptyItem {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
  }

  :global(.insideCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 15px;
    margin-bottom: 14px;
  }

  .actionHeading {
    font-size: 15px;
  }

  .button {
    justify-content: center;
    gap: 1px;
    width: auto;
    font-size: 12px;
  }

  .error {
    color: #eb340b;
    font-size: 15px;
  }

  .details {
    padding: 20px;
    background-color: #f9fafb; // Light background for the whole section
  }

  .insideCard {
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  // .updateButtonContainer {
  //   margin-top: 10px;
  //   text-align: right;
  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: flex-end;
  // }

  .milestoneItem {
    margin-bottom: 30px;
  }

  .milestoneBox {
    padding: 14px;
    background-color: #ffffff;
    border: 1px solid #e3e8ee;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .milestoneHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mileStoneHeading {
    font-size: 18px;
    font-weight: 700;
    color: #333;
    max-width: 80%;
  }

  .dateContainer {
    display: flex;
    font-size: 13px;
    font-style: italic;
    font-weight: 300;
  }

  .dateItem {
    padding-right: 10px;
  }

  .milestonePercentage {
    font-size: 16px;
    color: #6c757d;
  }

  .divider {
    margin: 10px 0;
    border: none;
    border-bottom: 1px solid #e3e8ee;
  }

  .taskList {
    list-style: none;
    padding-left: 16px;
  }

  .taskItem {
    margin-bottom: 15px;
  }

  .taskBox {
    padding: 15px;
    background-color: #fffbf9;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    /* Ensures that the Update button goes to the bottom */
  }

  .taskHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .taskHeading {
    font-size: 16px;
    font-weight: 500;
    max-width: 80%;
  }

  .taskPercentage {
    font-size: 12px;
    color: #6c757d;
    padding-top: 3px;
  }

  .taskFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .taskComments {
    flex: 1;
    /* Allows this section to take 50% width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* If the comment is too long, it will show ellipsis */
    margin-right: 20px;
  }

  .updateButtonContainer {
    // flex: 0 0 50%; /* Fixed width of 50% */
    text-align: right;
    margin-top: 10px;
    // text-align: right;
    // display: flex;
    // justify-content: flex-end;
    // align-items: flex-end;
  }

  .label {
    font-weight: bold;
  }

  .value {
    font-weight: normal;
  }

  .valueComment {
    display: block;
    max-width: 100%;
    /* Ensures the content doesn't exceed the width */
    word-wrap: break-word;
    white-space: normal;
  }

  .lightText {
    color: #888;
    font-size: 12px;
  }

  .milestonelist {
    margin: 0;
    padding: 0px;
    list-style: none;

    li {
      margin-bottom: 5px;

      :global(.milestoneBox) {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          min-width: 50%;
          max-width: 50%;

          &.mileStoneHeading {
            font-size: 14px;
            font-weight: 600;
          }
        }

        :global(.milestonePercentageCompletion) {
          min-width: 80px;
        }

        .inputFields {
          // width: 25%;
          min-width: 50px;
          max-width: 100px;

          .comments {
            min-width: 80px;
          }
        }
      }

      .nestedList {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 12px;

        .task {
          margin-bottom: 4px;

          .taskHeading {
            font-size: 14px;
            font-weight: 600;
            padding-left: 15px;
          }
        }
      }
    }
  }

  .heading {
    font-size: 14px;
    margin: 0;
    margin-bottom: 10px;
  }

  .teamCard {
    background-color: #fffbf9;
    border-radius: 8px;
    padding: 6px 10px;
    height: auto;
    min-height: 80px;
    width: 100%;
    border: 1px solid #ffe5d5;
    display: flex;
    gap: 10px;
    row-gap: 14px;
    margin-bottom: 10px;
    align-items: center;

    .teamDetails {
      width: 63%;
    }
  }
}

// <<<<<<<<<<media query>>>>>>>>>

@media only screen and (max-width: 767px) {
  .details {
    .milestoneHeader {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .mileStoneHeading {
      max-width: 100%;
    }

    .dateContainer {
      justify-content: space-between;
    }

    .dateItem {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .taskList{
      padding-left: 12px;
    }
    .taskBox{
      padding: 10px;
    }
    .taskHeader{
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    .dateContainer{
      justify-content: space-between;
    }
  }
}