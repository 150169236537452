@import '../../../../../assets/styles/index.scss';

.ext-application-success-modal {
  &__modal {
    @include flexCol;
  }
  &__content {
    @include flexCol;
    @include flexCenterContent;
    height: 100%;
    gap: 24px;

    &__logo {
      width: 90%;
      align-self: center;
    }

    &__text {
      color: $neutral-1100;
      text-align: center;

      &--highlight {
        color: $primary-600;
      }
    }

    &__buttons {
      @include flexRow;
      width: 100%;
      gap: 16px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .ext-application-success-modal {
    &__modal {
      height: 450px !important;
      width: 450px !important;
    }

    &__content {
      &__logo {
        width: 268.33px;
      }
    }
  }
}
