@mixin displayOne {
  font-family: 'Manrope', sans-serif;
  font-size: 61.04px;
  line-height: 83.38px;
  letter-spacing: 0em;
  margin: 0;
}

@mixin displayTwo {
  font-family: 'Manrope', sans-serif;
  font-size: 56px;
  line-height: 76.5px;
  letter-spacing: 0em;
  margin: 0;
}

@mixin displayThree {
  font-family: 'Manrope', sans-serif;
  font-size: 48.83px;
  line-height: 66.7px;
  letter-spacing: 0em;
  margin: 0;
}

@mixin headingOne {
  font-family: 'Manrope', sans-serif;
  font-size: 39.06px;
  line-height: 53.36px;
  letter-spacing: 0em;
  margin: 0;
}

@mixin headingTwo {
  font-family: 'Manrope', sans-serif;
  font-size: 31.25px;
  line-height: 48px;
  letter-spacing: 0em;
  margin: 0;
}

@mixin headingThree {
  font-family: 'Manrope', sans-serif;
  font-size: 25px;
  line-height: 34.15px;
  letter-spacing: 0em;
  margin: 0;
}

@mixin subHeadingOne {
  font-family: 'Manrope', sans-serif;
  font-size: 20px;
  line-height: 27.32px;
  letter-spacing: 0em;
  margin: 0;
}

@mixin subHeadingTwo {
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
  line-height: 24.59px;
  letter-spacing: 0em;
  margin: 0;
}

@mixin bodyOne {
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  line-height: 21.86px;
  letter-spacing: 0em;
  margin: 0;
}

@mixin bodyTwo {
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  line-height: 19.12px;
  letter-spacing: 0em;
  margin: 0;
}

@mixin captionTypography {
  font-family: 'Manrope', sans-serif;
  font-size: 12.8px;
  line-height: 17.48px;
  letter-spacing: 0em;
  margin: 0;
}

@mixin regularTypography {
  font-weight: 400;
  margin: 0;
}

@mixin mediumTypography {
  font-weight: 500;
  margin: 0;
}

@mixin semiBoldTypography {
  font-weight: 600;
  margin: 0;
}

@mixin boldTypography {
  font-weight: 700;
  margin: 0;
}
