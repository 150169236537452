@import '../../../../../assets/styles/index.scss';

.rate-idea {
  &__content {
    @include flexCol;
    gap: 16px;
  }

  &__heading {
    color: $neutral-1100;
  }

  &__form {
    @include flexCol;
    gap: 16px;
    color: $neutral-1100;

    &__parameters {
      @include flexCol;
      gap: 16px;

      &__parameter {
        @include flexCol;
        gap: 8px;

        &__levels {
          @include flexRow;
          gap: 16px;

          &__level {
            @include flexCenterContent;
            height: 32px;
            width: 36px;
            border: 1px solid $primary-200;
            border-radius: 4px;
            background: $background-100;
            cursor: pointer;

            &--is-selected {
              background: $primary-600;
              color: $neutral-100;
            }
          }
        }
      }
    }

    &__buttons {
      @include flexRow;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8px;

      button {
        width: 150px;
      }
    }
  }
}
