@import '../../../../../assets/styles/index.scss';

.evalutaion {
  @include flexCol;
  gap: 16px;
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  @include customScroll;

  :global(.title) {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 500;

    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    line-height: 24.59px;
    letter-spacing: 0em;
  }

  .container {
    .gradesBox {
      border-radius: 12px;
      border: 1px solid #ffe5d5;
      background: #fff;
      padding: 18px;
      height: inherit;
      overflow: hidden;
      min-height: 320px;

      .title {
        margin: 0;
        margin-bottom: 10px;
        font-weight: 500;

        font-family: 'Manrope', sans-serif;
        font-size: 16px;
        line-height: 24.59px;
        letter-spacing: 0em;
      }

      .gradeList {
        margin: 0;
        padding: 0;
        list-style: none;
        height: 83%;

        .listItem {
          margin-bottom: 10px;
          background: #fffbf9;
          padding: 10px;
          border-radius: 8px;
          border: 1px solid #ffe5d5;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          font-family: 'Manrope', sans-serif;
          &:hover {
            opacity: 0.9;
          }

          &.active {
            border: 1px solid #fa4616;
          }
        }
      }
    }
    .gradesContentBox {
      border-radius: 12px;
      border: 1px solid #ffe5d5;
      background: #fff;
      padding: 16px;
      height: inherit;
      overflow: hidden;

      .critiriaHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .actionBtnContainer {
          display: flex;
          align-items: center;
          gap: 10px;
          .editBtn {
            border: none;
            background-color: transparent;
            cursor: pointer;
            height: 39px;
            padding: 0 20px;
          }

          .saveBtn {
            font-family: 'Manrope', sans-serif;
            font-size: 14px;
            line-height: 19.12px;
            letter-spacing: 0em;
            font-weight: 600;
            gap: 8px;
            height: 39px;
            padding: 0 20px;
            background: #fa4616;
            color: #ffffff;
            border: 2px solid #ffffff;

            border-radius: 100px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s ease-out;
            --webkit-transition: all 0.3s ease-out;
            text-wrap: nowrap;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      .evaluationForm {
        margin: 10px 0;
        background: #fffbf9;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid #ffe5d5;
        width: 100%;
        position: relative;

        &.empty {
          padding: 130px;
          .emptyDetails {
            text-align: center;
            font-family: 'Manrope', sans-serif;
            font-size: 14px;
            line-height: 19.12px;
            letter-spacing: 0em;
            font-weight: 600;
            color: #fa4616;
          }
        }

        .deleteButton {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
          transform: translate(-70%, 50%);
        }

        :global(.form_label) {
          font-family: 'Manrope', sans-serif;
          font-size: 12.8px;
          line-height: 17.48px;
          letter-spacing: 0em;
          font-weight: 600;
          margin-bottom: 8px;

          // &::after {
          //   content: '*';
          //   color: #b91c1c;
          // }
        }

        .critiriaInputs {
          width: 90%;
          display: flex;
          align-items: center;
          gap: 14px;
          margin-bottom: 10px;

          .critriaInput {
            width: 100%;
          }

          .checkBox {
            margin-top: 20px;
          }
        }

        .ratingContainer {
          display: flex;
          align-items: center;
          gap: 10px;
          margin: 16px 0;
          .ratingBox {
            display: flex;
            flex-direction: column;
            width: 99.5%;

            .headingContainer {
              display: flex;
              align-items: center;
              gap: 10px;
              margin-bottom: 5px;

              .checkBox {
                margin-bottom: 7px;
              }
            }
          }

          .ratingCritiria {
            display: flex;
            align-items: center;
            gap: 17px;

            .formInput {
              color: #383838;
              border: 1px solid #898989;
              border-radius: 8px;
              width: 10%;
              padding: 0;
              outline: none;
              transition: all 0.3s ease-out;
              --webkit-transition: all 0.3s ease-out;

              font-family: 'Manrope', sans-serif;
              font-size: 12.8px;
              line-height: 17.48px;
              letter-spacing: 0em;
              font-weight: 400;
              height: 41px;
              padding: 0 16px;
              text-align: center;

              appearance: textfield;
              -moz-appearance: textfield;

              &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              &::-ms-reveal {
                display: none;
              }
              &::-ms-clear {
                display: none;
              }
            }
          }

          .progressContainer {
            width: 50%;
            margin-top: 14px;
          }
        }

        .commentBox {
          margin-top: 25px;
          .headingContainer {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 5px;

            .checkBox {
              margin-bottom: 8px;
            }
          }
          .textAreaContainer {
            margin-top: 10px;
            width: 100%;
          }
        }
      }

      .addMoreButton {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button {
          font-family: 'Manrope', sans-serif;
          font-size: 14px;
          line-height: 19.12px;
          letter-spacing: 0em;
          font-weight: 600;
          gap: 8px;
          height: 39px;
          padding: 0 20px;
          background: #fa4616;
          color: #ffffff;
          border: 2px solid #ffffff;
          border-radius: 100px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease-out;
          --webkit-transition: all 0.3s ease-out;
          text-wrap: nowrap;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    .gradesBox {
      min-height: 100% !important;
    }
  }
  .gradesContentBox{
    margin-top: 10px;
  }
  .critiriaInputs {
    width: 100% !important;
  }
  .formInput {
    width: 20% !important;
  }
  .gradeList{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    li{
      width: auto;
    }
  }
  .textAreaContainer{
    :global(.textarea-component){
      width: 100% !important;
    }
  }
  .progressContainer {
    width: 90% !important;
  }
}
