@import '../../../../../assets/styles/index.scss';

.program-actions {
  @mixin ideaDetailsIconStyle {
    @include actionIconHoverBehavior;
    height: 40px;
    width: 40px;
    svg {
      height: 24px;
      width: 24px;
    }
  }

  @include flexRow;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &__label {
    @include flexRow;
    gap: 16px;
    color: $black-100;
    align-items: center;

    // h5 {
    //   height: 41px;
    // }

    &__status {
      color: $primary-600;
      background: $primary-100;
      padding: 6px 16px;
      border-radius: 100px;
    }
  }

  &__actions {
    @include flexRow;
    gap: 24px;
    align-items: center;

    &__share {
      position: relative;

      .dropdown-component {
        top: calc(100% + 5px);
      }

      &__icon {
        @include ideaDetailsIconStyle;
      }
    }

    &__next {
      width: 138px;
    }

    &__archive {
      height: 40px;
      width: 40px;
    }
  }
}
