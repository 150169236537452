@import '../../../../assets/styles/index.scss';

.dm-chat-messages-footer {
  position: relative;

  &__form {
    @include flexCol;
    gap: 8px;

    &__row {
      @include flexRow;
      gap: 8px;
      align-items: center;
      width: 100%;

      .input-field-component {
        flex: 1;
      }

      &__actions {
        @include flexRow;
        align-items: center;
        gap: 8px;

        button {
          font-size: 12px;
          padding: 9px 24px;
          height: 43px;
        }
      }

      &__files {
        @include flexRow;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
      }
    }

    .EmojiPickerReact {
      position: absolute;
      bottom: 35px;
      right: 95px;  
    }
  }

  &__actions {
    @include flexRow;
    gap: 16px;
  }
}

.emojiLink_icons {
  svg {
    cursor: pointer;

    &:hover {
      color: #fa4616 !important;
    }

    path {
      stroke: #434343;
    }

    &:hover {
      path {
        stroke: #fa4616;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  // .dm-chat-messages-footer {
  //   &__form {
  //     &__row {
  //       &__actions {
  //         button {
  //           width: 150px;
  //         }

  //         &__cancel {
  //           width: 100px !important;
  //         }
  //       }
  //     }
  //   }
  // }
}