@import '../../assets/styles/index.scss';

.chat-page {
  height: inherit;

  &__body {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    flex: 1;
    padding: 19px 12px;
    margin-top: 89px;
    width: 100%;
    // height: 100%;
  }
}

@media (max-width:768px){
  .chat-page{
    &__body{
      margin-top: 160px;
    }
  }
}
@media screen and (min-width: $desktop) {
  .chat-page {
    &__body {
      padding: 19px 12px;
      grid-template-columns: minmax(0, 31%) minmax(0, 70.63%);
      // width: 93%;
      height: calc(100vh - 125px);
      width :  calc(100vw - (300px + (max(85px, calc((100vw - 1287px) / 2) - 20px) * 2) - 32px));
    }
  }
}
