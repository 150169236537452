@import '../../../../assets/styles/index.scss';

@mixin manageKeyMembersGridStyling {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  .infinite-scroll-component__footer {
    grid-column: span 1;
  }

  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
    .infinite-scroll-component__footer {
      grid-column: span 2;
    }
  }
}

.share-to-collaborate-modal {
  .modal-component {
    height: 500px;
  }
  &__content {
    flex: 1;
    .share-to-collaborate {
      @include flexCol;
      gap: 16px;
      height: 100%;

      &__search-users {
        @include flexCol;
        gap: 16px;
        position: relative;

        &__container {
          @include flexCol;
          gap: 12px;
          position: absolute;
          top: calc(100% + 5px);
          width: 100%;
          border: 1px solid $neutral-400;
          background: $background-100;
          border-radius: 8px;
          margin: 1px 0;
          padding: 12px;
          z-index: 99;

          &__result {
            max-height: 168px;
            overflow-y: auto;
            @include customScroll;
            @include manageKeyMembersGridStyling;
          }
        }
      }

      &__selected-users {
        @include manageKeyMembersGridStyling;
      }

      &__buttons {
        @include flexRow;
        justify-content: center;
        gap: 8px;
        margin-top: auto;

        button {
          width: 150px;
        }
      }
    }
  }
}
