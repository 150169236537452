@import '../../assets/styles/index.scss';

.resources {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;

  &__body {
    @include flexCol;
    gap: 24px;
    padding: 16px;

    &__content {
      @include flexCol;
      @include flexCenterContent;
      gap: 57px;
      color: $neutral-1000;

      img {
        width: 324px;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .resources {
    &__body {
      padding: 32px;
    }
  }
}
