@import '../../../../../assets/styles/index.scss';

.view-program-submitted-applications {
  @mixin submittedApplicationGridStyle {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media screen and (min-width: $tablet) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @include flexCol;
  gap: 16px;
  height: 100%;

  &__applicants {
    @include submittedApplicationGridStyle;
    padding-bottom: 50px;
  }
}
