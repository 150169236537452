@mixin OptionStyling {
  @include flexRow;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  height: 41px;
  line-height: 41px;
  list-style: none;
  padding: 0 16px;
  white-space: nowrap;
  @include bodyTwo;
  @include mediumTypography;
  &:hover,
  &:focus-within,
  &--selected {
    color: $primary-600;
    background: $primary-200;
  }
  &:hover {
    // Lighter color for hover state
    background: $primary-100;
  }
  &:focus {
    outline: none;
  }
  input {
    cursor: pointer;
  }
}
