.cardMain {
    position: relative;
    .cardRedirect {
        display: flex;
        align-items: center;
        gap: 14px;
        border: 1px solid #ffe5d5;
        border-radius: 15px;
        background: #FFFBF9;
        padding: 14px 15px;
        text-decoration: none;
        color: initial;

        .imgBox {
            min-width: 100px;
            max-width: 100px;
            border-radius: 8px;
            height: 100px;
            overflow: hidden;
            background: #e8e8e8;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    &__cross-icon{
        position: absolute;
        top: 10px;
        right: 10px;
        background: #fff;
        height: 12px;
        width: 12px;
        cursor: pointer;
    }
}