@import '../../../assets/styles/index.scss';

.action-card {
  @include flexCol;
  gap: 12px;
  color: $neutral-1000;

  .user-top-3-fallback {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff7f5d;
    color: #fff;
    border: 1px solid #ffffffac;
    cursor: pointer;
  }
  &__userImgs {
    ul {
      margin: 8px 0px;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-left: -22px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          cursor: pointer;
          border: 1px solid #fff;
          object-fit: cover;
        }
      }
    }
  }
}
