@import '../../../../assets/styles/index.scss';

@mixin contactInfoGridStyle {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.profile-about-additional-info {
  @include flexCol;
  gap: 12px;

  &__row {
    @include contactInfoGridStyle;
    color: $neutral-1000;
    &__section {
      @include flexCol;
      gap: 12px;
      &__header {
        @include flexRow;
        justify-content: space-between;
        &__icon {
          height: 32px;
          width: 32px;
          border-radius: 50%;
          cursor: pointer;
          @include flexCenterContent;
          @include hoverOrange;
        }
      }
      &__items {
        @include flexRow;
        flex-wrap: wrap;
        gap: 12px;
      }
    }
  }

  &__industries-modal,
  &__expertise-modal {
    .modal-component {
      min-height: 370px;
      &__content {
        overflow: initial;
        @include flexCol;
        gap: 16px;
      }
    }
    &__buttons {
      @include flexRow;
      justify-content: flex-end;
      gap: 8px;
      button {
        width: 150px;
      }
    }
  }
}
