@import '../../../../assets/styles/index.scss';

.verification-code-component {
  @include flexCol;
  gap: 8px;
  &__label {
    color: $neutral-1100;
  }
  &__input-fields {
    @include flexRow;
    height: 47px;
    gap: 12px;
    &__input-component {
      width: 56px !important;
      input {
        text-align: center;
        color: $neutral-800;
        @include bodyOne;
        @include semiBoldTypography;
      }
    }
  }
}
