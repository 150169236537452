@import '../../../assets/styles/index.scss';

.rocket-discoverTop {
    @include flexRow;
    gap: 17px;
    color: $neutral-1000;
    align-items: center;

    img {
        min-width: 100px;
        max-width: 100px;
    }

    &__right {

        &__heading {
            font-size: 19px;
            line-height: 25px;
            margin-bottom: 5px;
            color: #1D1D1D;
        }

        &__para {
            font-size: 14.79px;
            font-weight: 400;
            line-height: 20.21px;
            color: #121212;
        }

    }
}
