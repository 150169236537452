@import '../../../../../assets/styles/index.scss';

.discussion-message {
  @include flexCol;
  gap: 8px;
  max-width: 90%;
  margin-right: auto;

  &--is-sent {
    border-radius: 16px 0px 16px 16px;
    background: $primary-100;
    margin: 0 0 0 auto;
  }

  &--is-received {
    border-radius: 0px 16px 16px 16px;
    border: 1px solid #ffe5d5;
  }

  &__content {
    @include flexRow;
    gap: 8px;
    padding: 12px;

    .profile-picture {
      height: 24px;
      width: 24px;
      margin-top: 4px;

      h3 {
        font-size: 12px;
      }
    }

    &__right {
      @include flexCol;
      gap: 3px;

      &__row {
        @include flexRow;
        align-items: center;
        gap: 8px;

        &__name {
          cursor: pointer;
          line-height: initial;
          font-weight: 700;
          color: #ff7a4a;
        }

        &__orange-dot {
          height: 6.41px;
          width: 6.41px;
          background: $primary-700;
          border-radius: 50%;
        }
      }

      &__message {
        @include wrapText;
        font-size: 16px;
        margin-bottom: 4px;
      }
    }
  }

  &__header {
    @include flexRow;
    gap: 12px;
    padding: 12px;
    border-radius: 16px 0px 16px 16px;
    justify-content: space-between;
    align-items: center;

    .dropdown-component {
      min-width: 92px;
    }
  }

  &__actions {
    @include flexRow;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    &__left {
      @include flexRow;
      align-items: center;
      gap: 4px;
    }
  }
}

.discuss_MesaageTime {
  display: flex;
  font-size: 11px;
  color: #00000059;
  font-weight: 500;
  justify-content: start;
}

@media screen and (min-width: $desktop) {
  .discussion-message {
    // width: 600px;
  }
}