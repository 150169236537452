@import '../../../assets/styles/index.scss';

.create-post {
  position: relative;
  &__card {
    @include flexCol;
    gap: 16px;
    padding: 16px;
    border-radius: 16px;

    &__form {
      @include flexRow;
      gap: 10px;
      align-items: center;

      &__listed {
        &__upload-input{
          display: none;
        }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          align-items: center;
          gap: 10px;

          li {
            span {
              label{
                cursor: pointer;
              }
              svg {
                width: 19px;
                height: 19px;
              }
              &:hover{
                svg{
                  path{
                    stroke: #fa4616;
                  }
                }
              }
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  &__emoji-picker-container-outside{
    position: absolute;
    top: 60px;
    left:70px;
    z-index: 9;
  }
}