@mixin modalPositioning {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.2);
  @include flexCenterContent;
  display: none;
  &--is-open {
    display: flex !important;
  }
}
