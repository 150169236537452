@import '../../assets/styles/index.scss';

.navigation {
  @include flexCol;
  width: fit-content;
  align-items: center;
  padding: 24px 8px;
  border-right: 1px solid $primary-200;
  position: absolute;
  height: 100dvh;
  background: $white-100;
  z-index: 9;
  transform: translate(-100%);
  transition: all 0.25s linear;
  padding-left: 109px;
  &--is-open {
    transform: translate(0);
    padding: 22px
  }
  &__menu {
    @include flexCol;
    // align-items: center;
    gap: 64px;

    &__htraction-logo {
      width: 160.52px;
      height: 31px;
      cursor: pointer;
      // margin-right: auto;
      margin-left: 15px;
    }
    &__items {
      @include flexCol;
      gap: 8px;
    }
  }
}

@media screen and (min-width: $desktop) {
  .navigation {
    position: static;
    transform: translate(0);
    width: 300px;
    padding: 24px;
    // margin-left: calc(109px - 24px);
    margin-left: max(85px, calc((100vw - 1287px) / 2) - 39px);
    &--is-open {
      transform: translate(0);
    }
  }
}
