@import '../../../../assets/styles/index.scss';

.person-card {
  position: relative;
  .pendingStatusCard{
    position: absolute;
    top: 25px;
    right: 10px;
    max-height: 18px;
    min-height: 18px;
    max-width: 18px;
    min-width: 18px;
  }
  @include flexRow;
  align-items: center;
  gap: 8px;
  min-width: 210px;

  &__profile-pic {
    height: 44px;
    width: 44px;
  }

  &__person-details {
    
    @include flexCol;
    gap: 4px;
    color: $neutral-1100;
    position: relative;


    &__row {
      @include flexRow;
      align-items: center;
      gap: 4px;

      &__name,
      &__role {
        line-height: initial;
      }

      &__role {
        color: $neutral-800;
      }

      hr {
        width: 1px;
        height: 19px;
        border: none;
        background: $neutral-300;
      }
    }

    &__email {
      color: $neutral-800;
    }
  }
}
