@import '../../../assets/styles/index.scss';

.view-reply {
  @include flexCol;
  gap: 12px;
  &__header {
    @include flexRow;
    justify-content: space-between;
    align-items: center;

    &__user-info {
      @include flexRow;
      align-items: center;
      gap: 10px;
      &__name {
        color: $neutral-1000;
      }
      &__role {
        color: $neutral-700;
      }
      hr {
        width: 1px;
        border: none;
        background: $neutral-200;
        height: 19px;
      }
    }

    &__dropdown {
      position: relative;
      @include flexCenterContent;
      cursor: pointer;
      width: 34px;
      height: 34px;
    }
  }

  &__content {
    color: $neutral-1000;
  }

  &__interactions {
    @include flexRow;
    align-items: center;
    gap: 16pxs;
    &__like {
      width: fit-content;
    }
    &__replies {
      width: fit-content;
    }
  }
}
