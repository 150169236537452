@import '../../assets/styles/index.scss';

.header-component {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: #fff;
  border-bottom: 1px solid $primary-200;
  position: fixed;
  z-index: 8;
  &__full-page{
    margin: 0 auto;
    width: 1360px
  }
  &__htraction-logo{
    width: 160.52px;
    height: 31px;
    cursor: pointer;
    display: none;
  }
  &__search {
    width: 100%;
  }
  &__open-navigation {
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-left: 10px;
    @include hoverTransition;
    @include flexCenterContent;
    @include hoverOrange;
    align-self: flex-end;
  }
  &__right {
    @include flexRow;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    width: 100%;
    position: relative;
    padding: 8px;
    gap: 8px;

    &__notifications {
      height: 40px;
      width: 40px;
      cursor: pointer;
      @include flexCenterContent;
      @include hoverOrange;
      border-radius: 50%;
      flex-shrink: 0;
      position: relative;

      &__new-notification {
        position: absolute;
        top: 13px;
        right: 11px;
        height: 9px;
        width: 9px;
        border-radius: 50%;
        background: $error-600;
        border: 1px solid $white-100;
        color: $error-600;
        
      }
      span{
        color: #fa4616;
        font-size: 12px;
        font-weight: 600;
        position: absolute;
        top: 0px;
        right: 7px;
        // transform: translate(0, -20px);
        // height: 9px;
        // width: 9px;
      }
    }
    &__menu {
      @include flexRow;
      gap: 8px;
      align-items: center;
      padding: 0 8px;
      height: 41px;
      color: $neutral-800;
      &__arrow {
        cursor: pointer;
        height: 24px;
        width: 24px;
        @include flexCenterContent;
        border-radius: 50%;
        @include hoverOrange;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .header-component__without-login {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: $tablet) {
  .header-component {
    @include flexRow;
    &__right {
      justify-content: initial;
      width: initial;
      flex-wrap: nowrap;
    }
  }
}

@media screen and (min-width: $desktop) {
  .header-component {
    padding: 8px 16px;
    width: calc(100% - 300px);
    &__full-page{
      width: 1360px;
      margin: 0 auto;
      padding: 16px 0px 16px 34px;;
    }
    &__excluded-page{
      width: 100%;
    }
    &__htraction-logo{
      display: block;
    }
    &__open-navigation {
      display: none;
    }
    // padding: 16px 142px 16px 34px;
    padding: 16px max(145px,(100vw - 1287px) - 100px) 16px 32px;
    height: 89px;
    &__search {
      width: 600px;
    }
    &__right {
      &__menu {
        padding: 0 16px;
      }
    }
  }
}
@media (max-width: 1279px){
  .header-component{
    width: 100%;
    
  }
}
@media (max-width: 768px) {
  .header-component{
    width: 100%;
  }
}
