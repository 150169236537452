@import '../../../../../../assets/styles/index.scss';

.custom-application-form-section {
  @include flexCol;
  gap: 3px;
  padding: 16px;

  &__heading {
    @include flexRow;
    justify-content: space-between;
    align-items: center;

    &__delete {
      width: 149px;
    }
  }

  &__add-field {
    width: 94px;
  }
}

@media screen and (min-width: $desktop) {
  .custom-application-form-section {
    gap: 10px;
  }
}
