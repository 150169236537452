.createSurvey {
    .titleHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .title {
        font-size: 16px;
        margin: 0;
      }
    }
    .container {
      .inputFields {
        margin: 0 auto;
        margin-bottom: 14px;
      }
    }
    .teamCard {
      background-color: #fffbf9;
      border-radius: 8px;
      padding: 6px 10px;
      height: auto;
      min-height: 80px;
      width: 100%;
      cursor: pointer;
      border: 1px solid #ffe5d5;
      margin: 12px 0;
      position: relative;
      .removeIcon {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-10px, 3px);
      }
      &.active {
        border: 1px solid #fa4616;
      }
      .profile {
        display: flex;
        gap: 10px;
        row-gap: 14px;
        align-items: center;
        margin-top: 10px;
        .teamDetails {
          width: 63%;
        }
      }
      .addRemoveButton {
        display: flex;
        width: 80%;
        align-content: center;
        justify-content: space-between;
        margin: 6px auto;
        span {
          font-size: 18px;
          color: #fa4616;
          background: #fff;
          border: 1px solid #ffe5d5;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 30px;
          min-width: 30px;
          cursor: pointer;
        }
      }
    }
    .teamContainer {
      max-height: 210px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 5px;
      .emptyMessage {
        font-size: 13px;
        font-weight: 500;
        margin: 0;
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 8px;
        background: #f8f8f8;
        border-radius: 100px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #fa4616;
        border-radius: 100px;
      }
    }
  
    :global(.insideCard) {
      background: #fff;
      border-radius: 8px;
      border: 1px solid #ffe5d5;
      width: 100%;
      height: auto;
      padding: 12px 16px;
      margin-bottom: 10px;
    }
  }
  