@import '../../../assets/styles/index.scss';

.idea-details {
  @include flexCol;
  gap: 16px;

  &__sub-sections {
    @include flexCol;
    gap: 16px;

    &__menu {
      @include flexRow;
      gap: 8px;
      flex-wrap: wrap;

      &__item {
        height: 43px;
        border-radius: 8px;
        width: fit-content;
        padding: 8px;
      }
    }
    &__component {
      flex: 1;
    }
  }
}

@media screen and (min-width: $tablet) {
  .idea-details {
    gap: 24px;

    &__sub-sections {
      @include flexRow;
      gap: 32px;

      &__menu {
        @include flexCol;
        width: 260px;
        padding: 16px;

        &__item {
          width: 100%;
          padding: 12px 16px;
        }
      }
    }
  }
}

.scrollable-container {
  @include flexCol;
  max-height: 420px; // Adjust this value to suit your design
  overflow-y: auto; // Enables vertical scrolling
  overflow-x: hidden; // Prevents horizontal scrolling
  padding-right: 8px; // Optional: Adds space to avoid scrollbar overlap
}

.idea-details-actions__actions__finalize-idea {
  margin-left: auto;
  margin-right: auto;
}
