@import '../../../assets/styles/index.scss';

.invite-user-modal {
  .invite-user {
    @include flexCol;
    gap: 12px;

    &__heading {
      color: $neutral-1100;
    }
    &__form {
      @include flexCol;
      gap: 24px;
      &__buttons {
        @include flexRow;
        align-self: flex-end;
        gap: 24px;
        button {
          width: fit-content;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .invite-user-modal {
    &__modal {
      width: 548px !important;
    }
    .invite-user__form__buttons button {
      width: 150px;
    }
  }
}
