@import '../../../../assets/styles/index.scss';

@mixin verticalBarStyling {
  margin: 0 10px;
  width: 1px;
  color: $neutral-400;
}

.institute-card {
  @include flexRow;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  &__picture {
    width: 44px;
    height: 44px;
    // border-radius: 50%;
    border-radius: 20px;
    overflow: hidden;
    img {
      width: 36px;
      height: 36px;
      // border-radius: 50%;
    }
  }
  &__content {
    @include flexCol;
    flex: 1;
    gap: 8px;
    &__header {
      @include flexRow;
      justify-content: space-between;
      &__institute {
        @include flexCol;
        color: $neutral-1000;
        gap: 8px;

        &__degree {
          @include flexRow;
          hr {
            @include verticalBarStyling;
          }
        }
      }
      &__actions {
        @include flexRow;
        gap: 24px;
        align-items: center;
        &__action {
          @include flexCenterContent;
          @include hoverTransition;
          cursor: pointer;
          height: 32px;
          width: 32px;
          border-radius: 50%;
          @include hoverOrange;
        }
      }
    }
    &__duration {
      @include flexRow;
      color: $neutral-900;
      hr {
        @include verticalBarStyling;
      }
    }
  }
}
