@import '../../assets/styles/index.scss';

.force-change-password {
  @include flexCol;
  width: 100%;
  gap: 16px;

  &__header {
    color: $neutral-1000;
    @include flexCol;
    gap: 12px;
  }

  &__form {
    @include flexCol;
    width: 100%;
    gap: 16px;

    &__force-change-password {
      @include captionTypography;
      @include semiBoldTypography;
      cursor: pointer;
      text-decoration: underline 0.1px;
      color: $primary-700;
    }
  }
}

@media screen and (min-width: $tablet) {
  .force-change-password {
    padding: 0 0 64px 0;
  }
}
