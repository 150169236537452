@import '../../../assets/styles/index.scss';

@mixin editProfileGridStyle {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.edit-profile-modal {
  .modal-component__content {
    overflow: hidden;
  }
}

.edit-profile {
  &__form {
    @include flexCol;
    gap: 12px;

    &__fields {
      max-height: 364px;
      overflow-y: scroll;
      @include flexCol;
      gap: 16px;
      @include customScroll;
      padding-right: 8px;
      &__grid {
        @include editProfileGridStyle;
        &__field {
          @include flexCol;
          gap: 16px;
          color: $neutral-1100;
          input[type='date']::-webkit-calendar-picker-indicator {
            display: none;
          }
        }

        // Hide arrows/spinners from input type number
        // www.w3schools.com/howto/howto_css_hide_arrow_number.asp
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }

      &__address {
        @include flexCol;
        gap: 16px;
        color: $neutral-1100;
        &__grid {
          @include editProfileGridStyle;
        }
      }
    }

    &__buttons {
      @include flexRow;
      justify-content: center;
      gap: 8px;
      button {
        width: 150px;
      }
    }
  }
}
