@use '../../../assets/styles/index.scss' as styles;
// <<<<<<<<box 1>>>>>>>>>
.aboutFoundersImage {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin: 5px 0px;
  .aboutFounderscontent{
    display: flex;
    min-width: 50%;
    cursor: pointer;
  }
  img {
    border-radius: 100px;
    width: 50px;
    height: 50px;
  }

  h4 {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    margin: 0px;
    line-height: 19px;
    margin-left: 5px;
    font-family: 'Manrope', sans-serif;
    @include styles.lineClamp(1)
  }

  span {
    font-size: 12px;
    font-weight: 500;
    color: #000;
    font-family: 'Manrope', sans-serif;
    @include styles.lineClamp(1)
  }
}
.aboutFoundersButton {
  display: flex;
  flex-direction: row;
  gap: 9px;
}

// <<<<<<<<box 2>>>>>>>>>

.aboutFoundersImage {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin: 5px 0px;

  .coFounderInner {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;

    img {
      border-radius: 100px;
      width: 40px;
      height: 40px;
    }

    .coFounderContent {
      h4 {
        font-size: 14px;
        font-weight: 600;
        color: #1d1d1d;
        margin: 0px 0px -3px;
        font-family: 'Manrope', sans-serif;
        @include styles.lineClamp(1)
      }
      span {
        font-size: 11.8px;
        font-weight: 400;
        color: #6e6e6e;
        font-family: 'Manrope', sans-serif;
        display: block;
        @include styles.lineClamp(1)
      }
    }
  }
  button {
    width: 30%;
  }
}

// <<<<<<<<box 3>>>>>>>>>
.upVoterViews {
  display: flex;
  gap: 2px;
  padding: 0px;
  flex-wrap: wrap;
  row-gap: 12px;
  margin-bottom: 0;

  li {
    list-style: none;
    position: relative;
    cursor: pointer;

    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      object-position: center;
      border-radius: 100px;
      cursor: pointer;
    }
    span {
      position: absolute;
      left: 0;
      width: 60px;
      height: 60px;
      background-color: #ff623999;
      border-radius: 100px;
      z-index: 999;
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 60px;
    }
  }
}

// <<<<<<<<box 4>>>>>>>>>
.ideaslikeOuter {
  padding: 0px;
  margin-bottom: 0;

  li {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    margin: 0px 0px 16px;
    justify-content: space-between;
    row-gap: 30px;

    .ideaslikeInner {
      display: flex;
      width: 100%;
      gap: 10px;
      align-items: center;

      img {
        width: 42px;
        height: 42px;
        object-fit: cover;
        object-position: top;
        border-radius: 8px;
      }

      .ideaslikeContent {
        h4 {
          font-size: 14px;
          font-weight: 600;
          color: #1d1d1d;
          margin: 0px 0px -3px;
          font-family: 'Manrope', sans-serif;
        }
        span {
          font-size: 11.8px;
          font-weight: 400;
          color: #6e6e6e;
          font-family: 'Manrope', sans-serif;
        }
      }
    }
  }
  li:last-child {
    margin-bottom: 0px;
  }
  button {
    width: 30%;
  }
}
