// @import '../../../../assets/styles/index.scss';

// .idea-details-section-view {
//   @include flexCol;
//   gap: 16px;
//   margin-bottom: 20px;
//   &__fields {
//     @include flexCol;
//     gap: 16px;
//   }
// }

@import '../../../../assets/styles/index.scss';

.idea-details-section-view {
  @include flexCol;
  gap: 16px;
  margin-bottom: 20px;

  &__fields {
    @include flexCol;
    gap: 16px;
    max-height: 360px; // Adjust this value to suit your design
    overflow-y: auto; // Enables vertical scrolling
    overflow-x: hidden; // Prevents horizontal scrolling
    padding-right: 8px; // Optional: Adds space to avoid scrollbar overlap
  }
}

