@import '../../../assets/styles/index.scss';

.view-post { 
  &__comment-reply-box{
    display: flex;
    flex-direction: column;
    gap: 10px
  }
  @include flexCol;
  gap: 24px;
  padding: 12px !important;

  &__comments {
    @include flexCol;
    gap: 16px;
  }

  &__load-more-comments {
    cursor: pointer;
    color: $primary-600;
  }

  // Overrides
  & > .card-component {
    border: none;
  }
}
