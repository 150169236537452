// Neutral colors
$neutral-100: #f8f8f8;
$neutral-200: #f2f2f2;
$neutral-300: #dadada;
$neutral-400: #bfbfbf;
$neutral-500: #a4a4a4;
$neutral-600: #898989;
$neutral-700: #6e6e6e;
$neutral-800: #535353;
$neutral-900: #383838;
$neutral-1000: #1d1d1d;
$neutral-1100: #121212;

// Primary colors
$primary-100: #fff3ed;
$primary-200: #ffe5d5;
$primary-300: #fec6aa;
$primary-400: #fe9e73;
$primary-500: #fc6c3b;
$primary-600: #fa4616;
$primary-700: #eb2b0b;
$primary-800: #c31c0b;
$primary-900: #9b1911;
$primary-1000: #7c1812;

// Error colors
$error-100: #fef2f2;
$error-200: #fee2e2;
$error-300: #fecaca;
$error-400: #fca5a5;
$error-500: #f87171;
$error-600: #ef4444;
$error-700: #dc2626;
$error-800: #b91c1c;
$error-900: #991b1b;
$error-1000: #7f1d1d;

// Success colors
$success-100: #ecfdf5;
$success-200: #d1fae5;
$success-300: #a7f3d0;
$success-400: #6ee7b7;
$success-500: #34d399;
$success-600: #10b981;
$success-700: #059669;
$success-800: #047857;
$success-900: #065f46;
$success-1000: #064e3b;

// White colors
$white-100: #ffffff;
$white-200: #f3f7fb; // color not in the palette

// Black colors
$black-100: #000;

// background colors
$background-100: #fffbf9;
