@import '../../../assets/styles/index.scss';

.dropdown-option-card-component {
  background: $white-100;
  color: $neutral-1100;
  cursor: pointer;
  @include hoverTransition;
  @include flexCenterContent;
  z-index: 9999;
  user-select: none;
  white-space: nowrap;

  &:hover {
    background: $primary-100;
  }

  //   Sizes
  &--small {
    height: 37px;
    padding: 0 24px;
    @include captionTypography;
    @include mediumTypography;
  }

  &--large {
    height: 43px;
    padding: 0 24px;
    @include bodyTwo;
    @include mediumTypography;
  }

  //   Variants
  &--active {
    background: $primary-200;
  }

  // alignments
  &--left {
    justify-content: flex-start;
  }
  &--center {
    justify-content: flex-center;
  }
  &--right {
    justify-content: flex-end;
  }
}
