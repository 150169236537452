@import '../../../../assets/styles/index.scss';

.label-component {
  color: $neutral-1100;
  &__required {
    color: $error-800;
  }

  // Mandatory field label
  &.label-mandatory-field {
    &::after {
      content: '*';
      color: $error-800;
    }
  }
}
