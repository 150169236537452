@import '../../../../../../assets/styles/index.scss';

.search-program-member-item {
  @include flexRow;
  justify-content: space-between;
  gap: 16px;
  align-items: center;

  &__user-info {
    @include flexRow;
    align-items: center;
    gap: 24px;

    &__profile-pic {
      height: 36px;
      width: 36px;
    }

    &__user {
      @include flexCol;
      gap: 4px;
      color: $neutral-800;

      &__row {
        @include flexCol;
        align-items: flex-start;
        gap: 8px;

        &__name {
          color: $neutral-1100;
          width: 100%;
          word-break: break-word;
        }

        hr {
          display: none;
          height: 19px;
          border: none;
          width: 1px;
          background: $neutral-400;
        }

        &__email {
          width: 100%;
          word-break: break-word;
        }
      }
    }
  }

  &__action {
    width: 81px;
  }
}

@media screen and (min-width: $tablet) {
  .program-member-list-item {
    width: 336px;

    &__user-info {
      &__user {
        &__row {
          @include flexRow;
          align-items: center;

          hr {
            display: block;
          }
        }
      }
    }
  }
}
