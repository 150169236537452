@import '../../../../../assets/styles/index.scss';

.ih-g-chat-messages-footer {
  position: relative;
  &__form {
    @include flexCol;
    gap: 8px;

    &__row {
      @include flexRow;
      gap: 16px;
      align-items: center;
      width: 100%;

      .input-field-component {
        flex: 1;
      }

      &__actions {
        @include flexRow;
        gap: 8px;
        button {
          width: fit-content;
        }
      }

      &__files {
        @include flexRow;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
      }
    }

    .EmojiPickerReact {
      position: absolute;
      bottom: 0;
    }
  }

  &__actions {
    @include flexRow;
    gap: 16px;
  }
}

@media screen and (min-width: $desktop) {
  .ih-g-chat-messages-footer {
    &__form {
      &__row {
        &__actions {
          button {
            width: 150px;
          }

          &__cancel {
            width: 100px !important;
          }
        }
      }
    }
  }
}
