@import '../../../../assets/styles/index.scss';

.create-new-idea-card {
  @include flexCol;
  @include flexCenterContent;
  cursor: pointer;
  gap: 12px;
  color: $primary-600;
  min-height: 200px;
  border: 1px solid $primary-600;
  padding: 0;
  overflow: hidden;

  &__create-img{
    height: 176px;
    width: 100%;
  }
}
