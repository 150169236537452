@import '../../../assets/styles/index.scss';

.action-icon-component {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
  @include hoverTransition;
  @include flexCenterContent;
  @include hoverOrange;

  &--is-active {
    background: $primary-200;
    color: $primary-600;
    svg path {
      stroke: $primary-600;
    }
  }
}
