@import '../../../assets/styles/index.scss';

.carousel-component {
  position: relative;
  // border-radius: 16px;
  height: 100%;
  width: 100%;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  @include flexCenterContent;
  img,
  video {
    // object-fit: contain !important;
    // width: fit-content !important;
    // height: 100%;
    // border-radius: 16px;
    user-select: none;
  }

  &__handler {
    position: absolute;
    top: 50%;
    height: 40px;
    width: 40px;
    @include flexCenterContent;
    background: #000;
    opacity: 50%;
    border-radius: 50%;
    cursor: pointer;
    svg path {
      stroke: $neutral-200;
    }
  }
  &__previous {
    left: 16px;
    transform: translateY(-50%);
    z-index: 9999;
    &--is-hidden {
      visibility: hidden;
    }
  }
  &__next {
    right: 16px;
    transform: translateY(-50%) rotate(180deg);
    z-index: 9999;
    &--is-hidden {
      visibility: hidden;
    }
  }
  &__label {
    position: absolute;
    top: 15px;
    right: 15px;
    height: fit-content;
    width: fit-content;
    padding: 8px;
    background: #000;
    opacity: 70%;
    @include hoverTransition;
    color: $neutral-200;
    text-align: center;
    @include flexCenterContent;
    border-radius: 50%;
  }

  &__video {
    position: relative;
    &__play-button {
      position: absolute;
      height: 40px !important;
      width: 40px !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
