@import '../../../assets/styles/index.scss';

.chat-list-user-card {
  @include flexRow;
  justify-content: space-between;
  gap: 16px;
  padding: 8px 12px;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid $primary-200;

  &--is-selected {
    background: $primary-200;
  }

  &__left {
    @include flexRow;
    gap: 10px;
    align-items: center;
    max-width: 70%;

    &__user-details {
      @include flexCol;
      gap: 0px;

      p {
        cursor: pointer;
      }
    }
  }

  &__right {
    max-width: 30%;
    &__unread-count {
      display: flex;
      @include flexCenterContent;
      height: 18px;
      min-width: 18px;
      max-width: 18px;
      border-radius: 50%;
      color: $neutral-200;
      background: $primary-600;
      margin-left: auto;
      font-size: 10px;
      line-height: normal;
      padding: 0;

      &--is-hidden {
        visibility: hidden;
      }
    }

    &__updated-at {
      text-align: right;
      align-self: flex-end;
      white-space: nowrap;
      color: #00000059;
      font-size: 12px;
    }
  }
}

.namePerson {
  font-size: 14.92px;
  margin-bottom: 2px;
  color: #1D1D1D;
  text-transform: capitalize;
  font-weight: 600;
}

.profileRole {
  font-size: 13.64px;
  color: #6E6E6E;
  display: flex;
  gap: 3px;
}