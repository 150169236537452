@mixin flexRow {
  display: flex;
  flex-direction: row;
}

@mixin flexCol {
  display: flex;
  flex-direction: column;
}

@mixin flexJustifyContentCenter {
  justify-content: center;
}

@mixin flexJustifyContentSpaceBetween {
  justify-content: space-between;
}

@mixin flexAlignItemsCenter {
  align-items: center;
}

@mixin flexCenterContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
