@import '../../assets/styles/index.scss';

.email-verification {
  @include flexCol;
  width: 100%;
  gap: 16px;

  &__heading {
    color: $neutral-1000;
  }
  &__caption {
    color: $neutral-1000;
  }
  &__form {
    @include flexCol;
    width: 100%;
    gap: 16px;

    &__verification-code label {
      font-size: 12.8px;
      line-height: 17.48px;
    }
    &__resend-code {
      @include flexRow;
      justify-content: space-between;
      color: $primary-600;

      &__link {
        cursor: pointer;
        text-decoration: underline 0.1px;
        &--disabled {
          color: $neutral-600;
          pointer-events: auto;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .email-verification {
    padding: 0 0 64px 0;
  }
}
