.ventureCardOuter {
    border-radius: 16px;
    border: 1px solid #ffe5d5;
    background: #fff;
    overflow: hidden;
    cursor: pointer;

    img{
        width: 100%;
        object-fit: cover;
        object-position: top;
        height: 175.5px;
    }
    .ventureCardContent{
        padding: 12px;

        h3 {
            font-size: 14px;
            font-weight: 700;
            line-height: 19.84px;
            color: #1d1d1d;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        h5 {
            font-size: 12px;
            font-weight: 400;
            color: #111111;
            display: flex;
            align-items: center;
            gap: 6px;

            span {
                background: #fa4616;
                color: #ffffff;
                border-radius: 100px;
                padding: 3px 10px;
                font-size: 10px;
            }
        }

        p {
            color: #000000;
            font-size: 13px;
            font-weight: 400;
            margin: 10px 0 10px;
            line-height: 17.76px;
            min-height: 38px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-height: 45px;
        }
        .ventureCardButton {
            display: flex;
            justify-content: space-between;
            gap: 5px;
        }
    }    
}

@media (max-width: 768px) {
    .banner_BannerOuter {
       p {
        width: 66%;
       }
    }
}

@media (max-width: 576px) {
    .banner_BannerOuter {
        h3 {
            font-size: 20px;
            width: 65%;
        }
        .banner_BannerOuter__4IegK p {
            font-size: 7px;
            width: 60%;
        }
        
    }
    .ventureCardOuter{
        img {
            height: 200px;
        }
    }
}
    

