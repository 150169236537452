.topbar {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 14px;

  .searchfilter {
    height: 92px;
    gap: 0px;
    border-radius: 20px;
    border: 1px solid #ffe5d5;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    margin-bottom: 14px;

    .actionIcon {
      margin: 0;
      background: #fa4616;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        filter: contrast(4.5);
      }
    }
    .filters {
      display: flex;
      align-items: center;
      gap: 12px;
      height: 100%;
      button {
        background: none;
        padding: 8px 0;
        gap: 10px;
        border-radius: 100px;
        border: 1px solid #898989;
        margin: 0;
        min-width: 106px;
        color: #898989;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
          background: #fa4616;
          color: #fff;

          img {
            filter: contrast(20);
          }
        }
        span {
          margin: 0;
        }
      }
    }
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  .actionButton {
    border: none;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    &.disabled {
      color: #6e6e6e;
    }
  }
  .pages {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .page {
      font-weight: 400;
      font-size: 14px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.active {
        background: #fa4616;
        color: #fff;
      }
    }
  }
}


@media (max-width:380px){
  .searchfilter {
    padding: 0 2px !important;
  }
  .filters {
    button {
      min-width: 85px !important;
      img{
        display: none;
      }
    }
  }
}