@import '../../../../assets/styles/index.scss';

.search-ih-user-result {
  @include flexRow;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  padding: 8px 0;

  &__user-info {
    @include flexRow;
    align-items: center;
    gap: 8px;

    input {
      flex-shrink: 0;
    }

    &__profile-pic {
      height: 36px;
      width: 36px;
    }

    &__user {
      @include flexCol;
      gap: 4px;
      color: $neutral-800;

      &__row {
        @include flexRow;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 8px;

        &__name {
          color: $neutral-1100;
          width: 100%;
          word-break: break-word;
          cursor: pointer;
        }

        hr {
          height: 19px;
          border: none;
          width: 1px;
          background: $neutral-400;
        }

        &__email {
          width: 100%;
          word-break: break-word;
        }
      }
    }
  }

  &__action {
    width: 81px;
  }
}
