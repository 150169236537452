@import '../../../assets/styles/index.scss';

.back-navigation-component {
  color: $neutral-1100;
  @include flexRow;
  @include flexAlignItemsCenter;
  gap: 8px;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  &:hover {
    color: $primary-600;
    svg path {
      stroke: $primary-600;
    }
  }
}
