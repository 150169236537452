@import '../../../../../../assets/styles/index.scss';

.view-discussion-members {
  @mixin membersGridStyling {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media screen and (min-width: $desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__modal {
    gap: 16px !important;
  }

  &__content {
    @include flexCol;
    gap: 16px;
    height: 423px;
  }

  &__users {
    @include membersGridStyling;
    max-height: 274px;
    overflow-y: auto;
    @include customScroll;
  }

  &__close {
    align-self: center;
    margin-top: auto;
    width: 150px;
  }
}
