@import '../../assets/styles/index.scss';

.venture {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;
  &__body {
    margin-right: 58px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    padding: 16px;
    margin-right: 0;
    margin-top: 89px;
    margin-left: 2px;

    &__feed {
      @include flexCol;
      gap: 24px;

      &__items {
        width: 100%;
        @include flexCol;
        gap: 24px;
      }
      &__actions{
        display: flex;
        justify-content: space-between;

        &__filters{
          display: flex;
          align-items:'center';
          gap: 10px
        }
      }
    }
  }
  &__cards {
    @include flexCol;
    gap: 24px;
  }
}

@media (max-width: 768px) {
  .venture {
    &__body {
      margin-top: 160px;
    }
  }
}
@media screen and (min-width: $desktop) {
  .venture {
    &__body {
      display: grid;
      grid-template-columns: 100%;
      padding: 45px 32px 32px 32px;
      margin-top: 89px;
      margin-right: 61px;
      margin-right: max(61px,(100vw - 1287px) / 2 - 63px);
    }
    &__newBody {
      padding: 16px 30px 16px 32px;
      gap: 32px;
    }
  }
}
