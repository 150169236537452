.skeleton-wrapper {
    width: 100%;
  }
  
  .skeleton-image {
    display: inline-block;
    width: 100%;
    height: 200px;
    border-radius: 17px 17px 17px 17px;
    // border: 1px solid #fff;
    background: #e0e0e0;
    animation: shimmer 1.5s infinite linear;
  }
  @keyframes shimmer {
    0% {
      background-position: 100%;
    }
  
    100% {
      background-position: -100%;
    }
  }
  
  .skeleton-image {
    background: linear-gradient(90deg,
        #e0e0e0 25%,
        #f5f5f5 50%,
        #e0e0e0 75%);
    background-size: 200% 100%;
  }
  