.relationship {
  :global(.emptyContainerBoxes) {
    p {
      margin: 0;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .titleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .title {
      font-size: 16px;
      margin: 0;
    }
  }
  .logoBox {
    margin: 7px auto;
    .box {
      height: 130px;
      width: 138px;
      border-radius: 6px;
      background-color: #eff0f5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 24px;
      position: relative;
      cursor: pointer;

      .upload_img_remove {
        position: absolute;
        right: -8px;
        top: -10px;
        z-index: 9;
        border: none;
        background: none;
        padding: 0;
        line-height: 24px;

        :global(svg) {
          width: 20px;
          height: 20px;
          background-color: white;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          border-radius: 50%;
          padding: 3px;
          color: #dd2828;
        }
      }

      .img_content_showName {
        width: 100%;
        height: 100%;
        background: linear-gradient(#65beac, #3e8575);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      &.v2 {
        background-color: #fff;
        border: 1px solid red solid #eff0f5;
      }

      input[type='file'] {
        display: none;
      }

      .boxImage {
        border-radius: 20px;
        width: 60px;
        height: 60px;
        object-fit: cover;
        fill: #7f8699;
      }

      .bottomLogo {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(50%, 30%);
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
        background-color: #fff;
        border-radius: 10px;
        height: 31px;
        width: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        border-radius: 50%;

        img {
          width: 14px;
          cursor: pointer !important;
        }

        input {
          font-size: 2px;
          position: absolute;
          top: 50%;
          left: 0;
          opacity: 0;
        }
      }
    }

    .upload {
      font-weight: 600;
      font-size: 18px;
      color: #7f8699;
    }

    .boxDiv {
      width: 100%;
    }

    h2 {
      font-size: 18px;
      font-weight: 500;
    }

    h4 {
      color: #7f8699;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .actionButtons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 12px 0;
    .actionBtn {
      width: auto;
    }
    .addIcon {
      font-size: 18px;
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }
  .stepper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 18px 0;
  }
  .percentage {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 5px;
    span {
      margin: 0;
      font-size: 16px;
      &.highlight {
        color: #fa4616;
        font-weight: 500;
        margin-top: 1px;
      }
    }
  }
  .fundAction {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 18px 0 14px 0;
    height: 32px;

    input{
      width: 95px;
      height: 35px;
      text-align: center;
    }
    .actionHeading {
      font-size: 15px;
      margin: 0;
    }
    .actionValue {
      color: #fa4616;
      font-weight: 500;
    }
  }
  .details {
    margin-top: 14px;
    .actionHeading {
      font-size: 15px;
    }
    .chatHeading {
      font-size: 14px;
      margin: 0;
      margin: 12px 0;
    }
    .chatBox {
      min-height: 200px;
    }
    .milestonelist {
      margin: 0;
      padding: 0px;
      list-style: none;
      li {
        margin-bottom: 5px;

        :global(.milestoneBox) {
          display: flex;
          align-items: center;
          gap: 5px;
          span {
            min-width: 50%;
            max-width: 50%;

            &.mileStoneHeading {
              font-size: 14px;
              font-weight: 600;
            }
          }
          :global(.milestonePercentageCompletion) {
            min-width: 80px;
          }
          .inputFields {
            // width: 25%;
            min-width: 50px;
            max-width: 100px;
            .comments {
              min-width: 65px;
            }
          }
        }
        .nestedList {
          padding: 0;
          margin: 0;
          list-style: none;
          margin-bottom: 12px;
          .task {
            margin-bottom: 4px;
            .taskHeading {
              font-size: 14px;
              padding-left: 15px;
              font-weight: 600;
            }
          }
        }
      }
    }
    .teamCard {
      background-color: #fffbf9;
      border-radius: 8px;
      padding: 6px 10px;
      height: auto;
      min-height: 80px;
      width: 100%;
      border: 1px solid #ffe5d5;
      display: flex;
      gap: 10px;
      row-gap: 14px;
      margin-bottom: 10px;
      align-items: center;
      a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        display: flex;
        gap: 10px;
        row-gap: 14px;
        align-items: center;
      }
      .teamDetails {
        width: 63%;
      }
    }
  }

  :global(.insideCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 10px 20px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px){
  .fundAction {
    width: 100%;
    margin-bottom: 22px !important;
    :global(.input-field-component){
      width: 100%;
    }
    input{
      width: 100% !important;
    }
  }
}