@import '../../../assets/styles/index.scss';

@mixin profileCardStats {
  @include flexRow;
  flex-wrap: wrap;
  gap: 8px;
  &__stat {
    border-radius: 8px;
    border: 1px solid $primary-200;
    padding: 10px;
    @include flexRow;
    &__values {
      @include flexCol;
      gap: 8px;
      width: 60px;
      text-align: center;
      &__label {
        color: $neutral-800;
      }
      &__value {
        color: $neutral-1000;
      }
    }
    hr {
      display: none;
      border: 1px solid $primary-200;
    }
    &:last-child {
      hr {
        border: none;
      }
    }
  }

  @media screen and (min-width: $tablet) {
    justify-content: flex-start;
    &__stat {
      justify-content: flex-start;
      border: none;
      padding: 0;
      &__values {
        @include flexCol;
        text-align: left;
        gap: 8px;
        width: 30px;
      }
    }
    hr {
      display: block;
      border: 1px solid $primary-200;
      margin: 0 40px;
    }
  }
}

.profile-card {
  @include flexRow;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  &__picture {
    position: relative;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    .profile-picture {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      background: $white-100;
      img {
        object-fit: cover;
      }
      h3 {
        font-size: 20px;
      }
    }
    &__edit {
      position: absolute;
      @include flexCenterContent;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 32px;
      width: 32px;
      border-radius: 50%;
      cursor: pointer;
      @include hoverTransition;
      opacity: 0;
      background: $primary-200;
      svg path {
        stroke: $primary-600;
      }
    }
    &:hover {
      .profile-card__picture__edit {
        opacity: 1;
      }
    }

    &__options {
      display: none;
      position: absolute;
      top: 50px;
      z-index: 9999;
      border: 1px solid $primary-200;
      border-radius: 8px;
      &--is-open {
        display: block;
      }
    }
  }
  &__content {
    @include flexCol;
    flex: 1;
    gap: 8px;
    &__header {
      @include flexRow;
      justify-content: space-between;
      &__user-info {
        @include flexCol;
        color: $neutral-1000;
        gap: 8px;
      }
      &__actions {
        @include flexRow;
        align-items: center;
        gap: 16px;
        &__follow {
          width: 124px;
        }
        &__chat {
          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
    &__introduction {
      color: $neutral-1100;
    }
    &__stats {
      @include profileCardStats;
    }
  }
}

@media screen and (min-width: $tablet) {
  .profile-card {
    &__picture {
      width: 100px;
      height: 100px;
      .profile-picture {
        h3 {
          font-size: 40px;
        }
      }
      &__options {
        top: 100px;
      }
    }
  }
}
