.incubatorsCard {
    .listedView {
        padding: 0;
        margin: 0;
        list-style: none;
        padding-top: 2px;

        li {
            display: flex;
            margin-top: 13px;
            gap: 10px;
            align-items: center;
            cursor: pointer;

            .imgBox {
                min-width: 34px;
                max-width: 34px;
                height: 34px;
                border-radius: 50%;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }

            .contentBox {
                .typography__body-1 {
                    font-size: 14px;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .typography__body-2 {
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
    }
}