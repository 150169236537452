@import '../../../../assets/styles/index.scss';

.input-field-component {
  @include flexCol;
  &--small {
    gap: 8px;
  }
  &--medium {
    gap: 12px;
  }
  &--large {
    gap: 12px;
  }

  &__labelBox {
    display: flex;
    justify-content: space-between;

    &__remove-button {
      font-family: 'Manrope', sans-serif;
      font-size: 12.8px;
      line-height: 17.48px;
      letter-spacing: 0em;
      font-weight: 600;
      color: #fa4616;
      margin-right: 4px;
      cursor: pointer;
    }
  }
}
