@import '../../../../assets/styles/index.scss';

.program-filters {
  @include flexRow;
  align-items: center;
  gap: 8px;

  &__dropdown {
    position: relative;

    .dropdown-component {
      background: $white-100;
      min-width: 120px;
      top: calc(100% + 5px);
      right: 0px;
    }
  }
}
