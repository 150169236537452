@import '../../../assets/styles/index.scss';

.tabs-component {
  // @include flexCol;
  gap: 24px;
  width: 100%;
  height: 100%;
  &__tabs {
    display: flex;
    flex-direction: row;
    width: fit-content;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden; // There will be nothing to hide, but just to avoid vertical scroll
    @include customScroll;
    @include hoverTransition;
    padding-bottom: 1px;
    position: relative;
    margin-bottom: 10px;

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      background: $primary-100;
      bottom: 0;
    }

    &::-webkit-scrollbar {
      height: 3px;
    }
    &::-webkit-scrollbar {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &.overflow__visible{
      &:hover {
        overflow-x: visible !important;
      }
    }
    &:hover {
      // Scroll will be visible only when hovered
      overflow-x: auto;
      &::-webkit-scrollbar {
        background: $primary-100;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $primary-200;
      }
    }
    &__item {
      color: $neutral-700;
      padding: 8px 16px;
      border-bottom: 1px solid $primary-200;
      white-space: nowrap;
      cursor: pointer;
      min-width: 134px;
      text-align: center;
      &--is-active {
        position: relative;
        color: $primary-600;
        font-weight: 600;
        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 2px;
          background: $primary-600;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
      &--is-disabled {
        font-weight: normal;
        cursor: initial;
        pointer-events: auto;
      }
    }
  }

  .subcat_tab{
    overflow: visible !important;

    span{
      &::after{
        display: none;
      }
    }
    .dropdown{
      .dropdown-menu{
        transform: translateY(28px) !important; 
      }
      .dropdown-toggle{
        &.btn{
          background: none;
          border: none;
          color: #000;
          font-weight: 500;

          &:hover{
            color: #fa4616;
          }

          &::after{
            display: none;
          }
        }
      }
    }


    &:hover{
      overflow: visible !important;
    }
  }
}
