@import '../../../assets/styles/index.scss';
@import '../../../components/profile/profileCard/profileCard.scss';

.company-profile-card {
  @include flexCol;
  gap: 12px;
  &__row {
    @include flexRow;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    &__picture {
      position: relative;
      height: 50px;
      width: 50px;
      .profile-picture {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: $white-100;
        img {
          object-fit: contain;
        }
        h3 {
          font-size: 20px;
        }
      }
      &__edit {
        position: absolute;
        @include flexCenterContent;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 32px;
        width: 32px;
        border-radius: 50%;
        cursor: pointer;
        @include hoverTransition;
        opacity: 0;
        background: $primary-200;
        svg path {
          stroke: $primary-600;
        }
      }
      &:hover {
        .company-profile-card__picture__edit {
          opacity: 1;
        }
      }

      &__options {
        display: none;
        position: absolute;
        top: 50px;
        z-index: 9999;
        border: 1px solid $primary-200;
        border-radius: 8px;
        &--is-open {
          display: block;
        }
      }
    }
    &__content {
      @include flexCol;
      flex: 1;
      gap: 8px;
      justify-content: center;
      &__header {
        @include flexRow;
        justify-content: space-between;
        &__company-info {
          @include flexCol;
          color: $neutral-1000;
          gap: 8px;

          h5 {
            cursor: pointer;
          }
        }
        &__actions {
          @include flexRow;
          gap: 16px;
          &__follow {
            width: 124px;
          }
        }
      }
      &__introduction {
        color: $neutral-1100;
        word-break: break-all;
      }
      &__stats {
        @include profileCardStats;
      }
    }
  }

  &__footer {
    @include flexRow;
    gap: 24px;
    &__button {
      width: 140px;
    }
  }
}
.programmingCard {
  .company-profile-card__row {
    align-items: center;
  }

  .company-profile-card__row__content__header__company-info {
    display: flex;
    flex-direction: column;
    color: #1d1d1d;
    gap: 8px;
    max-width: 300px;

    h1 {
      font-size: 32px;
      margin-top: 35px;
      line-height: normal;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .company-profile-card__row__content {
    .company-profile-card__row__content__header {
      flex-wrap: wrap;
    }
  }
}
.program_imgCard {
  max-width: 600px;
  width: 100%;
}

@media screen and (max-width: 520px) {
  .program_imgCard {
    max-width: 100%;
  }
}
@media screen and (min-width: $tablet) {
  .company-profile-card {
    &__row {
      &__picture {
        width: 100px;
        height: 100px;
        .profile-picture {
          h3 {
            font-size: 40px;
          }
        }
        &__options {
          top: 100px;
        }
      }
    }
  }
}
