@import '../../../../assets/styles/index.scss';

.edit-problems-solutions {
  @include flexCol;
  gap: 16px;
  margin-bottom: 20px;
  &__heading {
    color: $neutral-1100;
  }

  &__form {
    @include flexCol;
    gap: 16px;
  }
}
