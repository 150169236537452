@import '../../../../assets/styles/index.scss';

.idea-details {
  .idea-label-value {
    @include flexCol;
    gap: 8px;
    color: $neutral-1100;

    // Style for specific markdown (### or ***)
    // &.special-markdown {
    //   // Additional or overriding styles for special markdown
    // }

    // Style for general markdown
    &.regular-markdown {
      p {
        margin: 0; // Reset margin for all <p> elements
        padding: 0; // Reset padding for all <p> elements
      }

      .markdown-renderer {
        margin: 0; // Remove any container-level margin
        padding: 0; // Remove padding

        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
