@import '../../../../assets/styles/index.scss';

.create-company-page-details {
  @include flexCol;
  align-self: center;
  gap: 16px;

  &__form {
    @include flexCol;
    gap: 16px;

    input {
      @include hideInputNumberFieldArrow;
    }

    &__buttons {
      @include flexRow;
      gap: 16px;
      .button-component {
        width: 150px;
      }
    }

    &__upload-logo {
      height: 48px;
      @include flexRow;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid $neutral-500;
      gap: 8px;
      cursor: pointer;

      &__drag-drop {
        @include flexRow;
        gap: 8px;
        color: $neutral-700;
      }

      &__browse {
        color: $primary-600;
      }
    }

    &__selected {
      height: 100px;
      width: 100px;
      border-radius: 16px;
      position: relative;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      &__delete {
        position: absolute;
        @include flexCenterContent;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: $background-100;
        top: 7px;
        right: 7px;
        cursor: pointer;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .create-company-page-details {
    width: 800px;
  }
}
