@import '../../../assets/styles/index.scss';

.left-menu {
  @include flexRow;
  justify-content: space-between;
  gap: 8px;
  width: 180px;
  border-radius: 16px;
  padding: 12px 16px;
  height: 48px;
  border-bottom: 2px transparent;
  color: $neutral-800;
  cursor: pointer;
  color: $neutral-1000;
  svg:not(.no-hover-state) path {
    stroke: $neutral-1000;
  }
  &:hover {
    background: $primary-100;
    color: $neutral-1000;
    svg:not(.no-hover-state) path {
      stroke: $neutral-1000;
    }
  }

  // Active state
  &--is-active {
    background: $primary-200;
    color: $primary-600;
    font-weight: 600;

    .left-menu__content__title {
      @include semiBoldTypography;
    }
  }
  // Disabled state
  &--is-disabled {
    pointer-events: auto;
    background: $neutral-100;
    color: $neutral-600;
  }

  &__content {
    @include flexRow;
    align-items: center;
    width: inherit;
    gap: 8px;

    &__icon {
      height: 24px;
      width: 24px;
      @include flexCenterContent;
      svg {
        max-height: 100%;
        max-width: 100%;
        path {
          stroke: $neutral-800;
        }
      }
      &--is-active {
        svg path {
          stroke: $primary-600;
        }
      }
      &--is-disabled {
        svg path {
          stroke: $neutral-600;
        }
      }
    }

    &__end-icon {
      height: 24px;
      @include flexCenterContent;
      margin-left: auto;
      svg {
        max-height: 100%;
        max-width: 100%;
      }
    }

    &__title {
      @include textEllipsis;
    }
  }

  &__notifications {
    display: flex;
    flex-shrink: 0;
    @include flexCenterContent;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    color: $neutral-200;
    background: $primary-600;
    caption {
      @include textEllipsis;
    }
  }
}

@media screen and (min-width: $tablet) {
  .left-menu {
    width: 252px;
  }
}
