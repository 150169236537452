@import '../../../assets/styles/index.scss';

.settings-change-password {
  @include flexCol;
  row-gap: 16px;
  border-radius: 16px;

  &__heading {
    color: $neutral-1000;
  }

  &__form {
    @include flexCol;
    row-gap: 16px;

    &__old-password {
      @include flexCol;
      row-gap: 8px;

      &__forgot-password {
        cursor: pointer;
        text-decoration: underline 0.1px;
        color: $primary-600;
      }
    }

    &__new-password {
      @include flexCol;
      gap: 16px;
    }

    &__submit {
      width: 160px;
    }
  }
}

@media screen and (min-width: $desktop) {
  .settings-change-password__form__old-password__field {
    @include flexRow;
    .input-field-component {
      flex-basis: 50%;
    }
  }
  .settings-change-password__form__new-password {
    @include flexRow;
    .input-field-component {
      flex-basis: 50%;
    }
  }
}
