.relationship {
  .titleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .title {
      font-size: 16px;
      margin: 0;
    }
  }
  .actionButtons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    .actionBtn {
      width: auto;
    }
    .addIcon {
      font-size: 18px;
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }
  .percentage {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 5px;
    span {
      margin: 0;
      font-size: 16px;
      &.highlight {
        color: #fa4616;
        font-weight: 500;
        margin-top: 1px;
      }
    }
  }
  .fundAction {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 18px 0 14px 0;
    height: 32px;
    input{
      width: 95px;
      height: 35px;
      text-align: center;
    }
    .actionHeading {
      font-size: 15px;
      margin: 0;
    }
    .actionValue {
      color: #fa4616;
      font-weight: 500;
    }
  }
  .TrashIcon {
    position: absolute;
    top: -5px;
    right: 28px;
    .dots {
      height: 18px;
      width: 18px;
    }
  }
  .TeamColumn {
    position: relative;
  }
  .details {
    margin: 16px 0;
    .actionHeading {
      font-size: 15px;
    }
    .about{
      font-size: 14px;
      margin: 0;
    }
    .milestonelist {
      margin: 0;
      padding: 0px;
      list-style: none;
      li {
        margin-bottom: 5px;

        :global(.milestoneBox) {
          display: flex;
          align-items: center;
          gap: 5px;
          span {
            min-width: 50%;
            max-width: 50%;

            &.mileStoneHeading {
              font-size: 14px;
              font-weight: 600;
            }
          }
          :global(.milestonePercentageCompletion) {
            min-width: 80px;
          }
          .inputFields {
            // width: 25%;
            min-width: 50px;
            max-width: 100px;
            .comments {
              min-width: 80px;
            }
          }
        }
        .nestedList {
          padding: 0;
          margin: 0;
          list-style: none;
          margin-bottom: 12px;
          .task {
            margin-bottom: 4px;
            .taskHeading {
              font-size: 14px;
              padding-left: 15px;
              font-weight: 600;
            }
          }
        }
      }
    }
    .teamCard {
      background-color: #fffbf9;
      border-radius: 8px;
      padding: 6px 10px;
      height: auto;
      min-height: 80px;
      width: 100%;
      border: 1px solid #ffe5d5;
      display: flex;
      gap: 10px;
      row-gap: 14px;
      margin-bottom: 10px;
      align-items: center;
      a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        display: flex;
        gap: 10px;
        row-gap: 14px;
        align-items: center;
      }
      .teamDetails {
        width: 63%;
      }
    }
  }

  :global(.insideCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 18px;
    margin-bottom: 14px;
  }
  :global(.aboutCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 2px 30px;
    margin-bottom: 14px;
  }
  :global(.emptyContainerBoxes) {
    p {
      margin: 0;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

@media (max-width: 768px){
  .fundAction {
    width: 100%;
    margin-bottom: 22px !important;
    :global(.input-field-component){
      width: 100%;
    }
    input{
      width: 100% !important;
    }
  }
}