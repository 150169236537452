@import '../../../../../assets/styles/index.scss';

@mixin manageKeyMembersGridStyling {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;

  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.search-select-evaluators {
  @include flexCol;
  gap: 16px;
  height: 100%;
  padding: 0 10px 0 0;
  overflow-y: auto;
  height: 285px;
  @include customScroll;
  background: $white-100;

  &__search-users {
    @include flexCol;
    gap: 16px;
    position: relative;

    &__container {
      @include flexCol;
      gap: 12px;
      position: absolute;
      top: 77px;
      width: 100%;
      border: 1px solid $neutral-400;
      background: $background-100;
      border-radius: 8px;
      margin: 1px 0;
      padding: 12px;
      z-index: 1;

      &__result {
        max-height: 168px;
        overflow-y: auto;
        @include customScroll;
        @include manageKeyMembersGridStyling;
      }
    }
  }

  &__selected-users {
    @include manageKeyMembersGridStyling;
  }

  &__buttons {
    @include flexRow;
    justify-content: center;
    gap: 8px;
    margin-top: auto;

    button {
      width: 150px;
    }
  }
}
