@import '../../assets/styles/index.scss';

.view-program {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;

  &__body {
    @include flexCol;
    gap: 24px;
    padding: 16px;
    position: relative;
  }
  .sectionHeading {
    font-weight: 600;
    font-size: 25px;
  }
}
.sections {
  .section {
    margin-top: 22px;
    .headBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
      .sectionHeading {
        font-weight: 600;
        font-size: 22px;
        margin: 0;
      }
      span {
        margin: 0;
        color: #535353;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 768px) {
  .view-program {
    &__body {
      margin-top: 160px;
    }
  }
}
@media screen and (min-width: $desktop) {
  .view-program {
    &__body {
      padding: 32px;
      width :  calc(100vw - (300px + (max(85px, calc((100vw - 1287px) / 2) - 39px) * 2) - 32px));
    }
  }
}
