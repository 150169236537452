@import '../../../assets/styles/index.scss';

.modal-component-outer {
  @include modalPositioning;
  .modal-component {
    @include flexCol;
    gap: 12px;
    padding: 16px;
    border-radius: 16px;
    box-shadow: $shadow-200;
    width: 95%;
    background: $white-100;
    max-height: 90%;
    overflow: hidden;

    &__header {
      width: 100%;
      @include flexRow;
      justify-content: space-between;
      align-items: center;
      color: $neutral-1100;

      &__close {
        cursor: pointer;
        display: flex;
        gap: 10px;
        svg {
          height: 15px;
          width: 15px;
          &:hover {
            path {
              stroke: $primary-600;
            }
          }
        }
      }
    }

    &__content {
      overflow-y: auto;
      @include customScroll;
    }
  }

  @media screen and (min-width: $tablet) {
    .modal-component {
      padding: 24px;
      width: 750px;
    }
  }
}
