.topCompanies {
  :global(.owl-carousel) {
    :global(.owl-item) {
      :global(.item) {
        margin-right: 10px;
      }
    }
  }
  .globalUnknonwn {
    text-align: center;
  }
}
