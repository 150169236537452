.createProgramForm {
  width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #ffe5d5;
  border-radius: 10px;
  padding: 40px 60px;
  .programHeading {
    font-weight: 600;
    font-size: 19px;
    text-align: center;
    margin: 0;
    width: 100%;
    margin-bottom: 18px;
    letter-spacing: 0%;
  }
  :global(.input-component){
    &:hover{
        border: 2px solid transparent;
    }
  }
}
