.serviceSection {
  .globalUnknonwn {
    text-align: center;
  }
  .unknown {
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
    p {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
  }
  .listBox {
    // padding: 0px 12px;
    .serviceList {
      margin: 0;
      padding: 0;
      li {
        padding: 13px 20px;
        list-style: none;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        &.active {
          border-radius: 8px;
          background: #ffe5d5;
          color: #fa4616;
        }
      }
    }
  }
  .image {
    height: 130px;
    width: 100%;
    margin-bottom: 14px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  :global(.insideCard) {
    background: #fff;
    border-radius: 16px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 20px 12px;
  }
}
