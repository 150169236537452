@import '../../../../assets/styles/index.scss';

.idea-people {
  @include flexRow;
  gap: 12px;
  flex-wrap: wrap;

  & > hr {
    color: $primary-300;
    width: 1px;
    height: inherit;
  }

  &__more-people {
    height: initial;
    @include flexCenterContent;
    position: relative;

    &__count {
      @include flexCenterContent;
      height: 36px;
      width: 36px;
      border-radius: 100%;
      background: $primary-200;
      color: $primary-600;
      cursor: pointer;
    }

    &__dropdown {
      position: absolute;
      width: fit-content;
      top: calc(100% + 10px);
      right: 0;
      padding: 8px;
      border-radius: 12px;
      z-index: 1;

      .person-card {
        border: none;
        padding: 8px;
        &__profile-pic {
          height: 32px;
          width: 32px;
        }
        span {
          text-wrap: nowrap;
        }
      }
    }
  }
}
