@import '../../../assets/styles/index.scss';

.header-search {
  position: relative;

  &__results {
    color: $neutral-1100;
    width: 100%;
    position: absolute;
    top: 49px;
    z-index: 9999;
    border: 1px solid $neutral-400;
    @include flexCol;
    gap: 12px;
    box-shadow: $drop-shadow-100;
    padding: 12px 0;

    &__recent {
      @include flexCol;
      gap: 12px;

      p {
        padding: 0 24px;
      }

      &__item {
        @include flexRow;
        justify-content: space-between;
        align-items: center;
        height: 41px;
        cursor: pointer;
        padding: 0 24px;

        &:hover {
          background: $primary-100;
        }

        &__left {
          @include flexRow;
          gap: 8px;
        }

        button {
          width: 67px;
        }
      }
    }

    &__recommended {
      @include flexCol;
      gap: 8px;
      &__header {
        @include flexRow;
        justify-content: space-between;

        &__see-all {
          cursor: pointer;
        }
      }
    }
  }
}
