@import '../../../../assets/styles/index.scss';

.discussion-messages {
  @include flexCol;
  padding: 12px;
  gap: 12px;
  height: inherit;

  &--is-hidden {
    visibility: hidden;
  }
}

@media screen and (min-width: $desktop) {
  .chat-messages {
    padding: 12px;
  }
}
