@import '../../../../assets/styles/index.scss';

.create-program-card {
  @include flexCol;
  @include flexCenterContent;
  cursor: pointer;
  gap: 12px;
  color: $primary-600;
  min-height: 146px;
  border: 1px solid $primary-600;
}
