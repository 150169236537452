@import '../../../../assets/styles/index.scss';

.chat-message {
  @include flexCol;
  gap: 8px;
  max-width: 90%;
  margin-right: auto;

  &--is-sent {
    margin: 0 0 0 auto;
  }

  &__header {
    // @include flexRow;
    // gap: 12px;
    // justify-content: space-between;
    // align-items: center;
    padding: 12px;
    // border-radius: 16px 16px 0px 16px;
    border: 1px solid #ffe5d5;

    &__left {
      @include flexCol;
      gap: 12px;
      margin-bottom: 5px;
      cursor: pointer;

      &__tooltip {
        background-color: #fff;
        color: #000;
        border: 1px solid #ffe5d5;
        padding: 6px 12px; // Slightly increased padding for better spacing
        position: absolute;
        // transform: translateY(-15px); // Moves the tooltip slightly higher
        border-radius: 6px;
        max-width: 900px; // Increased width
        z-index: 9999;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      
        &__item-list {
          display: flex;
          margin: 0;
          padding: 0;
          list-style: none;
          gap: 16px;
      
          li {
            color: #000;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            transition: all .2s;
            &:hover{
              color:#fa4616;
            }
          }
        }
      }
      

      &__message {
        @include wrapText;
        font-size: 16px;
      }

      &__files {
        @include flexCol;
        gap: 16px;
      }
    }

    p {
      display: flex;
      font-size: 11px;
      color: #00000059;
      font-weight: 500;
    }

    .three-dots-dropdown-component {
      align-self: flex-start;
    }

    &--is-received {
      border-radius: 0px 16px 16px 16px;
      p {
        justify-content: flex-start;
      }
    }

    &--is-sent {
      border-radius: 16px 16px 0px 16px;
      background: $primary-200;

      p {
        justify-content: flex-end;
      }
    }

    .dropdown-component {
      min-width: 92px;
    }
  }

  &__actions {
    @include flexRow;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    &__left {
      @include flexRow;
      align-items: center;
      gap: 8px;
    }
  }
}

.timeTextRight {
}

@media screen and (min-width: $desktop) {
  .chat-message {
    // padding: 24px;
    max-width: 600px;
  }
}
