@import '../../../assets/styles/index.scss';

.breadcrumbs-component {
  @include flexRow;
  align-items: center;
  gap: 8px;
  color: $neutral-1100;

  &__label {
    cursor: pointer;
    &:hover {
      @include hoverTransition;
      color: $primary-600;
      @include semiBoldTypography;
    }
  }

  &__icon {
    @include flexCenterContent;
    width: 9px;
    &:last-child {
      display: none;
    }
  }
}
