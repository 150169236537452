@import '../../../../assets/styles/index.scss';

.discussion-list {
  height: 100%;
  @include flexCol;
  gap: 16px;
  padding: 16px;
  height: inherit;
  overflow: hidden;

  &__heading {
    @include flexCol;
    gap: 16px;
    padding: 16px;
  }

  &__main {
    color: $neutral-1000;
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;

    &--is-active {
      @include bodyTwo;
      @include semiBoldTypography;
      color: $primary-600;
      background: $primary-100;
    }
  }

  &__groups {
    @include flexCol;
    gap: 8px;
    overflow: hidden;

    &__header {
      @include flexRow;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .action-icon-component {
        height: 35px;
        width: 35px;
      }
    }

    &__body {
      @include flexCol;
      gap: 8px;
      overflow: hidden;

      &__create-group {
        padding: 10px 20px;
      }

      &__list {
        @include flexCol;
        overflow-y: auto;
        @include customScroll;
        gap: 8px;
      }
    }
  }
}

// @media screen and (min-width: $desktop) {
//   .discussion-list {
//   }
// }
