.mentor {
  :global(.emptyContainerBoxes) {
    p {
      margin: 0;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .titleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .title {
      font-size: 16px;
      margin: 0;
    }
  }
  .chatHeading {
    font-size: 14px;
    margin: 0;
    margin-bottom: 10px;
  }
  .teamContainer {
    min-height: 250px;
    padding: 8px;
    .btnContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      .assignButton {
        background: #fa4616;
        color: #ffffff;
        border: 2px solid #ffffff;
        font-family: 'Manrope', sans-serif;
        font-size: 14px;
        line-height: 19.12px;
        letter-spacing: 0em;
        margin: 0;
        font-weight: 600;
        margin: 0;
        gap: 8px;
        height: 39px;
        padding: 0 20px;
        border-radius: 100px;
      }
    }
    .inputFields {
      margin-bottom: 10px;
      position: relative;
      .mentorList {
        position: absolute;
        bottom: 0;
        transform: translateY(101%);
        border-radius: 8px;
        width: 100%;
        background: #fff;
        border: 1px solid #ffe5d5;
        height: 180px;
        padding: 12px;
        overflow-y: auto;

        :global(.emptyList) {
          font-size: 13px;
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 8px;
          background: #ffe5d5;
          border-radius: 100px;
        }
        &::-webkit-scrollbar-button {
          display: none;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #fa4616;
          border-radius: 100px;
        }
      }
    }

    .teamCard {
      background-color: #fffbf9;
      border-radius: 8px;
      padding: 6px 8px;
      height: auto;
      min-height: 80px;
      width: 100%;
      border: 1px solid #ffe5d5;
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 14px;
      cursor: pointer;
      position: relative;
      .teamCount {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(-25%, -20%);
        background: #fff;
        border: 1px solid #ffe5d5;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        span {
          font-size: 13px;
          font-weight: 500;
          margin: 0;
          color: #fa4616;
          margin-bottom: 2px;
        }
      }
      &.active {
        border: 1px solid #fa4616;
      }
    }
  }
  :global(.insideCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 12px;
    margin-bottom: 10px;
  }
}
