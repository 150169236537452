@import '../../../assets/styles/index.scss';

.search-filters {
  @include flexRow;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  color: $neutral-1000;

  &__filter {
    position: relative;

    &__options {
      position: absolute;
      z-index: 9999;
      top: calc(100% + 5px);
      width: fit-content;
      left: 0;
      display: none;
      background: #fff;
      border-radius: 8px;
      border: 1px solid $primary-200;
      list-style: none;
      color: $neutral-1100;
      max-height: 344px;
      overflow-y: auto;
      @include customScroll;

      &--is-open {
        @include flexCol;
      }

      &--is-location {
        .input-component {
          min-width: 234px;
        }
      }

      &__option {
        @include customCheckboxInput;
        @include flexRow;
        align-items: center;
        gap: 8px;
        @include OptionStyling;
        cursor: pointer;
        padding: 12px 24px;
      }
    }
  }
  button {
    width: fit-content;
  }
}
