.companyServices {
  .serviceDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 12px;
    .serviceName {
      font-family: Manrope;
      font-size: 18px;
      font-weight: 600;
      line-height: 24.59px;
      text-align: left;
    }
    .dotDropdown {
      // position: absolute;
      // top: 0;
      // right: 0;
      // transform: translate(-41px, 10px);
      // z-index: 99;
      .dots {
        height: 16px;
        width: 16px;
      }

      :global(.accordion) {
        :global(.accordion-header) {
          padding: 0;

          :global(.accordion-button) {
            padding: 8px 17px 5px 17px;
            background: #fff !important;
            .dotDropInside {
              span {
                margin-right: 5px;
              }
            }
            &:global(.collapsed) {
              &::after {
                content: '+';
                transform: rotate(0);
              }
            }

            &::after {
              display: flex;
              align-items: center;
              font-size: 20px;
              content: '-';
              background-image: none !important;
              transform: rotate(0);
              transition: content 3s;
            }
          }
        }
        :global(.accordion-body) {
          background: #fff !important;
          color: black !important;
          background: #fff !important;
          &:focus,
          &:active {
            outline: none;
            background: none;
          }
          &:focus {
            outline: none;
            background-color: transparent;
          }
          padding: 0;
          .itemList {
            list-style: none;
            padding: 5px 28px 0px 28px;
            margin: 0;
            overflow: hidden;
            max-width: 100%;
            &:focus,
            &:active {
              outline: none;
              background: none;
            }
            background: #fff !important;
            color: black !important;
            .item {
              font-size: 14px;
              display: block; /* Ensure the li elements are block-level elements */
              max-width: 100%; /* Prevent overflow */
              word-wrap: break-word; /* Ensure long words break to the next line */
              word-break: break-word; /* Break words if necessary */
              line-height: 1.5;
              margin-bottom: 4px;
              &:focus {
                outline: none;
                background-color: transparent;
              }

              &:last-child {
                margin-bottom: 0;
              }

              // &:hover{
              //   background-color: #fa471629;
              // }
            }
          }
        }
      }

      :global(.dropdown) {
        // position: absolute;
        // top: 100%;

        :global(.dropdown-toggle) {
          .dots {
            // position: absolute;
            // top: 0px !important;
            cursor: pointer;
            background: #fff !important;
          }
        }
        :global(.dropdown-menu) {
          z-index: 2 !important;
          padding: 5px 0px;
          // right: 5px !important;
          min-width: 190px !important;
          // position: relative;
          background: #fff !important;
          :global(.dropdown-item){
            &:active{
              background: none;
              color: #000;
            }
          }
        }
        :global(.btn) {
          background-color: transparent;
          border: none;
          background: none;

          &::after {
            display: none;
          }
        }

        .dotDropInside {
          display: flex;
          gap: 10px;
          align-items: center;
          margin: 0;
          font-size: 14px;
        }
      }

      .correctImage {
        height: 20px;
        width: 20px;
        opacity: 0.6;
      }
    }
  }
  .serviceRow {
    margin-top: 14px;
  }
  .addServices {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .serviceRow [data-rbd-placeholder-context-id] {
    display: inline-block;
    width: 100%; /* Adjust to match the draggable item's size */
    height: auto;
    visibility: hidden; /* Keeps layout intact without showing the placeholder */
  }
}
