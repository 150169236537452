@import '../../assets/styles/index.scss';

.view-idea {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;

  &__body {
    @include flexCol;
    gap: 24px;
    padding: 16px;
    margin-top: 89px;
  }
}

@media (max-width:768px){
  .view-idea{
    &__body{
      margin-top: 160px;
    }
  }
}
@media screen and (min-width: $desktop) {
  .view-idea {
    &__body {
      padding: 32px;
      margin-top: 89px;
      width: 95%;
    }
  }
}
