@import '../../../../../assets/styles/index.scss';

.apply-to-program-external {
  .ape-navigation-modal {
    .modal-component__header {
      display: none;
    }

    &__modal {
      height: 500px;
      width: 500px;
      @include flexCenterContent;
    }

    &__content {
      @include flexCol;
      @include flexCenterContent;
      gap: 24px;
      padding: 24px;

      img {
        width: 200px;
      }

      button {
        width: 350px;
      }
    }

    &__footer {
      @include flexRow;
      align-items: center;
      gap: 8px;
      color: $neutral-1000;

      &__sign-up {
        cursor: pointer;
        color: $primary-700;
      }
    }
  }
}
