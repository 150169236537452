@import '../../../../../../assets/styles/index.scss';

.pd-discussion-user-info {
  @include flexRow;
  align-items: center;
  gap: 24px;
  padding: 8px 0;

  &__profile-pic {
    height: 36px;
    width: 36px;
  }

  &__details {
    @include flexCol;
    gap: 4px;

    &__row {
      @include flexRow;
      align-items: center;
      gap: 8px;

      &__name {
        cursor: pointer;
        text-wrap: nowrap;
      }

      &__role {
        cursor: pointer;
        text-wrap: nowrap;
      }

      .vertical-separator-component {
        background: $neutral-400;
        height: 19px;
      }
    }

    &__email {
      cursor: pointer;
      text-wrap: nowrap;
    }
  }
}
