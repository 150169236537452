@import '../../../assets/styles/index.scss';

.company {
  &-services {
    @include flexCol;
    gap: 16px;
    color: $neutral-1100;
    &__services {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;

      &__add-service {
        @include flexCol;
        @include flexCenterContent;
        color: $primary-600;
        cursor: pointer;
        gap: 12px;
        border-radius: 16px;
        border: 1px solid $primary-600;
        padding: 16px;
        svg {
          height: 26.67px;
          width: 26.67px;
          padding: 3px;
          border-radius: 50%;
          border: 1px solid $primary-600;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .company {
    &-services {
      &__services {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
