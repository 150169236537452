@import '../../../assets/styles/index.scss';

.activities {
  @include flexCol;
  gap: 16px;
  overflow-y: auto;
  @include mainPageScroll;

  &__body {
    @include flexCol;
    gap: 24px;
    &__items {
      width: 100%;
      @include flexCol;
      gap: 24px;
    }
  }

  .card-component {
    padding: 12px;
  }
}
