@import '../../../../../assets/styles/index.scss';

@mixin editProfileGridStyle {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.assignTeamModal {
  :global(.modal-component__content) {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .assignTeam {
    .teamCard {
      background-color: #fffbf9;
      border-radius: 8px;
      padding: 6px 8px;
      height: auto;
      min-height: 80px;
      width: 100%;
      border: 1px solid #ffe5d5;
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 14px;
      position: relative;
      .TrashIcon {
        position: absolute;
        top: -4px;
        right: 18px;
        .dots {
          height: 17px;
          width: 17px;
        }
      }
      .unassignedTeam {
        position: absolute;
        top: 0;
        right: 0;
        background: #fff;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 1px solid #ffe5d5;
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          border: none;
          background: transparent;
          margin-bottom: 3px;
        }
      }
    }
  }
}
