.selectBox {
  :global(.css-13cymwt-control) {
    max-height: 41px !important;
    height: 41px;
    color: #383838;
    border: 1px solid #898989;
    border-radius: 8px;
    width: 100%;
    padding: 0;
    outline: none;
    transition: all 0.3s ease-out;
    --webkit-transition: all 0.3s ease-out;
    margin-top: 1px;
  }
}
