@import '../../../assets/styles/index.scss';

.profile-picture-component {
  @include flexCenterContent;
  height: 41px;
  width: 41px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  background: $primary-100;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &--Company {
    img {
      object-fit: contain;
    }
  }
  &__error {
    color: $primary-600;
  }
}
