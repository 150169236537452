@import '../../../../../../assets/styles/index.scss';

.program-member-card {
  @include flexCol;
  gap: 8px;
  &__header {
    @include flexRow;
    justify-content: space-between;

    &__user-info {
      @include flexRow;
      gap: 16px;

      &__user {
        &__name {
          color: $neutral-1000;
        }
        &__role {
          color: $neutral-800;
        }
      }

      &__profile-pic {
        height: 48px;
        width: 48px;
      }
    }

    &__dropdown {
      position: relative;
      cursor: pointer;
      height: 32px;
      width: 32px;
      @include flexCenterContent;
      .dropdown-component {
        width: fit-content;
        white-space: nowrap;
      }
    }
  }

  &__description {
    color: $neutral-1100;
    width: 100%;
    word-break: break-word;
  }
}
