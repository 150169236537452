@import '../../../../assets/styles/index.scss';

@mixin editProfileGridStyle {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.update-work-experience-modal {
  .modal-component__content {
    overflow: hidden;
  }
}

.update-work-experience {
  &__form {
    @include flexCol;
    gap: 12px;

    &__fields {
      max-height: 364px;
      overflow-y: auto;
      overflow-x: hidden;
      @include flexCol;
      gap: 16px;
      @include customScroll;
      padding-right: 8px;
      &__row {
        @include editProfileGridStyle;
        gap: 16px;

        &__start-month-year {
          position: relative;

          .select-month-year-outer {
            position: absolute;
            bottom: 77px;
          }

          &--has-error {
            .select-month-year-outer {
              bottom: 102px;
            }
          }
        }
      }
      &__grid {
        @include editProfileGridStyle;
        &__field {
          @include flexCol;
          gap: 16px;
          color: $neutral-1100;
          input[type='date']::-webkit-calendar-picker-indicator {
            display: none;
          }
        }
      }

      &__end {
        @include flexCol;
        gap: 8px;
        position: relative;

        .select-month-year-outer {
          position: absolute;
          bottom: 77px;
        }

        &--has-error {
          .select-month-year-outer {
            bottom: 102px;
          }
        }

        &__currently-working {
          @include flexRow;
          color: $neutral-1100;
          gap: 8px;
          align-items: center;
        }
      }
    }

    &__buttons {
      @include flexRow;
      justify-content: center;
      gap: 8px;
      button {
        width: 150px;
      }
    }
  }
}
