.topCompanies {
  :global(.owl-carousel) {
    :global(.owl-item) {
      :global(.item) {
        margin-right: 10px;
      }
    }
  }
  .outer_box {
    border: 1px solid #ffe5d5;
    border-radius: 16px;

    .marketplace_heading {
      padding: 12px;
      font-size: 16px;
      font-weight: 600;
    }
    .marketplace_content {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .globalUnknonwn {
    text-align: center;
  }
}
