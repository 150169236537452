@import '../../../../assets/styles/index.scss';

.multi-select-field-component {
  @include flexCol;
  gap: 8px;
  &__custom-select {
    position: relative;

    &__select {
      @include flexRow;
      justify-content: space-between;
      align-items: center;
      height: 41px;
      padding: 0 16px 0 12px;
      border-radius: 8px;
      border: 1px solid $neutral-700;
      color: $neutral-700;
      cursor: pointer;
      overflow: hidden; /* Ensure no overflow */
      white-space: nowrap; /* Ensure text remains on a single line */
      width: 100%; /* Make it responsive */
      min-width: 0;

      .one-line-ellipsis {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%; /* Ensure it does not exceed its container */
        flex: 1; /* Allows it to take up available space */
        min-width: 0; /* Important for flex containers to prevent overflow */
        width: 10px;
      }

      @include hoverTransition;
      &:hover {
        border: 2px solid $primary-300;
      }
      &:focus {
        outline: 2px solid $primary-300;
        border: none;
      }
      &--selected {
        color: $neutral-1100;
      }
      span {
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    &__options {
      display: none;
      position: absolute;
      margin: 0;
      width: 100%;
      color: $neutral-1100;
      width: 100%;
      border: 1px solid $neutral-700;
      border-width: 2px;
      background: $background-100;
      border-radius: 8px;
      z-index: 9999;
      max-height: 215px;
      overflow-y: auto;
      padding-right: 8px;
      @include customScroll;
      &--is-open {
        display: block;
      }

      &__option {
        @include flexRow;
        gap: 8px;
        justify-content: flex-start;
        align-items: center;
        height: 41px;
        line-height: 41px;
        list-style: none;
        padding: 0 16px;
        @include bodyTwo;
        @include mediumTypography;
        &:hover,
        &:focus-within,
        &--selected {
          color: $primary-600;
          background: $primary-200;
        }
        &:focus {
          outline: none;
        }
        input {
          cursor: pointer;
        }
      }
    }
  }

  &__selected {
    @include flexCol;
    gap: 16px;
    &__tags {
      @include flexRow;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}
