@import '../../assets/styles/index.scss';

// Apply background color and padding to the form container
.signupFormfeedback {
    background-color: $white-100;
    padding: 40px 60px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba($neutral-900, 0.1);
    margin: 40px 0px;
    border: 1px solid $primary-600;
}
.errorFeedback {
    color: rgb(247, 71, 71);
    font-size: 0.875rem;
    margin-top: 0.25rem;
    }

.signupFormfeedback h2 {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: $primary-600;
}

// Form group styling
.formGroupfeedback {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    background-color: $white-100;
    gap: 8px;

    label {

        font-weight: 600;
        margin-bottom: 2px;
        font-family: "Manrope", sans-serif;
        font-size: 12.8px;
        line-height: 17.48px;
        letter-spacing: 0em;
        margin: 0;
        font-family: "Manrope", sans-serif;
        font-size: 12.8px;
        line-height: 17.48px;
        letter-spacing: 0em;
        margin: 0;
        color: #121212;

    }

    input::placeholder {
        color: #9b9898;
        font-weight: 400; 
    }


    .formControl {

        &:focus,
        &:hover {
            outline: 2px solid #fec6aa;
            border-color: transparent;
        }
    }
}

// Input group with icon positioning
.inputGroup {
    position: relative; // Position icons absolutely
    // margin: 10px 0;
}

// Icon positioning within input fields
.icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: $primary-600;
}

// Input, select, and textarea styling
.formGroupfeedback input,
select,
textarea {
    padding: 0.5rem 0.5rem 0.5rem 11px;
    font-size: 14px;
    border: 1px solid $primary-600;
    border-radius: 8px;
    color: $neutral-900;
    background: $neutral-100;
    width: 100%;
    box-sizing: border-box;
    height: 41px;

}

// Specific textarea height
.formGroupfeedback textarea {
    height: 100px;
    resize: vertical;
}

// Toggle icon positioning
.formGroupfeedback .toggleIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: $primary-600;
}

// Error message styling
.errorMessage {
    color: $error-700;
    font-size: 0.875rem;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
}

// Forgot password line styling
.forgetline {
    cursor: pointer;
    font-size: 18px;
    text-align: right;
    color: $primary-600;
}

// Account options styling with flexbox
.accounts {
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-top: 5px;
    color: $neutral-600;
    justify-content: center;
    gap: 50px;
}

.back-buttonFeedback {
    background: none;
    border: none;
    color: #101111;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: inline;
}

// Submit button styling
.submitButton2 {
    // background-color: $primary-600;
    // color: $white-100;
    // padding: 10px 20px;
    // border: none;
    // border-radius: 4px;
    // cursor: pointer;
    // font-size: 18px;
    // transition: background-color 0.3s ease;
    // gap: 10px;
    font-size: 14px;
    line-height: 19.12px;
    letter-spacing: 0em;
    margin: 0;
    font-weight: 600;
    margin: 0;
    gap: 8px;
    height: 39px;
    padding: 0 20px;
    background: #fa4616;
    color: #ffffff;
    border: 2px solid #ffffff;

    &:hover {
        background-color: $primary-700;
    }
}

.btnarea {
    a {
        text-decoration: none;
        color: #000;

        &:hover {
            color: $primary-700;
        }
    }
}

@media (max-width: 767px) {
    .signupForm {

        padding: 40px 30px;

    }
}