.sidebar {
  :global(.insideCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 20px 22px;
    height: 290px;
  }

  .sidebaritemList {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 12px;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        color: #fa4716a4;
      }

      &.active {
        color: #fa4616;
      }
    }
  }
}

@media (max-width: 768px) {
  .sidebar {
    :global(.insideCard) {
      height: auto;
      margin-bottom: 10px;
    }

    .sidebaritemList {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: flex-start;

      li {
        line-height: normal;
        margin-right: 9px;
        margin-bottom: 0px;
      }
    }
  }
}