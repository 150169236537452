@import '../../../../assets/styles/index.scss';

.search-select-component {
  @include flexCol;
  gap: 8px;
  &__custom-select {
    position: relative;

    &__select {
      @include flexRow;
      justify-content: space-between;
      align-items: center;
      height: 41px;
      padding: 0 16px 0 12px;
      border-radius: 8px;
      border: 1px solid $neutral-700;
      color: $neutral-700;
      cursor: pointer;
      @include hoverTransition;
      &:hover {
        border: 2px solid $primary-300;
      }
      &:focus {
        outline: 2px solid $primary-300;
        border: none;
      }
      &--selected {
        color: $neutral-1100;
      }
    }

    &__options {
      display: none;
      position: absolute;
      margin: 0;
      width: 100%;
      color: $neutral-1100;
      border: 1px solid $neutral-700;
      border-width: 2px;
      background: $background-100;
      border-radius: 8px;
      z-index: 9999;
      max-height: 215px;
      overflow-y: auto;
      padding-right: 8px;
      @include customScroll;
      &--is-open {
        display: block;
      }

      &__option {
        @include flexRow;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        height: 41px;
        line-height: 41px;
        list-style: none;
        padding: 0 16px;
        border-radius: 8px;
        @include bodyTwo;
        @include mediumTypography;
        &:hover,
        &:focus-within,
        &--selected {
          color: $primary-600;
          background: $primary-200;
        }
        &:focus {
          outline: none;
        }
        &:hover {
          background: $primary-100;
        }
      }
    }
  }
}
