@import 'src/assets/styles/index.scss';

@mixin labelStyling {
  &__label {
    color: $neutral-1100;
    span {
      color: $neutral-1000;
    }
  }
}

@mixin buttonFieldStyle {
  @include flexCol;
  gap: 8px;

  @include labelStyling;

  &__options {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
  }

  @media screen and (min-width: $tablet) {
    &__options {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
    }
  }
}
.select-field-component__custom-select__options{
  padding: 0;
}
.tell-me-more {
  @include flexCol;
  width: 100%;
  gap: 16px;

  &__heading {
    color: $neutral-1000;
    &--highlight {
      color: $primary-600;
    }
  }

  &__background {
    @include buttonFieldStyle;
  }

  &__entrepreneur {
    @include flexCol;
    gap: 8px;
    @include labelStyling;
    &__options {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }

  &__startup-stage {
    &__footer {
      color: $neutral-1100;
    }
  }

  &__mentor-investor-role {
    @include flexCol;
    gap: 8px;
    @include labelStyling;
    &__options {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }

  &__mentor-investor-interests {
    @include flexCol;
    gap: 8px;
    @include labelStyling;
    &__options {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }

  &__looking-for {
    @include buttonFieldStyle;
  }

  &__buttons {
    @include flexRow;
    justify-content: space-between;
    gap: 16px;
  }
}
