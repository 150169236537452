@import '../../assets/styles/index.scss';

.access-denied {
  background: $background-100;
  height: 100dvh;
  @include flexCenterContent;

  &__content {
    @include flexCol;
    align-items: center;
    gap: 24px;
    text-align: center;
    color: $neutral-1100;
    padding: 16px;

    button {
      width: 150px;
    }
  }
}
