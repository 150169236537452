@mixin actionButton {
  // For buttons such as edit, delete etc.
  @include flexCenterContent;
  @include hoverTransition;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  @include hoverOrange;
}
