@import '../../../../../assets/styles/index.scss';

.share-demo {
  &__form {
    @include flexCol;
    gap: 16px;

    &__row {
      @include flexRow;
      gap: 16px;

      &__field {
        flex: 1;
      }
    }

    &__buttons {
      @include flexRow;
      align-self: flex-end;
      gap: 16px;

      button {
        width: 150px;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .share-demo__modal {
    width: 600px !important;
  }
}
