@import '../../../assets/styles/index.scss';

@mixin featureBlock {
  background: $primary-100;
  border-radius: 16px;
  padding: 47.5px 24px;
  gap: 16px;
  text-align: center;
  @include flexCol;
  @include flexCenterContent;
  &__icon {
    @include flexCenterContent;
    border-radius: 8px;
    background: $primary-500;
    height: 52px;
    width: 52px;
  }
  &__title {
    color: $neutral-1100;
  }
  &__description {
    color: $neutral-1000;
  }
}

.landing-page {
  .feature-block {
    @include featureBlock;
  }
}
