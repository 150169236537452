@import '../../../../assets/styles/index.scss';

@mixin editProfileGridStyle {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.submitted-report-modal {
  .modal-component {
    width: 500px;
    max-height: 380px;
  }
  .modal-component__content {
    overflow: hidden;
  }
}

.submitted-report-body {
  .submitted-report-submit-box{
    margin: 10px 0 20px 0;
    .inputFields{
      display: flex;
      flex-direction: column;
      gap: 0px;
    }
  }
  .submitted-report-list {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background: $primary-100;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary-600;
      border-radius: 100px;
    }
    .submitted-report-list-item {
      width: 100%;
      margin-bottom: 14px;
      .submitted-report-item {
        background: #fdf3ed;
        padding: 10px;
        border-radius: 8px;
        min-width: 170px;
      }
      .submitted-report-item-timestamp {
        height: 100%;
        display: flex;
        align-items: center;
        height: 60px;
        .date-time {
          font-weight: 500;
          font-size: 15px;
        }
      }
      .submitted-report-item-removebox {
        display: flex;
        align-items: center;
        height: 60px;
        .remove-button {
          background-color: transparent;
          border: none;
          color: red;
          background-color: #fdf3ed;
          border-radius: 50%;
          height: 30px;
          min-width: 30px;
          transform: translateX(-20px);
        }
      }
    }
  }
}
