@import '../../assets/styles/index.scss';

.create-program {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;
  &__body {
    padding: 16px;
    color: $neutral-1100;
    @include flexCol;
    gap: 16px;
    margin-top: 89px;

    &__stepper {
      align-self: center;
      width: 90%;
    }

    &__step {
      align-self: center;
    }
  }
}
@media (max-width: 768px) {
  .create-program{
    &__body{
      margin-top: 160px;
    }
  }
}
@media screen and (min-width: $desktop) {
  .create-program {
    &__body {
      padding: 32px;
      // width: 95%;
      width :  calc(100vw - (300px + (max(85px, calc((100vw - 1287px) / 2) - 39px) * 2) - 32px));
      gap: 24px;
      &__stepper {
        width: 65%;
      }

      &__step {
        width: 800px;
      }
    }
  }
}
