@import '../../../../../assets/styles/index.scss';

.application-container{
  max-width: 1350px;
  margin: 0 auto;
}
.view-program-application {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;

  &__body {
    @include flexCol;
    gap: 24px;
    padding: 16px;
    height: 100%;
  }
}

@media screen and (min-width: $desktop) {
  .view-program-application {
    &__body {
      padding: 32px;
    }
  }
}
