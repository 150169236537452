@import '../../../../../assets/styles/index.scss';

@mixin overrideLinkStyling {
  a {
    text-decoration: none;
    color: inherit;
  }
}

@mixin landingPagePadding {
  padding: 8px 16px;
  @media screen and (min-width: $tablet) {
    padding: 16px 32px;
  }
  @media screen and (min-width: $desktop) {
    padding: 24px 64px;
  }
}

@mixin landingPageHeading {
  color: $neutral-1000;
  &--highlight {
    color: $primary-600;
  }
}

@mixin limitSectionWidth {
  max-width: 1440px;
  margin: 0 auto;
}

// base styles
.landing-page {
  @include flexCol;
  gap: 16px;
  &__top-navigation {
    @include flexRow;
    @include flexJustifyContentSpaceBetween;
    padding: 8px 16px; // mobile specific
    &__logo {
      width: 140px; // mobile specific
      height: 31px;
    }
    &__buttons {
      @include flexRow;
      gap: 16px; // mobile specific
      @include overrideLinkStyling;
      &__button {
        text-wrap: nowrap;
      }
    }
  }
}

// responsive styles for tablet
@media screen and (min-width: $tablet) {
  .landing-page {
    &__top-navigation {
      padding: 16px 32px; // tablet  specific
      &__logo {
        width: 160.52px;
      }
    }
  }
}

// responsive styles for desktop
@media screen and (min-width: $desktop) {
  .landing-page {
    &__top-navigation {
      padding: 24px 64px; // mobile specific
      max-width: 1440px;
      min-width: 100%;
      margin: 0 auto;
      &__buttons {
        &__button {
          width: 140px;
        }
      }
    }
  }
}

@media screen and (min-width: $desktopL) {
  // .landing-page {
  //   &__top-navigation {
  //     min-width: 1425px;
  //     max-width: 1440px;
  //   }
  // }
}
