@import '../../assets/styles/index.scss';

.main-search {
  @include mainPageScroll;
  &__body {
    @include flexCol;
    gap: 24px;
    flex: 1;
    padding: 16px;
    margin-top: 89px;

    &__header {
      @include flexCol;
      gap: 12px;
    }

    &__pagination {
      @include flexRow;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 8px;
      &__pages {
        @include flexRow;
        justify-content: center;
        gap: 9px;
        li {
          @include flexCenterContent;
          cursor: pointer;
          list-style: none;
          height: 24px;
          width: 24px;
          border-radius: 50%;

          a {
            height: 100%;
            width: 100%;
            text-align: center;
            @include bodyTwo;
            line-height: 24px;
          }
          &.selected {
            background-color: $primary-600;
            color: $neutral-200;
          }
          &.previous,
          &.next {
            display: none;
          }
        }
      }
      &__previous,
      &__next {
        @include flexRow;
        @include flexCenterContent;
        gap: 4px;
        color: $neutral-700;
        cursor: pointer;
        svg path {
          stroke: $neutral-700;
        }
      }
      &__previous {
        width: 108px;
        svg {
          transform: rotate(90deg);
        }
      }
      &__next {
        width: 83px;
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }
}

@media (max-width:768px) {
  .main-search{
    &__body{
      margin-top: 160px;
    }
  }
}
@media screen and (min-width: $desktop) {
  .main-search {
    &__body {
      padding: 32px;
      // width: 95%;
      width :  calc(100vw - (300px + (max(85px, calc((100vw - 1287px) / 2) - 39px) * 2) - 32px));
    }
  }
}
