.eventContainer {
  padding: 32px 64px;
  .eventBody {
    padding: 0px 64px;
    max-width: 1440px;
    // min-width: 100%;
    margin: 0 auto;
  }
}
.bannerBody {
  width: 85%;
  height: 280px;
  overflow: hidden;
//   margin: 10px 0;
  // margin: 0 auto;
  margin-top: 24px;
  :global(.card) {
    height: 100%;
    width: 100%;
    border-radius: 12px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
}
.eventFormBody{
    margin-top: 45px !important;
}


@media (max-width: 768px) {
  .eventContainer{
    padding: 16px 8px;
    .eventBody{
      padding: 8px 16px !important;
    }
  }
  .bannerBody {
    width: 100%;
    margin-top: 12px;
  }
}

@media (max-width: 1200px) {
  .eventContainer{
    .eventBody{
      padding: 16px 32px;
    }
  }
}

@media (max-width: 468px) {
  .eventContainer {
    padding: 6px;
  }
}