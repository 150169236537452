@import '../../../assets/styles/index.scss';

.tag-component {
  width: fit-content;
  border-radius: 100px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
  --webkit-transition: all 0.3s ease-out;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    color: $primary-600;
    background: $primary-100;
    border: 1px solid transparent;
    svg path {
      color: $primary-600;
      stroke: $primary-600;
    }
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  // Tag variants
  &--default {
    background: none;
    color: $neutral-700;
    border: none;
  }

  &--active {
    background: $primary-200;
    color: $primary-600;
    border: 1px solid transparent;
    font-weight: 600 !important;
    svg path {
      stroke: $primary-600;
    }
  }

  &--border {
    border: 1px solid $neutral-500;
    color: $neutral-600;
    background: none;
    svg path {
      stroke: $neutral-600;
    }
  }

  // Tag sizes
  &--large {
    @include bodyOne;
    @include mediumTypography;
    gap: 4px;
    height: 38px;
    padding: 0 16px;
  }

  &--medium {
    @include bodyTwo;
    @include mediumTypography;
    gap: 4px;
    height: 35px;
    padding: 0 16px;
  }

  &--small {
    @include captionTypography;
    @include mediumTypography;
    gap: 4px;
    height: 29px;
    padding: 0 16px;
  }

  // Icon styles
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    &--large {
      svg {
        height: 13.5px;
        width: 13.5px;
      }
    }
    &--medium {
      svg {
        height: 12px;
        width: 12px;
      }
    }
    &--small {
      svg {
        height: 12px;
        width: 12px;
      }
    }
  }
}
