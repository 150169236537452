@import '../../../../../assets/styles/index.scss';

.view-rating {
  @include flexCol;
  padding: 16px;
  gap: 12px;

  &__header {
    @include flexRow;
    gap: 12px;
    align-items: center;

    &__user {
      @include flexRow;
      gap: 8px;
      align-items: center;

      &__name {
        color: $neutral-1100;
      }

      &__dot {
        height: 6.41px;
        width: 6.41px;
        background: $primary-600;
        border-radius: 50%;
      }

      &__updated-at {
        color: $neutral-800;
      }
    }
  }

  &__parameters {
    @include flexRow;
    flex-wrap: wrap;
    gap: 12px;

    &__parameter {
      @include flexRow;
      gap: 12px;
    }
  }

  &__description {
    @include flexCol;
    gap: 12px;
  }
}

@media screen and (min-width: $desktop) {
  .view-rating {
    &__parameters {
      row-gap: 12px;
      column-gap: 92px;
    }
  }
}
