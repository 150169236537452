@import '../../../assets/styles/index.scss';

.stepper-component {
  @include flexRow;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;

  &__step {
    @include flexCol;
    @include flexCenterContent;
    gap: 4px;
    color: $neutral-500;

    &::after {
      content: '';
      width: 100%;
    }

    &__number {
      @include flexCenterContent;
      height: 44px;
      width: 44px;
      border-radius: 50%;
      border: 1px solid $neutral-500;
    }

    &--is-active {
      color: $primary-600;

      .stepper-component__step__number {
        background: $primary-100;
        border: 1px solid $primary-600;
      }
    }

    &--is-completed {
      color: $primary-600;
      .stepper-component__step__number {
        background: $primary-600;
        border: none;
      }
    }
  }

  &__separator {
    flex: 1;
    height: 1px;
    align-self: center;
    background: $neutral-500;
    border: none;

    &--is-active {
      background: $primary-600;
    }

    &--is-hidden {
      display: none;
    }
  }
}
