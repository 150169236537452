@import '../../../../../../assets/styles/index.scss';

.dynamic-field {
  @include flexCol;
  gap: 8px;

  @mixin checkboxRow {
    @include customCheckboxInput;
    @include flexRow;
    cursor: pointer;
    color: $neutral-900;
    align-items: center;
    gap: 8px;

    label {
      cursor: pointer;
    }
  }

  @mixin dropdownOptionsStyling {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;

    @media screen and (min-width: $desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__header {
    @include flexRow;
    justify-content: space-between;
    align-items: center;

    &__actions {
      @include flexRow;
      align-items: center;
      gap: 24px;

      &__required {
        @include checkboxRow;
      }
    }
  }

  &__field {
    @include flexCol;
    gap: 12px;

    &__character-limit-checkbox {
      @include checkboxRow;
    }

    &__character-limit {
      .input-component {
        width: 250px;
      }
    }

    &__dropdown-options {
      @include dropdownOptionsStyling;

      &__option {
        @include flexRow;
        align-items: center;
        gap: 8px;

        .input-field-component {
          flex: 1;
        }

        .hide-icon {
          visibility: hidden;
        }
      }
    }

    &__add-option {
      width: 132px;
    }
  }

  &__upload-file {
    @include flexCol;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid $neutral-500;
    padding: 12px;
    gap: 8px;
    cursor: pointer;
    color: $neutral-700;

    &__drag-drop {
      @include flexRow;
      gap: 8px;
    }

    &__browse {
      color: $primary-600;
    }
  }
}
