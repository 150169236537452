@import '../../../../assets/styles/index.scss';

.my-profile-applied-programs-list {
  @mixin appliedProgramsGrid {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;

    @media screen and (min-width: $tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include flexCol;
  gap: 16px;
  color: $neutral-1100;

  &__programs {
    @include appliedProgramsGrid;
  }
  &__not-applied-message-box{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__see-more-button {
    color: #fa4616;
    font-size: 13px;
    cursor: pointer;
    font-weight: 600;
    margin: 0;
    text-align: right;
  }
}
