@import '../../../../assets/styles/index.scss';

.select-card-component {
  // Default mode
  @include flexRow;
  @include flexCenterContent;
  border: 1px solid $neutral-900;
  border-radius: 8px;
  @include hoverTransition;
  color: $neutral-1100;
  cursor: pointer;
  background: none;
  height: 70px;
  width: 100%;

  &__icon {
    @include flexCenterContent;
    svg {
      height: 100%;
      path {
        stroke: $neutral-1100;
      }
    }
  }

  // Hover mode
  &:hover:not(&--selected) {
    color: $neutral-900;
    border: 2px solid $primary-300;
    svg {
      path {
        stroke: $neutral-900;
      }
    }
  }

  // Selected mode, when button is selected as a part of form etc.
  &--selected {
    border: 1px solid $primary-600;
    background: $primary-200;
    svg {
      path {
        stroke: $primary-600;
      }
    }
  }
  &__text {
    &--selected {
      color: $primary-600;
      @include boldTypography;
    }
  }

  // Small size variant
  &--small {
    height: 41px;
    gap: 8px;
  }

  // medium size variant
  &--medium {
    height: 43px;
    gap: 8px;
  }

  // large size variant
  &--large {
    height: 46px;
    gap: 8px;
  }

  // Icon variant
  &__icon {
    &--small {
      height: 12px;
    }
    &--medium {
      height: 12px;
    }
    &--large {
      height: 13.5px;
    }
  }
}
