@import '../../../assets/styles/index.scss';
@import '../../../components/profile/profileCard/profileCard.scss';

.company-profile-card {
  @include flexCol;
  gap: 12px;
  &__row {
    @include flexRow;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    border-radius: 16px;
    &__picture {
      position: relative;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      .profile-picture {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        background: $white-100;
        img {
          object-fit: contain;
        }
        h3 {
          font-size: 20px;
        }
      }
      &__edit {
        position: absolute;
        @include flexCenterContent;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 32px;
        width: 32px;
        border-radius: 50%;
        cursor: pointer;
        @include hoverTransition;
        opacity: 0;
        background: $primary-200;
        svg path {
          stroke: $primary-600;
        }
      }
      &:hover {
        .company-profile-card__picture__edit {
          opacity: 1;
        }
      }

      &__options {
        display: none;
        position: absolute;
        top: 50px;
        z-index: 9999;
        border: 1px solid $primary-200;
        border-radius: 8px;
        &--is-open {
          display: block;
        }
      }
    }
    &__content {
      @include flexCol;
      flex: 1;
      gap: 8px;
      &__header {
        @include flexRow;
        justify-content: space-between;
        &__company-info {
          @include flexCol;
          color: $neutral-1000;
          gap: 8px;

          h5 {
            cursor: pointer;
          }
        }
        &__actions {
          @include flexRow;
          gap: 16px;
          &__follow {
            width: 124px;
          }
        }
      }
      &__introduction {
        color: $neutral-1100;
        // word-break: break-all;
      }
      &__stats {
        @include profileCardStats;
      }
    }
  }

  &__footer {
    @include flexRow;
    gap: 24px;
    &__button {
      width: 140px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .company-profile-card {
    &__row {
      &__picture {
        width: 100px;
        height: 100px;
        .profile-picture {
          h3 {
            font-size: 40px;
          }
        }
        &__options {
          top: 100px;
        }
      }
    }
  }
}
