@import '../../../../../assets/styles/index.scss';

.custom-application-form {
  @include flexCol;
  gap: 16px;
  color: $neutral-1100;

  &__form {
    @include flexCol;
    gap: 16px;

    &__header {
      @include flexRow;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;

      &__navigation {
        @include flexRow;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        button {
          width: 128px;
        }
      }
    }

    &__row {
      @include flexCol;
      gap: 16px;

      &__fields {
        @include flexCol;

        &__field {
          flex: 1;
        }
       
      }
    }

    &__add-section {
      width: 150px;
    }
  }
}

@media screen and (min-width: $desktop) {
  @media screen and (min-width: $tablet) {
    .custom-application-form {
      gap: 24px;

      &__form {
        gap: 24px;
        &__row {
          &__fields {
            @include flexRow;
            gap: 24px;
          }
        }
      }
    }
  }
}
