@import '../../../../assets/styles/index.scss';

.select-field-component {
  @include flexCol;
  gap: 8px;
  &__custom-select {
    position: relative;

    &__select {
      @include flexRow;
      justify-content: space-between;
      align-items: center;
      height: 41px;
      padding: 0 16px 0 12px;
      border-radius: 8px;
      border: 1px solid $neutral-700;
      color: $neutral-700;
      cursor: pointer;
      @include hoverTransition;
      &:hover {
        border: 2px solid $primary-300;
      }
      &:focus {
        outline: 2px solid $primary-300;
        border: none;
      }
      &--selected {
        color: $neutral-1100;
      }

      &__icon {
        transform: rotate(0deg);
        &--is-open {
          transform: rotate(180deg);
        }
      }
    }

    &__options {
      display: none;
      position: absolute;
      margin: 0;
      width: 100%;
      color: $neutral-1100;
      border: 1px solid $neutral-700;
      border-width: 2px;
      background: $background-100;
      border-radius: 8px;
      z-index: 9999;
      max-height: 215px;
      overflow-y: auto;
      padding-right: 8px;
      cursor: pointer;
      @include customScroll;
      &--is-open {
        display: block;
      }

      &__option {
        @include OptionStyling;
      }
    }
  }

  &__selected {
    @include flexCol;
    gap: 16px;
    &__tags {
      @include flexRow;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}
