@import '../../../../../assets/styles/index.scss';

.application-actions {
  @mixin ideaDetailsIconStyle {
    @include actionIconHoverBehavior;
    height: 40px;
    width: 40px;
    svg {
      height: 24px;
      width: 24px;
    }
  }

  @include flexRow;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  &__label {
    @include flexRow;
    gap: 24px;
    color: $black-100;
    align-items: center;

    &__status {
      color: $primary-600;
      background: $primary-100;
      padding: 6px 16px;
      border-radius: 100px;
    }

    &__stage {
      @include flexRow;
      align-items: center;
      gap: 8px;
      color: $success-800;
    }
  }

  &__actions {
    @include flexRow;
    gap: 24px;
    align-items: center;

    &__finalize-idea {
      width: 159px;
    }

    &__share {
      position: relative;

      .dropdown-component {
        top: calc(100% + 5px);
      }

      &__icon {
        @include ideaDetailsIconStyle;
      }
    }

    &__next {
      width: 168px;
    }

    &__share {
      width: 142px;
    }

    &__view-company {
      width: 185px;
    }

    &__dropdown {
      .dropdown-component {
        top: calc(100% + 5px);
      }
    }
  }
}
