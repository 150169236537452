@import '../../assets/styles/index.scss';
@import './featureBlock/featureBlock.scss';

@mixin overrideLinkStyling {
  a {
    text-decoration: none;
    color: inherit;
  }
}

@mixin landingPagePadding {
  padding: 8px 16px;

  @media screen and (min-width: $tablet) {
    padding: 16px 32px;
  }

  @media screen and (min-width: $desktop) {
    padding: 24px 64px;
  }
}

.landing-page__hero__sub-heading {
  margin-bottom: 20px;
}
.homeContainer{
  background: #fafafa;
}

@mixin landingPageHeading {
  color: $neutral-1000;

  &--highlight {
    color: $primary-600;
  }
}

@mixin limitSectionWidth {
  max-width: 1440px;
  margin: 0 auto;
}

.heroBanner {
  padding: 170px 0px 100px;
  background: #fff3ed;

  .heroImage {

    img {
      width: 90%;
      margin: 0 auto;
      display: flex;
    }
  }
}

// base styles
.landing-page {
  @include flexCol;

  // gap: 16px;
  &__top-navigation {
    @include flexRow;
    @include flexJustifyContentSpaceBetween;
    align-items: center;
    padding: 8px 16px; // mobile specific

    &__logo {
      width: 100px; // mobile specific
    }

    &__buttons {
      @include flexRow;
      gap: 16px; // mobile specific
      @include overrideLinkStyling;

      &__button {
        text-wrap: nowrap;
      }
    }
  }

  &__hero {

    &__heading {
      color: $neutral-1100;
      margin-bottom: 20px;

      &--highlight {
        color: $primary-600;
      }
    }

    &__sub-heading {
      color: $neutral-1000;
    }

    &__button,
    button {
      width: 180px;
      padding: 10px 20px;
    }

    &__banner {
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__about {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 32px;
    padding: 8px 16px;
    background: #fff;
    @include limitSectionWidth;

    &__content {
      @include flexCol;
      gap: 24px;
      color: $neutral-1000;

      &__heading {
        &--highlight {
          color: $primary-600;
        }
      }

      &__description {
        @include flexCol;
        gap: 21.86px;
      }
    }

    &__banner {
      @include flexCenterContent;

      img {
        width: 300px;
        border-radius: 16px;
      }
    }
  }

  &__key-features {
    @include landingPagePadding;
    @include limitSectionWidth;
    @include flexCol;
    margin-top: 16px;
    gap: 32px;

    &__heading {
      @include landingPageHeading;
      text-align: center;
    }

    &__features {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;
    }
  }

  &__htraction-for {
    @include landingPagePadding;
    @include flexCol;
    @include limitSectionWidth;
    margin-top: 16px;
    gap: 32px;

    &__heading {
      @include landingPageHeading;
      text-align: center;
    }

    &__items {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;

      &__item {
        @include featureBlock;
        padding: 39px 24px;
        display: block !important;

        p {
          margin-bottom: 12px;
        }
      }
    }
  }
}
.modal-backdrop{
  // z-index: 100000;
  
}
.contactPopup {
  z-index: 100001;

  .contactModal {
    align-items: flex-start !important;
    border-bottom: none !important;

    .modal-title {

      h3 {
        font-size: 24px;
        font-weight: 600;
        color: #1D1D1D;
        margin-bottom: 8px;

        span {
          color: #FA4616;
        }
      }

      p {
        color: #121212;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0px;
      }
    }
  }

  .form-group {
    margin-bottom: 24px;

    label {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
      color: #121212;

      span {
        color: #FA4616;
      }
    }

    select {
      border: 1px solid #898989;
      padding: 9px 16px;
      font-size: 14px;
      color: #898989;
      font-weight: 400;
      border-radius: 8px;

      &:focus {
        outline: none;
        box-shadow: none;
        border-color: #FA4616;
      }
    }

    svg {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translate(0%, -50%);
    }

    .form-control {
      border: 1px solid #898989;
      padding: 12px 16px 12px 35px;
      font-size: 14px;
      color: #898989;
      font-weight: 400;
      border-radius: 8px;

      &:focus {
        outline: none;
        box-shadow: none;
        border-color: #FA4616;
      }
    }
  }

  .modal-footer {
    border-top: 0px;
    padding: 0px 24px 24px;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;

    .btn {
      padding: 10px 70px;
      border: 1px solid #FA4616;
      border-radius: 50px;
      background-color: transparent;
      color: #FA4616;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
      min-width: 190px;

      &.active {
        color: #fff;
        background-color: #FA4616;
      }
    }
  }
}

// responsive styles for tablet
@media screen and (min-width: $tablet) {
  .landing-page {
    &__top-navigation {
      padding: 16px 32px; // tablet  specific

      &__logo {
        width: 182.47px;
      }
    }

    &__hero,
    &__about {
      // padding: 0 32px;
    }

    &__about {
      padding: 54px 32px;

      &__banner {
        img {
          width: 400px;
        }
      }
    }

    &__key-features {
      &__features {
        grid-template-columns: 1fr 1fr;
      }
    }

    &__htraction-for {
      &__items {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

.contactButton {
  background: #FA4616;
  padding: 8px 25px;
  border: 1px solid #FA4616;
  border-radius: 50px;
  display: flex;
  margin: 16px auto 0px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}

.inviteOnly {
  display: flex;
  margin: 10px auto;
  justify-content: center;
  color: #6E6E6E;
  font-weight: 500;
}

.header-pd {
  position: fixed;
  width: 100%;
  left: 0px;
  padding: 20px 0px;
  // background: #fff3ed;
  background: #fafafa;
  z-index: 999;
  transition: .5s;

  &.headActive {
    background: #fff3ed;
  }
}

// responsive styles for desktop
@media screen and (min-width: $desktop) {
  .landing-page {
    &__top-navigation {
      padding: 24px 64px 54px; // mobile specific
      // max-width: 1440px;
      // min-width: 100%;
      margin: 0 auto;

      &__buttons {
        &__button {
          width: 140px;
        }
      }
    }

    &__hero,
    &__about {
      // padding: 0 64px;
    }

    &__about {
      grid-template-columns: 61% 30.5%;
      justify-content: space-between;
      padding: 54px 64px;

      &__banner {
        img {
          width: 100%;
        }
      }
    }

    &__key-features {
      &__features {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    &__htraction-for {
      &__items {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
}