@import '../../../assets/styles/index.scss';

.social-stat-component {
  @include flexCol;
  gap: 4px;
  width: 100px;

  &:nth-child(2) {
    border-left: 1px solid $primary-300;
    border-right: 1px solid $primary-300;
  }

  &__label {
    color: $neutral-700;
    text-align: center;
  }

  &__value {
    color: $neutral-1100;
    text-align: center;
  }
}
