@import '../../../../assets/styles/index.scss';

.cohortActions {
  @mixin ideaDetailsIconStyle {
    @include actionIconHoverBehavior;
    height: 40px;
    width: 40px;
    svg {
      height: 24px;
      width: 24px;
    }
  }

  .label {
    @include flexRow;
    gap: 16px;
    color: $black-100;
    align-items: center;
    font-size: 12px;
  }
  .actions {
    @include flexRow;
    gap: 24px;
    align-items: center;
    width: 100%;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 20px;
      row-gap: 8px;
      .cohortName{
        font-size: 16px;
        margin: 0;
      }
      .dates{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
        row-gap: 8px;
        p{
          font-size: 14px;
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }
}
