@use '../../assets//styles/index.scss' as styles;
.venture-details{
    padding: 20px;
    max-height: 'auto';
    height: 'auto';
    &__form {
        @include styles.flexCol;
        gap: 8px;
    
        &__row {
          @include styles.flexRow;
          gap: 8px;
          align-items: center;
          width: 100%;
    
          .input-field-component {
            flex: 1;
          }
    
          &__actions {
            @include styles.flexRow;
            align-items: center;
            gap: 8px;
    
            button {
              font-size: 12px;
              padding: 9px 24px;
              height: 43px;
            }
          }
    
          &__files {
            @include styles.flexRow;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;
          }
        }
      }
}