@import '../../../assets/styles/index.scss';

.edit-reply {
  @include flexCol;
  gap: 10px;
  &__header {
    @include flexRow;
    gap: 10px;

    .input-field-component {
      flex: 1;
    }
  }

  &__buttons {
    @include flexRow;
    justify-content: flex-end;
    gap: 16px;
    button {
      width: 150px;
    }
  }
}
