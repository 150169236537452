.toggleBox {
  max-height: 240px;
  height: auto;
  width: 234px;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 18px 10px 12px;
  border-radius: 12px;
  border: 1px solid #ffe5d5;
  z-index: 5;
  transform: translate(0px, 27px);
  list-style: none;

  .topHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;

    button{
      border: none;
      background: transparent;
      font-size: 14px;
      color: #fa4616;
    }
  }

  .itemList {
    text-align: left;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-y: auto;
    max-height: 170px;
    height: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 4px;
      background: #fff;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fa4616;
      border-radius: 100px;
    }

    li {
      font-family: Manrope;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.12px;
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  .buttonContainer {
    padding: 6px 0;
  }
}
