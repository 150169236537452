@import '../../assets/styles/index.scss';

@mixin logoPadding {
  padding: 8px 16px;

  @media screen and (min-width: $tablet) {
    padding: 16px 32px;
  }

  @media screen and (min-width: $desktop) {
    // padding: 22px 71px;
    padding: 27px 112px 29px;
  }
}

.auth-layout {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  height: 100dvh;
  background: $background-100;

  &__content {
    @include flexCol;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    position: relative;

    &__logo {
      @include logoPadding;
      background: $background-100;
      width: 100%;

      img {
        // width: 100px;
        max-width: 161px;
        height: 34px;
      }
    }

    &__form {
      width: 90%;
      height: 100%;
      @include flexCenterContent;
      align-items: flex-start;
    }
  }

  &__banner {
    display: none;
    max-width: 620px;
    position: relative;
    top: -90px;
  }
}

.loginPage_mainBox {
  background: #fafafa;

  .imgBox {
    padding: 29px 0px;

    img {
      width: 160.52px;
      height: 31px;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: $tablet) {
  .auth-layout {
    grid-template-columns: 1fr 1fr;

    &__content {
      &__logo {
        img {
          width: 160.52px;
          height: 32px;
          padding-top: 2px;
        }
      }

      &__form {
        width: 500px;
        margin: 0 10px;
      }
    }

    &__banner {
      display: block;
    }
  }
}

@media screen and (min-width: $desktop) {
  .auth-layout {
    &__content {
      &__form {
        margin: 0;
      }
    }
  }
}