@import '../../../assets/styles/index.scss';

.circle-loader-component {
  @keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @include flexCenterContent;
  svg {
    height: 90%;
    animation: rotateAnimation 0.8s infinite linear;
  }
}
