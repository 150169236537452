@import '../../assets/styles/index.scss';

.view-profile {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;
  &__body {
    @include flexCol;
    gap: 24px;
    flex: 1;
    padding: 16px;
    margin-top: 89px;
    &__content {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;
      &__body {
        @include flexCol;
        gap: 24px;
        overflow: hidden;
      }
      &__cards {
        @include flexCol;
        gap: 32px;
      }
    }
  }
}

@media (max-width: 768px) {
  .view-profile  {
    &__body {
      margin-top: 160px;
    }
  }
}

@media screen and (min-width: $desktop) {
  .view-profile {
    &__body {
      padding: 32px;
      // width: 95%;
      // width: max(1074px, calc((100vw - 1287px) / 2) - 39px);;
      width :  calc(100vw - (300px + (max(85px, calc((100vw - 1287px) / 2) - 39px) * 2) - 32px));
      &__content {
        display: grid;
        grid-template-columns: 65% 32.5%;
      }
    }
  }
}
