.investementTracking {
  width: 100%;
  .title {
    font-size: 16px;
    margin: 0;
    margin-bottom: 10px;
  }
  .chatHeading {
    font-size: 14px;
    margin: 0;
    margin-bottom: 10px;
  }
  .initialTitle {
    p {
      font-size: 13px;
      font-weight: 500;
      margin: 0;
    }
  }
  .itemContainer {
    min-height: 250px;
    padding: 8px;
    button {
      .addIcon {
        font-size: 18px;
        font-weight: 500;
      }
    }
    &.autoHeight {
      min-height: 100px;
    }
    .itemLabel {
      margin-bottom: 10px;
      &.display {
        height: 100%;
        display: flex;
        align-items: center;
      }
      &.prices {
        margin: 0;
        .label {
          display: block;
          margin-bottom: 10px;
          display: flex;
          gap: 10px;
          span {
            display: block;
            min-width: 90px;
          }
        }
      }
      .label {
        font-size: 14px;
        font-weight: 500;
      }
    }

    .inputFields {
      // margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 8px;
      height: 60px;
      width: 100%;
      &.bottonMargin {
        transform: translateY(-13px);
      }
      :global(.input-field-component) {
        width: 100% !important;
      }

      button {
        .addIcon {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }

    .teamCard {
      background-color: #fffbf9;
      border-radius: 8px;
      padding: 0px 8px;
      height: auto;
      min-height: 70px;
      width: 100%;
      border: 1px solid #ffe5d5;
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 14px;
      &.deactivate{
        opacity: 0.5;
      }
    }
  }
  :global(.insideCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 18px 8px;
    margin-bottom: 10px;
  }
}
