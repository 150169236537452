@import '../../../assets/styles/index.scss';

.chat-program-file-card {
  @include flexRow;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  &__preview-image{
    height: 100px;
    width: 160px;
    object-fit: cover;
  }

  &__file {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    padding: 8px;
    @include flexCenterContent;
    background: $primary-100;

    svg path {
      stroke: $primary-600;
    }
  }

  &__text {
    max-width: 216px !important;
    @include textEllipsis;
  }
}
