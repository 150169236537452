.toggleBox {
  height: 230px;
  width: 230px;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  padding: 18px 20px 0 20px;
  border-radius: 12px;
  border: 1px solid #ffe5d5;
  background: #fff;
  z-index: 10000;
  transform: translate(0px, 27px);
  list-style: none;
  .itemList {
    text-align: left;
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      font-family: Manrope;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.12px;
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
