@import '../../../assets/styles/index.scss';

.startup-news-resources {
  @include flexCol;
  padding: 16px;
  gap: 16px;
  align-items: center;

  p {
    width: 100%;
    text-align: left;
  }

  img {
    width: 162px;
    padding: 20px 0;
  }
}
