@import '../../assets/styles/index.scss';

.apply-to-program {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;
  max-width: 1360px;
  margin: 0 auto;
  
  &__body {
    @include flexCol;
    gap: 16px;
    padding: 16px;
    margin-top: 89px;
    

    &__step {
      @include flexCol;
      justify-content: space-between;
      gap: 16px;

      &__component {
        @include flexCol;
        gap: 16px;
        width: 100%;

        &__header {
          @include flexRow;
          align-content: center;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 16px;

          button {
            width: 162px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .apply-to-program{
    &__body{
      margin-top: 160px;
    }
  }
}
@media screen and (min-width: $desktop) {
  .apply-to-program {
    &__body {
      gap: 24px;
      padding: 32px;

      &__step {
        @include flexRow;
        gap: 32px;

        &__component {
          gap: 24px;
        }
      }
    }
  }
}
