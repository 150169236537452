@import '../../../assets/styles/index.scss';

.post-media-modal {
  @media screen and (min-width: $desktop) {
    .modal-component {
      height: 500px;
      width: 800px;
      padding: 16px !important;
      .post-media-modal__content {
        height: 416px;
        .post-card__carousel {
          height: 100%;
          img,
          video {
            width: 100% !important;
            height: 373px;
          }
        }
      }
    }
  }

  .carousel-component__video__play-button {
    display: none !important; // hide it from the modal
  }
}
