@import '../../../../assets/styles/index.scss';

.program-card {
  @include flexCol;
  padding: 16px;
  gap: 16px;
  &__content {
    @include flexRow;
    justify-content: space-between;
    gap: 16px;

    &__details {
      @include flexCol;
      gap: 8px;
      color: $neutral-1100;

      &__heading {
        @include flexRow;
        align-items: center;
        gap: 8px;

        &__title {
          max-width: 112px;
          cursor: pointer;
        }

        &__status {
          color: $neutral-800;
        }
      }
    }

    &__stats {
      color: $neutral-800;

      p {
        text-align: center;
      }
    }
  }

  &__footer {
    margin-top: auto;
    @include flexRow;
    justify-content: space-between;
    align-items: center;

    &__action {
      width: fit-content;
    }

    &__dropdown {
      position: relative;
      cursor: pointer;
      height: 32px;
      width: 32px;
      @include flexCenterContent;
      .dropdown-component {
        width: fit-content;
        white-space: nowrap;
      }
    }
  }
}
