.subCategoryCard {
  border: 1px solid #ffe5d5;
  background: #ffffff;
  padding: 12px;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 18px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    h5 {
      font-size: 14px;
      font-weight: 600;
      line-height: 19.12px;
      text-align: left;
      margin: 0;
    }
    .actions{
        display: flex;
        align-items: center;
        gap: 10px;
    }
  }
  .content{
    width: 100%;
  }
}
