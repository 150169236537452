@import '../../assets/styles/index.scss';

.create-company {
  &__body {
    padding: 16px;
    color: $neutral-1100;
    margin-top: 89px;
    @include flexCol;
    gap: 16px;

    &__stepper {
      align-self: center;
      width: 90%;
    }
  }
}
@media (max-width: 768px) {
  .create-company{
    &__body{
      margin-top: 160px;
    }
  }
}
@media screen and (min-width: $desktop) {
  .create-company {
    &__body {
      padding: 32px;
      gap: 24px;
      // width: 95%;
      width :  calc(100vw - (300px + (max(85px, calc((100vw - 1287px) / 2) - 39px) * 2) - 32px));
      &__stepper {
        width: 65%;
      }
    }
  }
}
