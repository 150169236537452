// Checkbox
input[type='checkbox'] {
  appearance: none;
  height: 18px;
  width: 18px;
  cursor: pointer;
  background-repeat: no-repeat !important;
  background: url('../../assets/images/svg/checkboxUnchecked.svg');
  &:checked {
    background: url('../../assets/images/svg/checkMarkChecked.svg');
  }
}

@mixin customCheckboxInput {
  input[type='checkbox'] {
    appearance: none;
    height: 18px;
    width: 18px;
    cursor: pointer;
    background-repeat: no-repeat !important;
    background: url('../../assets/images/svg/checkboxUnchecked.svg');
    &:checked {
      background: url('../../assets/images/svg/checkMarkChecked.svg');
    }
  }
}

// Radio input
@mixin customRadioInput {
  input[type='radio'] {
    appearance: none;
    &::after {
      display: block;
      content: url('../../../public/assets/images/svg/radioUnchecked.svg');
    }
    &:disabled::after {
      content: url('../../../public/assets/images/svg/radioUncheckedDisabled.svg');
    }
    &:checked::after {
      content: url('../../../public/assets/images/svg/radioChecked.svg');
    }
  }
}

// Mandatory field label
.label-mandatory-field {
  &::after {
    content: '*';
    color: $error-800;
  }
}

// Hyperlinks
.social-media-hyperlink {
  color: $primary-600;
  word-break: break-all;
}

@mixin hideInputNumberFieldArrow {
  // Hide arrows/spinners from input type number
  // www.w3schools.com/howto/howto_css_hide_arrow_number.asp
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

// Radio styles
