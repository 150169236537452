.tabContainer {
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: auto; // Horizontal scroll only
    overflow-y: visible; // Ensure no vertical scroll is triggered
    border-bottom: 1px solid #ffe5d5;
    &::-webkit-scrollbar {
      width: 6px;
      height: 4px;
      background: #fff;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fa4616;
      border-radius: 100px;
    }
    li {
      margin-right: 15px;
      cursor: pointer;
      // :global(.dropdown) {
        :global(.dropdown-toggle) {
          background: transparent !important;
          border: none !important;
          color: black;
          font-size: 14px;
          font-weight: 500;

          // &:hover {
          //   color: #fa4616;
          // }
          &::after {
            display: none; // Hide default Bootstrap caret
          }

          &.btn {
            background: none !important;
            border: none !important;
            color: #000;
            font-weight: 500;

            

            &::after {
              display: none; // Hide default Bootstrap caret
            }
          }
        }
      // }
    }
  }
}

.dropdownMenu {
  position: absolute;
  z-index: 1000; // Ensures it is above other content
  white-space: nowrap;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  .dropdown-menu{
    max-width: 80px
  }
}
