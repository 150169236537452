@import '../../../../../../../assets/styles/index.scss';

.pd-create-edit-group {
  @mixin addIdeaGroupGridStyling {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;

    @media screen and (min-width: $tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }

  @mixin membersGridStyling {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media screen and (min-width: $desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__modal {
    gap: 16px !important;
    height: 500px;
  }

  &__content {
    @include flexCol;
    gap: 16px;
    height: 100%;
  }

  &__form {
    @include flexCol;
    gap: 16px;
    height: 100%;

    &__search-users {
      position: relative;

      &__container {
        position: absolute;
        @include flexCol;
        width: 100%;
        z-index: 99;
        gap: 12px;
        top: 82px;
        width: 100%;
        border: 1px solid $neutral-400;
        background: $background-100;
        border-radius: 8px;
        margin: 1px 0;
        padding: 12px;

        &__result {
          max-height: 168px;
          overflow-y: auto;
          @include customScroll;
          @include addIdeaGroupGridStyling;
        }
      }
    }

    &__users {
      @include membersGridStyling;
      max-height: 192px;
      overflow-y: scroll;
      @include customScroll;

      &--Edit {
        height: 159px;
      }
    }

    &__buttons {
      @include flexRow;
      justify-content: center;
      gap: 8px;
      margin-top: auto;

      &__close,
      &__create-group {
        width: 150px;
      }
    }
  }
}
