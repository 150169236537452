.pollCard {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  width: 100%; // Ensures it takes the full width
  max-width: 100%; // Optional, prevents it from getting too wide on larger screens
  margin: 0 auto; // Centers it when max-width applies
}


.question {
  margin-top: 12px;
  font-size: 16px;
  font-weight: bold;
}

.options {
  margin-top: 10px;
  width: 100%;
}

.optionButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: #f9f9f9;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  p{
    margin: 0;
    word-break: break-all;
  }

  .successSymbol {
    font-weight: bold;
    margin-left: 8px;
    .checkIcon{
      height: 20px;
      width: 20px;
      color: #1f3e80;
    }
}

  &:hover {
    // background: #efefef;
    opacity: 0.8;
  }

  span {
    font-size: 14px;
    position: relative;
    z-index: 2;
  }
}

/* Background transition effect when results are shown */
.showResults {
  background: linear-gradient(to right, #ffe5d5 0%, rgb(250 71 22 / 23%) 0%);
  background-repeat: no-repeat;
  background-position: left;
  transition: background-size 0.5s ease-in-out;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;

  .showResultButton {
    color: #fa4616;
    font-size: 13px;
    cursor: pointer;
    font-weight: 600;
    margin: 0;
  }
  .voteCount {
    font-size: 13px;
    color: #666;
    margin: 0;
  }
}

@media (max-width: 600px) {
  .pollCard {
    max-width: 100%; // Ensures it takes full width on smaller screens
  }
}

