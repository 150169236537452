@import '../../../assets/styles/index.scss';

.hiringDetail {
  .modal-component {
    position: relative;
    width: 600px !important;
  }
  &__content {
    color: $neutral-1100;
    overflow-x: hidden;
    @include flexCol;
    gap: 16px;
    &__header {
      @include flexRow;
      align-items: center;
      color: $neutral-1100;
      gap: 8px;
      .profile-picture {
        height: 24px;
        width: 24px;
      }
    }

    &__post-type {
      @include flexRow;
      gap: 24px;

      &__field {
        @include flexRow;
        gap: 8px;
        padding: 12px 16px;
        align-items: center;
        input,
        label {
          cursor: pointer;
        }
        @include customRadioInput;

        &--is-disabled {
          label {
            color: $neutral-600;
          }
        }
      }
    }

    &__form {
      @include flexCol;
      gap: 8px;

      &__button-fields {
        @include flexRow;
        gap: 16px;

        .input-field-component {
          flex: 1;
        }
      }

      .textarea-component {
        width: 100%;
      }

      &__link-preview {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    &__actions {
      @include flexRow;
      gap: 16px;
      &__action {
        @include flexCenterContent;
        @include hoverOrange;
        height: 32px;
        width: 32px;
        border-radius: 100px;
        cursor: pointer;
      }
    }
    &__media {
      @include flexRow;
      gap: 24px;
      flex-wrap: wrap;
      &__item {
        height: 130px;
        width: 130px;
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        &__delete {
          position: absolute;
          height: 32px;
          width: 32px;
          background: $background-100;
          border-radius: 50%;
          @include flexCenterContent;
          top: 10px;
          right: 10px;
          cursor: pointer;
          @include hoverOrange;
        }
        &--is-uploading {
          @include flexCenterContent;
        }
      }
    }
    &__buttons {
      @include flexRow;
      justify-content: flex-end;
      overflow-y: hidden;
      gap: 8px;
      button {
        width: 140px;
      }
      &__poll-button {
        &__cancel {
          color: #000;
          border: none;
          height: 38px;
          background: transparent;
          width: 100px !important;
          margin-bottom: 14px;
          letter-spacing: 0.05px;
          font-family: 'Manrope', sans-serif;
          font-size: 14px;
          font-weight: 600;
        }
        &__submit {
          border: none;
          height: 38px;
          background: rgb(250 71 22 / 72%);
          border-radius: 18px;
          color: #fff;
          letter-spacing: 0.05px;
          width: 120px !important;
          margin-bottom: 14px;
          font-family: 'Manrope', sans-serif;
          font-size: 14px;
          font-weight: 600;

          &.active {
            background: #fa4616;
          }
        }
      }
    }

    &__poll {
      margin-top: 10px;

      .textarea-field-component {
        .textarea-component {
          min-height: 48px;
          width: 100%;
          &:hover {
            min-height: 48px;
            height: auto;
          }
          textarea {
            height: 100%;
          }
          &:hover {
            border: none;
          }
        }
      }
      &__optionBox {
        margin-top: 8px;
        &__char-count {
          text-align: right;
          display: block;
          font-size: 13px;
          font-weight: 500;
          color:rgba(0, 0, 0, 0.467);
          margin-right: 10px;
        }
      }
    }

    // Emoji picker styling
    .EmojiPickerReact {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .select-post-image {
    display: none;
  }
  .uploadFileld{
    margin-top: 10px;
    position: relative;
  }
}

@media screen and (min-width: $desktop) {
  .hiringDetail {
    .modal-component {
      width: 900px;
    }
  }
}
