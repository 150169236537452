@import '../../../../assets/styles/index.scss';

.discussion-user {
  @include flexRow;
  gap: 8px;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  margin-right: 5px;

  &--is-active {
    background: $primary-100;
  }

  .profile-picture {
    height: 24px;
    width: 24px;

    h3 {
      font-size: 12px;
    }
  }

  &__user {
    color: $neutral-1000;
    cursor: pointer;
    @include lineClamp(1);
    border-radius: 8px;

    &--is-active {
      @include bodyTwo;
      @include semiBoldTypography;
      color: $primary-600;
    }
  }
}
