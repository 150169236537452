@import '../../../../../assets/styles/index.scss';

.view-program-application-sections {
  @include flexCol;
  gap: 16px;

  &__menu {
    @include flexRow;
    gap: 8px;
    flex-wrap: wrap;

    &__item {
      height: 43px;
      border-radius: 8px;
      width: fit-content;
      padding: 8px;
    }
  }
  &__component {
    flex: 1;
  }
}

@media screen and (min-width: $tablet) {
  .view-program-application-sections {
    @include flexRow;
    gap: 32px;

    &__menu {
      @include flexCol;
      width: 260px;
      padding: 16px;

      &__item {
        width: 100%;
        padding: 12px 16px;
      }
    }
  }
}
