// Orange custom scroll

@mixin customScroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background: $primary-100;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primary-600;
    border-radius: 100px;
  }
}

@mixin mainPageScroll {
  @include customScroll;
  height: 100%;
  overflow-y: auto;
  padding-right: 0px;
  &::-webkit-scrollbar {
    width: 4px;
  }
}

@mixin hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}
