@import '../../../../../assets/styles/index.scss';
.simple-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #ddd;
  }

  td {
    text-align: center;
  }
  
}

.page {
  background-color: '#FFFFFF';
  padding: '30px';
  font-family: 'Arial, sans-serif';
}
.header{
  font-size: '20px';
  margin-bottom: '20px';
  color: '#000000';
  text-align: 'center';
  text-decoration: 'underline';
}
.section {
  margin: '10px 0';
  padding: '10px';
  border-bottom: '1px solid #000000';
}
.question {
  font-size: '15px';
  margin-bottom: '5px';
  font-weight: 'bold';
}
.answer {
  font-size: '12px';
  margin-bottom: '10px';
  color: '#000000';
}
.program-application-section-view {
  @include flexCol;
  gap: 16px;

  &__title {
    color: $neutral-1100;
  }

  &__fields {
    @include flexCol;
    gap: 16px;

    &__field {
      @include flexCol;
      gap: 8px;

      &__value {
        color: $neutral-1000;
      }
    }
  }
}
