@import '../../../../assets/styles/index.scss';

.chat-messages-header {
  @include flexRow;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid $primary-200;

  &__left {
    @include flexRow;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    &__go-back-mobile {
      display: flex;
    }

    .profile-picture {
      height: 43px;
      width: 43px;
    }

    &__namePerson {
      font-size: 14.92px;
      margin-bottom: 2px;
      color: #1D1D1D;
      text-transform: capitalize;
    }

    &__profileRole {
      font-size: 13.64px;
      color: #6E6E6E;
      display: flex;
      gap: 3px;
    }

    p {
      // line-height: initial;
      cursor: pointer;
    }

    .vertical-separator-component {
      height: 32px;
    }
  }

  &__right {
    @include flexRow;
    align-items: center;
    gap: 8px;

    .dropdown-component {
      min-width: 124px;
    }
  }
}

@media screen and (min-width: $desktop) {
  .chat-messages-header__left__go-back-mobile {
    display: none;
  }
}