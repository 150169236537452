@import '../../../../assets/styles/index.scss';

.applied-program-card {
  @include flexCol;
  gap: 8px;
  color: $neutral-1000;
  height: fit-content;

  &__header {
    @include flexRow;
    align-items: center;
    justify-content: space-between;

    &__left {
      @include flexRow;
      align-items: center;
      gap: 16px;

      &__user-info {
        @include flexCol;
        gap: 4x;

        &__app-no {
          color: $neutral-800;
        }
      }
    }

    &__right {
      @include flexCol;
      justify-content: center;
      gap: 4px;

      &__stage {
        @include flexRow;
        align-items: center;
        gap: 8px;
      }

      p {
        text-align: center;
      }
    }
  }

  &__view-details {
    width: 145px;
  }
}
