@import '../../../../../assets/styles/index.scss';

@mixin editProfileGridStyle {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }
}
.update_button {
  width: 50%;
  padding-top: 20px;
  margin: auto;
}
.edit-cohort-modal {
  .modal-component__content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.textareaField {
  height: 80px;
}
.edit-cohort {
  &__form {
    @include flexCol;
    gap: 12px;

    &__container-cohort {
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: calc(100vh - 220px);
    }

    &__fields {
      max-height: 364px;
      overflow-y: scroll;
      @include flexCol;
      gap: 16px;
      @include customScroll;
      padding-right: 8px;
      &__grid {
        @include editProfileGridStyle;
        &__field {
          @include flexCol;
          gap: 16px;
          color: $neutral-1100;
          input[type='date']::-webkit-calendar-picker-indicator {
            display: none;
          }
        }
      }

      &__address {
        @include flexCol;
        gap: 16px;
        color: $neutral-1100;
        &__grid {
          @include editProfileGridStyle;
        }
      }

      input {
        @include hideInputNumberFieldArrow;
      }
    }

    &__buttons {
      @include flexRow;
      justify-content: center;
      gap: 8px;
      button {
        width: 150px;
      }
    }
  }
}
.insideCard {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ffe5d5;
  width: 100%;
  height: auto;
  padding: 12px;
  margin-bottom: 10px;
  .itemContainer {
    min-height: 250px;
    padding: 8px;
    .itemLabel {
      margin-bottom: 10px;
      &.prices {
        flex-direction: row;
      }
      .label {
        font-size: 14px;
        font-weight: 500;
      }
    }

    .inputFields {
      // margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 8px;
      height: 60px;
      width: 50%;
      :global(.input-field-component) {
        width: 100% !important;
      }

      button {
        .addIcon {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }

    .teamCard {
      background-color: #fffbf9;
      border-radius: 8px;
      padding: 0px 8px;
      height: auto;
      min-height: 80px;
      width: 100%;
      border: 1px solid #ffe5d5;
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 14px;
    }
  }
}
.spacing {
  padding-top: 20px;
}
.dateBox {
  display: flex;
  justify-content: space-between;
}

.DateInputField {
  width: 100%;
}

.wideInputField {
  width: 20%;
}
