.outer_box {
  border: 1px solid #ffe5d5;
  border-radius: 16px;
  margin-bottom: 14px;
  position: relative;
  min-height: 295px;
  max-height: 295px;
  .actions {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-40%, 70%);
    svg {
      filter: brightness(7);
      cursor: pointer;
    }
  }
  .imageBox {
    padding: 4px;
    border-radius: 16px;
    width: 100%;
    height: 190px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }
  .marketplace_heading {
    padding: 0 12px;
    h4 {
      font-size: 16px;
      font-weight: 600;
      margin-top: 6px;
    }
    h5 {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .marketplace_content {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
    p {
      font-family: Manrope;
      font-size: 13px;
      font-weight: 400;
      line-height: 17.48px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .ctaButton {
    margin-bottom: 12px;
    button {
      width: auto;
      font-weight: 600;
      font-size: 12px;
    }
  }
}
