.likeReaction_modal {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0;
      display: flex;
      gap: 12px;
      padding: 8px 0px 9px;
      position: relative;
      cursor: pointer;

      &::after {
        border-bottom: 1px solid #8c8c8c33;
        bottom: 0;
        content: '';
        height: 0;
        position: absolute;
        right: 0;
        width: calc(100% - 52px);
      }
      &:last-child{
        &::after{
            display: none;
        }
      }

      .likeReaction_content {
        h4 {
          color: #1d1d1d;
        }
        p {
          color: #6e6e6e;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .likeReaction_modal {
    .modal-component {
      max-width: 550px;
    }
  }
}
