@import '../../../assets/styles/index.scss';

.create-comment {
  @include flexRow;
  gap: 10px;
  &__header {
    @include flexRow;
    gap: 10px;
    width: 100%;

    .input-field-component {
      flex: 1;
    }
  }

  &__buttons {
    @include flexRow;
    justify-content: flex-end;
    gap: 16px;
    align-items: center;
    button {
      width: 110px;
    }
  }
}
