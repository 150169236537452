@import '../../../../assets/styles/index.scss';

.idea-document-card {
  padding: 0;
  min-height: 180px;
  @include flexCol;
  justify-content: flex-end;

  &__footer {
    min-height: 64px;
    background: $primary-200;
    padding: 12px;
    @include flexRow;
    justify-content: space-between;
    gap: 8px;

    &__info {
      @include flexCol;
      gap: 4px;
      width: 90%;

      .tooltip-custom {
        width: 100%;
      }

      &__title {
        color: $black-100;
        cursor: pointer;
        @include lineClamp(1);
      }

      &__updated-at {
        color: $neutral-800;
      }
    }

    &__actions {
      height: 34px;
      width: 34px;
      cursor: pointer;
      @include flexCenterContent;
      align-self: flex-end;
      position: relative;
    }
  }
}
