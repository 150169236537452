@import '../../../../assets/styles/index.scss';

@mixin createCompanyGridStyle {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.create-company-company-details {
  @include flexCol;
  align-self: center;
  gap: 16px;

  &__form {
    @include flexCol;
    gap: 16px;

    &__grid {
      @include createCompanyGridStyle;
    }

    &__buttons {
      @include flexRow;
      gap: 16px;
      .button-component {
        width: 150px;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .create-company-company-details {
    width: 800px;
  }
}
