@import '../../../../assets/styles/index.scss';

@mixin companyProgramsGridStyle {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.company-programs {
  @include flexCol;
  gap: 16px;
  overflow: auto;
  @include mainPageScroll;
  height: auto;

  &__header {
    @include flexRow;
    justify-content: space-between;
    color: $neutral-1100;
    align-items: center;

    hr {
      flex: 1;
      margin: 0 16px;
      background: $neutral-300;
    }

    &__buttons {
      @include flexRow;
      justify-content: space-between;
      gap: 12px;
      align-items: center;

      &__all {
        height: 29px;
        width: 109px;
      }

      &__access {
        width: 136px;
        height: 29px;
      }
    }
  }
  &__programs {
    @include companyProgramsGridStyle;
  }
}
