.select-card-checkbox-component {
  &__option {
    label{
      width: 100%;
    }
    &__input {
      display: none;
    }
  }
}
