@import '../../../assets/styles/index.scss';

.company-card-component {
  @include flexCol;
  border-radius: 16px;
  padding: 12px;
  gap: 12px;

  &__header {
    @include flexRow;
    align-items: center;
    gap: 16px;

    &__name {
      color: $neutral-1000;
      @include lineClamp(1);
    }

    &__type {
      color: $neutral-1000;
    }

    .profile-picture-component {
      height: 56px;
      width: 56px;
    }
  }

  &__introduction {
    color: $neutral-1100;
    word-break: break-all;
    @include lineClamp(2);
  }

  &__stats {
    @include flexRow;
    gap: 8px;
  }
}
