@import '../../../../assets/styles/index.scss';

.delete-work-experience {
  .modal-component {
    width: 95%;
  }
  &__content {
    @include flexCol;
    align-items: center;
    gap: 24px;
    overflow-y: hidden !important;
  }
  &__title {
    text-align: center;
    color: $neutral-1100;
  }
  &__buttons {
    @include flexRow;
    gap: 24px;
    &__button {
      width: 140px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .delete-work-experience {
    .modal-component {
      width: 450px;
    }
  }
}
