@import '../../assets/styles/index.scss';

.my-company {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;
  height: 100%;
  &__body {
    @include flexCol;
    gap: 24px;
    flex: 1;
    padding: 16px;
    margin-top: 89px;

    &__title {
      @include flexRow;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      color: $neutral-1000;

      button {
        width: 216px;
      }
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;
      height: 100%;
      &__body {
        @include flexCol;
        gap: 24px;
        overflow: hidden;
        &_premium-actions{
          text-align: right;
          position: relative;
          span{
            font-weight: 500;
            font-size: 16px;
            cursor: pointer;
            span{
              img{
                height: 16px;
                width: 16px
              }
            }
          }
        }
      }
      &__cards {
        display: none;
        gap: 32px;
      }
    }
  }
}

@media (max-width: 768px) {
  .my-company{
    &__body{
      margin-top: 160px;
    }
  }
}

@media screen and (min-width: $desktop) {
  .my-company {
    &__body {
      padding: 32px;
      // width: 95%;
      width :  calc(100vw - (300px + (max(85px, calc((100vw - 1287px) / 2) - 39px) * 2) - 32px));
      &__content {
        display: grid;
        grid-template-columns: 65% 32.5%;
        &__cards {
          @include flexCol;
        }
      }
    }
  }
}
