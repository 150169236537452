@import '../../../../../assets/styles/index.scss';

.program-members {
  @include flexCol;
  gap: 16px;
  overflow-y: auto;
  height: 100%;
  @include customScroll;

  &__header {
    width: 100%;
    @include flexRow;
    justify-content: space-between;
    color: $neutral-1100;

    button {
      width: 159px;
    }
  }

  &__members {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    overflow: auto;
    padding-bottom: 50px;
    @include hideScrollbar;
  }
}

@media screen and (min-width: $tablet) {
  .program-members {
    &__members {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
