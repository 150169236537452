@import '../../../assets/styles/index.scss';

@mixin filterResultCardsGridStyle {
  display: grid;
  gap: 8px;
  grid-template-columns: minmax(0, 1fr);
  @media screen and (min-width: $tablet) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 16px;
  }
}

.filter-result-cards {
  @include flexCol;
  gap: 16px;

  &__header {
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    color: $neutral-1000;

    &__see-all {
      cursor: pointer;
      color: $neutral-800;
    }

    &__total-results {
      color: $neutral-800;
    }
  }

  &__items {
    @include filterResultCardsGridStyle;
  }

  &--is-post,
  &--is-announcement {
    &__items {
      grid-template-columns: minmax(0, 694px) !important;
    }
  }
}
