$muiSwitchWidth: 48px;
$muiSwitchThumbSize: 20px;
$muiSwitchThumbTranslate: $muiSwitchWidth - $muiSwitchThumbSize - 2;

.greenSwitch {
  position: relative;
  min-width: 52px;
  max-width: 52px;
  height: 28px;
  padding: 5px 9px;
  box-shadow: 1.2075471878051758px 1.2075471878051758px 3px 0px rgba(74, 81, 91, 45%);
  border-radius: 100px;
  cursor: pointer;
  overflow: hidden;
  background-color: #EFEEF3 !important;
  &.variant2{
    background-color: '#fa4616' !important;
  }

  .toggleInput {
    display: none;
  }

  .toggleLabel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  // Styles for the thumb (the circular part) of the switch
  .toggleThumb {
    position: absolute;
    top: 4px;
    left: 4px;
    padding: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    cursor: default;
  }

  .toggleInput:checked~.toggleThumb {
    transform: translateX($muiSwitchThumbTranslate );
    cursor: default;
  }
}