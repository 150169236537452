@import '../../../assets/styles/index.scss';

.confirm-modal-outer {
  @include modalPositioning;
  .confirm-modal {
    @include flexCol;
    align-items: center;
    gap: 24px;
    padding: 16px;
    border-radius: 16px;
    box-shadow: $shadow-200;
    width: 90%;
    background: $white-100;

    &__header {
      width: 100%;
      @include flexCol;
      align-items: center;
      &__close {
        align-self: flex-end;
        cursor: pointer;
        svg {
          height: 12px;
          width: 12px;
        }
      }
    }

    &__content {
      @include flexCol;
      gap: 8px;
      &__title,
      &__description {
        text-align: center;
        color: $neutral-1100;
      }
    }

    &__buttons {
      @include flexRow;
      gap: 24px;
      &__button {
        width: fit-content;
        padding: 0 20px;
        min-width: 140px;
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: $tablet) {
    .confirm-modal {
      width: 450px;
    }
  }
}
