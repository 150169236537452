.outer_box {
  border: 1px solid #ffe5d5;
  border-radius: 16px;
  padding: 2px;
  margin-bottom: 14px;
  position: relative;
  min-height: 266px;
  max-height: 266px;
  &.clickable {
    cursor: pointer;
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-40%, 70%);
    z-index: 9;
    svg {
      cursor: pointer;
    }
  }
  .playerBox {
    position: relative;
    height: 170px;
    border-radius: 16px;
    .viewFrame {
      height: 170px;
      width: 100%;
      border-radius: 16px;
    }
    .playerIcon {
      height: 34px;
      width: 34px;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(-60%, 45%);
      cursor: pointer;
    }
  }
  .marketplace_heading {
    padding: 12px;
    h4 {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .marketplace_content {
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 17.48px;
      margin-bottom: 0;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px 16px 16px 16px;
  }
}
