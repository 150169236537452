@import '../../assets/styles/index.scss';

.sign-up {
  @include flexCol;
  width: 100%;
  gap: 16px;
  padding: 0 0 64px 0;

  &__heading {
    color: $neutral-1000;
  }
  &__caption {
    color: $neutral-1000;
  }
  &__separator {
    @include flexRow;
    justify-content: space-between;

    gap: 24px;
    hr {
      flex: 1;
      background: none;
      height: 0.1px;
      border: 0.5px solid $neutral-300;
      transform: translateY(8px);
    }
    span {
      color: $neutral-600;
    }
  }
  &__form {
    @include flexCol;
    width: 100%;
    gap: 16px;
  }
  &__footer {
    @include flexRow;
    gap: 8px;
    color: $neutral-1000;
    a {
      @include bodyTwo;
      @include boldTypography;
      color: $primary-700;
    }
  }
}

.google-login-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;  // Adjust as needed
  border-radius: 10px;
  width: 100%; // Ensures it's taking full width of parent container
}

.google-login-button {
  width: 300px !important;  // Ensures consistent width
  min-width: 300px !important;
  max-width: 100%;
  border-radius: 50px !important; // More rounded corners
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  overflow: hidden; // Ensures no clipping issues
}

.log-in__or-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0;
  position: relative;

  .log-in__or-line {
    flex-grow: 1;
    height: 1px;
    background: #ccc;
  }

  .log-in__or-text {
    padding: 0 15px;
    font-size: 14px;
    color: #666;
    font-weight: 500;
    white-space: nowrap;
  }
}
