@import '../../../../assets/styles/index.scss';

@mixin textAreaTransition {
  transition: all 0.3s ease-out;
  --webkit-transition: all 0.3s ease-out;
}

.textarea-component {
  height: 120px;
  width: 90%;
  border-radius: 10px;
  border: 2px solid transparent;
  @include textAreaTransition;

  &:hover:not(&--disabled) {
    border: 2px solid $primary-300;
  }

  &__textarea {
    position: relative;
    height: 100%;
    width: 90%;
    resize: none;
    outline: none;
    border-radius: 8px;
    border: 1px solid $neutral-500;
    padding: 12px;
    color: $neutral-900;
    background: transparent;
    @include textAreaTransition;
    @include customScroll;

    &::placeholder {
      color: $neutral-500;
    }

    &:disabled {
      background: $neutral-100;
      color: $neutral-500;
    }

    &:hover:not(:disabled) {
      border: 1px solid $primary-400;
    }

    &--small {
      @include captionTypography;
      @include regularTypography;
      &::placeholder {
        @include captionTypography;
        @include regularTypography;
      }
    }
    &--medium {
      @include bodyTwo;
      @include regularTypography;
      &::placeholder {
        @include bodyTwo;
        @include regularTypography;
      }
    }
    &--large {
      @include bodyTwo;
      @include regularTypography;
      &::placeholder {
        @include bodyTwo;
        @include regularTypography;
      }
    }
  }
  &.noShow_focous{
    box-shadow: none !important;
    border: none !important;
    height: 180px !important;

    &:hover{
      border: none !important;
    }

  }
  .ql-container{
    height: 135px;
  }
}
