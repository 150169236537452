@import '../../../assets/styles/index.scss';

.search-recommend-user {
  @include flexRow;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px 0;

  &__profile-pic {
    height: 32px;
    width: 32px;
  }

  &__user-info {
    @include flexCol;
    color: $neutral-1100;
    gap: 4px;

    &__role {
      color: $neutral-900;
    }
  }
}
