@import '../../../../assets/styles/index.scss';

.create-company {
  &-view-service {
    @include flexCol;
    gap: 8px;
    &__header {
      @include flexRow;
      justify-content: space-between;
      color: $neutral-1000;

      &__actions {
        @include flexRow;
        gap: 16px;
        &__action {
          @include actionButton;
        }
      }
    }

    &__description {
      color: $neutral-900;
    }
  }
}
