@import '../../../assets/styles/index.scss';

.work-experience {
  @include flexCol;
  gap: 16px;
  &__header {
    @include flexRow;
    justify-content: space-between;
    gap: 8px;
    &__title {
      color: $neutral-1100;
    }
    &__action {
      width: 150px;
    }
  }

  &__companies {
    @include flexCol;
    gap: 16px;
  }
}
