@import '../../../../../../assets/styles/index.scss';

.dynamic-field {
  @include flexCol;
  gap: 8px;
.simple-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #ddd;
  }

  td {
    text-align: center;
  }
}

  @mixin checkboxRow {
    @include customCheckboxInput;
    @include flexRow;
    cursor: pointer;
    color: $neutral-900;
    align-items: center;
    gap: 8px;

    label {
      cursor: pointer;
    }
  }

  @mixin dropdownOptionsStyling {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;

    @media screen and (min-width: $desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__header {
    @include flexRow;
    justify-content: space-between;
    align-items: center;

    &__actions {
      @include flexRow;
      align-items: center;
      gap: 24px;

      &__required {
        @include checkboxRow;
      }
    }
  }

  &__field {
    @include flexCol;
    gap: 12px;

    &__character-limit-checkbox {
      @include checkboxRow;
    }

    &__character-limit {
      .input-component {
        width: 250px;
      }
    }

    &__dropdown-options {
      @include dropdownOptionsStyling;

      &__radio,
      &__checkbox {
        @include flexRow;
        align-items: center;
        gap: 8px;

        @include customRadioInput;
      }
    }

    &__add-option {
      width: 132px;
    }
  }

  &__upload-file {
    @include flexCol;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid $neutral-500;
    padding: 12px;
    gap: 8px;
    cursor: pointer;
    color: $neutral-700;

    &__drag-drop {
      @include flexRow;
      gap: 8px;
    }

    &__browse {
      color: $primary-600;
    }
  }
}
