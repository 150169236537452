@import '../../../assets/styles/index.scss';

.company-key-members {
  @include flexCol;
  gap: 16px;

  &__header {
    width: 100%;
    @include flexRow;
    justify-content: space-between;
    color: $neutral-1100;

    button {
      width: 128px;
    }
  }

  &__members {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    overflow: auto;
    padding-bottom: 100px;
  }
}

@media screen and (min-width: $tablet) {
  .company-key-members {
    &__members {
      grid-template-columns: 1fr 1fr;
    }
  }
}
