.onboard {
  .titleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .title {
      font-size: 16px;
      margin: 0;
    }
  }
  .inputFields {
    margin: 0 auto;
    margin-bottom: 14px;
  }
  .task {
    margin-top: 12px;

    .addTask {
      margin-top: 27px;
      display: flex;
      align-items: center;
      gap: 10px;
      .addRemoveIcon {
        cursor: pointer;
        background: #fa4616;
        color: #fff;
        height: 38px;
        width: 45px;
        border-radius: 12px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .actionButton {
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    .addMilestone {
      background: #fa4616;
      height: 34px;
      width: 65px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      &.update{
        min-width: 120px;
      }
      .addRemoveBtn {
        color: #fff;
      }
    }
  }
  :global(.insideCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto;
    padding: 12px 20px;
    margin-bottom: 14px;
  }
}
