@import '../../../assets/styles/index.scss';

.view-comment {
  @include flexCol;
  gap: 16px;

  &__content {
    @include flexRow;
    gap: 12px;
    &__left {
    }
    &__right {
      width: 100%;
      @include flexCol;
      gap: 4px;

      &__header {
        @include flexCol;
        padding: 8px;
        gap: 4px;
        background: $primary-100;
        border-radius: 0 12px 12px 12px;

        &__row {
          @include flexRow;
          align-items: center;
          justify-content: space-between;

          &__user-info {
            @include flexRow;
            align-items: center;
            gap: 10px;
            &__name {
              color: $neutral-1000;
            }
            &__role {
              color: $neutral-700;
            }
            &__lineBorder {
              width: 1px;
              background: $neutral-400;
              height: 18px;
            }
          }

          &__dropdown {
            position: relative;
            @include flexCenterContent;
            cursor: pointer;
            width: 34px;
            height: 28px;
          }
        }

        &__content {
          color: $neutral-1000;
          @include wrapText;
        }
      }

      &__interactions {
        @include flexRow;
        align-items: center;
        gap: 6px;
        &__like {
          width: fit-content;
        }
        &__replies {
          width: fit-content;
        }
      }
    }
  }

  &__replies {
    @include flexCol;
    gap: 4px;
    padding: 0 0 0 20px;
  }
}

@media screen and (min-width: $tablet) {
  .view-comment {
    &__replies {
      padding: 0 0 0 50px;
    }
  }
}
