.createEvent {
    .titleHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .title {
        font-size: 16px;
        margin: 0;
      }
    }
    .container {
      .inputFields {
        margin: 0 auto;
        margin-bottom: 14px;
      }
    }
    .dynamicFieldsBox{
      :global(.card-component){
        :global(.input-field-component){
          :global(.input-component){
            :global(.align-icon-right){
              display: none !important;
            }
          }
        }
      }
    }
  }
  