@import '../../../../assets/styles/index.scss';

.viewCohort {
  overflow: hidden;
  height: 100%;
  width: 100%;

  :global(.insideCard) {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ffe5d5;
    width: 100%;
    height: auto !important;
    padding: 20px 22px;
    margin-top: 0px;
    height: 85px;
    margin-bottom: 10px;
  }

  @include mainPageScroll;

  .body {
    @include flexCol;
    gap: 6px;
    padding: 16px;
    width: 100%;
    margin-top: 89px;

    .dashboard {
      :global(.emptyBoxlable) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 14px;
          margin: 0;
        }
      }

      h5 {
        font-size: 15px;
        font-weight: 500;
      }

      .dashboardCard {
        margin-bottom: 10px;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid #ffe5d5;
        width: 100%;
        position: relative;
        overflow: auto;
        padding: 20px;

        .emptyItem {
          font-size: 13px;
          font-weight: 500;
        }

        .milestoneHeading {
          border-bottom: 5px solid #ffffffa3;
          padding-bottom: 10px;
          margin-bottom: 3px;

          h5 {
            margin: 0;
            font-size: 16px;
          }
        }

        &.overflowNone {
          overflow: hidden;
        }

        &::-webkit-scrollbar {
          width: 6px;
          height: 5px;
          background: $primary-100;
          border-radius: 100px;
        }

        &::-webkit-scrollbar-button {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $primary-600;
          border-radius: 100px;
        }

        .dashboardCardItem {
          background-color: #fff;
          border-radius: 8px;
          height: 80px;
          width: 100%;
          border: 1px solid #ffe5d5;
          display: flex;
          gap: 10px;
          align-items: center;
          padding-top: 8px;
          flex-direction: column;
          margin-bottom: 14px;
          cursor: pointer;

          .dashboardItem {
            display: flex;
            flex-direction: column;
            width: 100%;

            span {
              text-align: center;
            }

            .itemNumber {
              color: #fa4616;
              font-weight: 500;
              font-size: 20px;
            }

            .itemName {
              font-size: 15px;
              font-weight: 500;
            }
          }
        }

        .stepper {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 14px;
        }

        .chatHeading {
          font-size: 15px;
          font-weight: 500;
          margin: 0;
          margin-bottom: 10px;
        }

        .activityList {
          margin: 0;
          padding: 0;
          list-style: none;
          padding: 0 8px;

          li {
            margin-bottom: 4px;
            font-size: 16px;

            &.emptyItem {
              font-size: 13px;
              font-weight: 500;
            }
          }
        }

        .chatBox {
          min-height: 250px;
          padding: 8px;

          .teamCard {
            background-color: #fffbf9;
            border-radius: 8px;
            padding: 8px 10px;
            min-height: 100px;
            height: auto;
            width: 100%;
            border: 1px solid #ffe5d5;
            display: flex;
            gap: 10px;
            align-items: center;
            margin-bottom: 14px;
            cursor: pointer;
          }
        }

        .milestonelist {
          margin: 0;
          padding: 0px;
          list-style: none;

          li {
            margin-bottom: 5px;

            :global(.milestoneBox) {
              display: flex;
              align-items: center;
              gap: 5px;

              span {
                min-width: 50%;
                max-width: 50%;

                &.mileStoneHeading {
                  font-size: 14px;
                  font-weight: 600;
                }
              }

              :global(.milestonePercentageCompletion) {
                min-width: 80px;
              }

              .inputFields {
                // width: 25%;
                min-width: 50px;
                max-width: 100px;

                .comments {
                  min-width: 80px;
                }
              }
            }

            .nestedList {
              padding: 0;

              margin: 0;
              list-style: none;
              margin-bottom: 12px;

              .task {
                margin-bottom: 4px;

                .taskHeading {
                  font-size: 14px;
                  padding-left: 15px;
                  font-weight: 600;
                }
              }
            }
          }
        }

        .highlight {
          background-color: #d4edda;
          /* light green background */
          border: 2px solid #28a745;
          /* green border */
        }

        :gloabl(.transparent) {
          background: transparent !important;
        }

        :global(.insideCard) {
          background: #fff;
          border-radius: 8px;
          border: 1px solid #ffe5d5;
          width: 100%;
          height: auto;
          padding: 30px;
          margin-bottom: 10px;
        }

        :global(.form_label) {
          font-family: 'Manrope', sans-serif;
          font-size: 12.8px;
          line-height: 17.48px;
          letter-spacing: 0em;
          font-weight: 600;
        }
      }
    }

    .actionButtons {
      margin-top: 15px;
      @include flexRow;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 11px;

      .addIcon {
        font-size: 18px;
        display: flex;
        align-items: center;
        gap: 2px;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .viewCohort {
    .body {
      padding: 32px;
      // width: 95%;
      width :  calc(100vw - (300px + (max(85px, calc((100vw - 1287px) / 2) - 39px) * 2) - 32px));
    }
  }
}

// <<<<<<<<<<media query>>>>>>>>>

@media only screen and (max-width: 768px) {
  .viewCohort {
    .body {
      margin-top: 160px;
      .actionButtons {
        justify-content: flex-start;
      }
    }
  }
}