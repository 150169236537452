@import '../../../assets/styles/index.scss';

.invite-notifications-card {
  @include flexRow;
  padding: 8px;
  gap: 16px;

  &__body {
    @include flexCol;
    gap: 4px;
    justify-content: center;

    &__title {
      color: $neutral-1000;
      // @include flexRow;
      @include lineClamp(1);
    }

    &__description {
      color: $neutral-800;
    }

    &__actions {
      @include flexRow;
      gap: 12px;
      margin-top: 8px;

      button {
        padding: 8px 16px;
        width: fit-content;
      }
    }
  }

  &__timestamp {
    color: $neutral-800;
    margin-left: auto;
    text-wrap: nowrap;
  }
}
