@import '../../../../assets/styles/index.scss';

.textarea-field-component {
  @include flexCol;
  &--small {
    gap: 8px;
  }
  &--medium {
    gap: 12px;
  }
  &--large {
    gap: 12px;
  }

  &__text-length {
    color: $neutral-500;
    text-align: right;
  }
}
