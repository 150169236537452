@import '../../../../assets/styles/index.scss';

.my-profile-contact-info {
  @include flexCol;
  gap: 12px;

  &__heading {
    color: $neutral-1000;
  }

  &__fields {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 12px;

    &__field {
      @include flexCol;
      gap: 12px;
      padding: 12px;
      border-radius: 12px;

      &__label {
        color: $neutral-1000;
      }
      &__value {
        color: $neutral-1000;
        &__linkedin {
          @include flexRow;
          align-items: center;
          img {
            height: 32px;
            width: 32px;
          }
          p {
            cursor: pointer;
          }
          @include flexRow;
          gap: 12px;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .my-profile-contact-info {
    &__fields {
      grid-template-columns: 1fr 1fr;
      &__field:last-child {
        grid-column-end: span 2; /* Make the address field span two columns */
      }
    }
  }
}
