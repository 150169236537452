@import '../../../assets/styles/index.scss';

.select-month-year-outer {
  .select-month-year {
    border-radius: 16px;
    padding: 8px;
    width: 336px;
    @include flexCol;
    gap: 8px;
    border: 1px solid $primary-200;
    box-shadow: $shadow-100;
    background: $white-100;

    &__heading {
      color: $neutral-1100;
      padding: 0 16px;
    }

    &__selection {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 105px;
      gap: 12px;

      &__col {
        @include flexCol;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          display: none;
        }
        height: 100%;
        &__item {
          height: 35px;
          padding: 8px 0;
          @include flexCenterContent;
          cursor: pointer;
          user-select: none;
          @include hoverOrange;
          color: $neutral-600;

          &--is-selected {
            color: $primary-600;
            background: $primary-100;
            border-bottom: 2px solid $primary-600;
            @include semiBoldTypography;
          }
        }
      }
    }

    &__buttons {
      @include flexRow;
      justify-content: flex-end;
      gap: 8px;

      button {
        width: 89px;
      }
    }
  }
}
