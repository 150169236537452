// header
.header_listing {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 36px;

    li {
      a {
        color: #303030;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        transition: 0.3s;

        &:hover {
          color: #ff5924;
        }
      }
    }
  }

  .closeButton {
    position: absolute;
    top: 4px;
    right: 12px;
    background: transparent;
    border: none;
    font-size: 26px;
    color: #ff5924;
    font-weight: 500;
  }
}

.loginSignUp_btn {
  :global(.btn) {
    border: none;
    background-color: #ff5924;
    color: #fff;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 23px;
    display: inline-flex;
    align-items: center;
    gap: 7px;
    text-decoration: none;
    line-height: normal;
    height: 48px;

    &:focus,
    &:active {
      background-color: #ff5924;
      color: #fff;
    }
  }
}

.header_listing {
  position: fixed;
  top: 0;
  left: -100%;
  width: 240px;
  height: 100%;
  background-color: #fff;
  z-index: 20;
  padding: 40px 28px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  &.menuOpen {
    left: 0; // Slide in for small screens
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column; // Vertical layout for small screens
    gap: 20px;

    li {
      a {
        color: #303030;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        transition: 0.3s;

        &:hover {
          color: #ff5924;
        }
      }
    }
  }

  @media (min-width: 992px) {
    position: static; // Reset position for large screens
    width: auto;
    height: auto;
    padding: 0;
    box-shadow: none;
    display: flex; // Horizontal layout for large screens
    background: none;

    .closeButton {
      display: none;
    }

    ul {
      flex-direction: row; // Horizontal layout
      gap: 36px; // Restore original gap
    }

    &.menuOpen {
      left: auto; // Prevent sliding in effect
    }
  }
}

.menuToggle {
  display: none;
  font-size: 22px;
  padding: 0 10px;
  margin-left: 2px;

  &:active, &:focus{
    border: 1px solid transparent !important;
  }

  @media (max-width: 991px) {
    display: block; // Show toggle button only for small screens
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Light gray overlay
  z-index: 899;
  transition: all 0.3s ease-in-out;

  @media (min-width: 992px) {
    display: none; // Hide backdrop for large screens
  }
}

// topBannerSec
.topBannerSec {
  padding: 180px 0px 80px;

  .content_box {
    h1 {
      color: #303030;
      font-size: 60px;
      font-weight: 400;
      line-height: 66px;
      margin-bottom: 30px;

      .boldHeading {
        font-weight: 700;
      }

      .arrowAnimate {
        // width: 100px;
        display: inline-flex;

        div {
          //   width: 0px !important;
          animation: arrow 4s !important;
        }
      }
    }

    p {
      line-height: normal;
      font-size: 20px;
      font-weight: 500;
      color: #303030;
      margin-bottom: 40px;
    }

    :global(.btn) {
      border: none;
      background-color: #ff5924;
      color: #fff;
      border-radius: 12px;
      font-size: 20px;
      font-weight: 600;
      padding: 18px 35px;
      display: inline-flex;
      align-items: center;
      gap: 7px;
      box-shadow: 0px 30px 30px 0px #ff59241a;
      text-decoration: none;
    }
  }

  .img_box {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

// marqueeSec
.marqueeSec {
  padding-bottom: 80px;

  .mainBox {
    display: flex;
    align-items: center;
    gap: 18px;

    .headingBox {
      min-width: 110px;
      max-width: 110px;

      h2 {
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        color: #8f8f8f;
        margin-bottom: 0;
      }
    }

    :global(.rfm-marquee-container) {
      &::before {
        position: absolute;
        content: '';
        left: 0;
        background: linear-gradient(90deg,
            #fafafa 3.72%,
            rgba(250, 250, 250, 0) 99.48%,
            rgba(250, 250, 250, 0) 100.02%,
            #fafafa 100%);
        width: 110px;
        height: 86px;
        z-index: 99;
      }

      &::after {
        position: absolute;
        content: '';
        right: 0;
        background: linear-gradient(270deg,
            #fafafa 3.72%,
            rgba(250, 250, 250, 0) 99.48%,
            rgba(250, 250, 250, 0) 100.02%,
            #fafafa 100%);
        width: 110px;
        height: 86px;
        z-index: 99;
      }
    }

    .imgBox {
      display: inline-block;
      margin: 0px 15px;
      min-width: 86px;
      max-width: 86px;
      height: 86px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

// world_rocketSec
.world_rocketSec {
  padding-bottom: 80px;

  .content_box {
    position: relative;
    background-color: #ffeee980;
    border: 1px solid #ffccbb;
    border-radius: 12px;
    padding: 40px 30px;
    overflow: hidden;

    h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      color: #303030;
      margin-bottom: 24px;
      position: relative;
      z-index: 1;

      span {
        color: #ff5924;
      }
    }

    p {
      color: #303030;
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0;
      max-width: 680px;
      position: relative;
      z-index: 1;
    }

    img {
      position: absolute;
      top: 0;
    }

    .img1 {
      right: 400px;
      width: 145px;
      top: -40px;
      z-index: 0;
      opacity: 0.25;
    }

    .img2 {
      right: 150px;
      width: 240px;
      top: 10px;
      z-index: 0;
      opacity: 0.5;
    }

    .img3 {
      right: -49px;
      width: 145px;
      top: 50px;
      z-index: 0;
      opacity: 0.25;
    }
  }
}

// dayZeroSec
.dayZeroSec {
  padding-bottom: 80px;

  .mainHeading_box {
    h2 {
      font-size: 40px;
      font-weight: 400;
      color: #303030;
      margin-bottom: 9px;

      span {
        font-weight: 700;
        color: #ff5924;
      }
    }

    p {
      color: #303030;
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
    }
  }

  .content_box {
    border: 1px solid #ffccbb;
    border-radius: 12px;
    padding: 24px 22px;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    transition: 0.4s;
    margin-top: 20px;

    &:hover {
      border: 1px solid #ff5924;
      background: linear-gradient(213deg, #ffffff 10%, #ffeee9 100%);

      .moving {
        transform: translateY(-15px);
      }

      .linkAttach {
        height: auto;

        .btns {
          opacity: 1;
        }
      }
    }

    .linkAttach {
      margin-top: 0px;
      line-height: normal;
      height: 0;
      transition: 0.4s;
      display: contents;
      text-align: center;

      .btns {
        text-decoration: none;
        color: #ff5924;
        font-weight: 700;
        font-size: 14px;
        line-height: normal;
        opacity: 0;
        transition: 0.4s;
      }
    }

    .moving {
      transform: translateY(20px);
      transition: 0.4s;

      img {
        width: 56px;
        height: 56px;
        object-fit: contain;
        margin-bottom: 26px;
      }

      h3 {
        font-size: 32px;
        font-weight: 700;
        color: #303030;
      }

      p {
        line-height: 18px;
        font-size: 12px;
        font-weight: 500;
        color: #8f8f8f;
        margin-bottom: 0px;
      }
    }
  }
}

// collabrationSec
.collabrationSec {
  padding-bottom: 80px;

  .imgAnimation_box {
    width: 100%;
    height: auto;
  }

  .content_box {
    h2 {
      color: #303030;
      font-size: 40px;
      font-weight: 400;
      line-height: 48px;
      margin-bottom: 30px;

      span {
        font-weight: 700;
        color: #ff5924;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        color: #303030;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 8px;

        span {
          font-weight: 700;
          color: #303030;
        }

        svg {
          margin-right: 14px;
        }
      }
    }
  }
}

// entrepreneurshipTabbingSec
.entrepreneurshipTabbingSec {
  padding-bottom: 60px;

  .mainHeading_box {
    margin-bottom: 36px;

    h2 {
      color: #303030;
      font-size: 40px;
      font-weight: 400;
      line-height: 48px;

      span {
        color: #ff5924;
        font-weight: 700;
      }
    }
  }

  :global(.nav) {
    :global(.nav-item) {
      margin-bottom: 14px;

      :global(.nav-link) {
        color: #fff;
        background: none;
        border: 2px solid #ffccbb;
        border-radius: 12px;
        padding: 28px 22px;

        figure {
          display: flex;
          align-items: center;
          gap: 20px;
          margin: 0;

          img {
            width: 96px;
            height: 96px;
            object-fit: contain;
          }

          figcaption {
            h3 {
              color: #303030;
              font-size: 32px;
              font-weight: 700;
              margin-bottom: 8px;
            }

            p {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              margin-bottom: 0px;
              color: #8f8f8f;
            }
          }
        }

        &:global(.active) {
          border: 2px solid #ff7a50;
          background: linear-gradient(193deg, #ffffff 10%, #ffeee9 100%);
        }
      }
    }
  }

  :global(.tab-content) {
    .tabContent_box {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

// founderSec

.founderSec {
  padding-bottom: 80px;

  figure {
    background: #ff5924;
    border-radius: 16px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 70px;
    padding: 40px 40px 0px 65px;
    margin-right: 10px;

    .imgBox {
      min-width: 320px;
      max-width: 320px;

      img {
        width: 100%;
        height: auto;
      }
    }

    figcaption {
      .labelling {
        color: #fff;
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;

        span {
          font-weight: 700;
        }
      }

      h2 {
        color: #fff;
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
        margin: 30px 0px;

        span {
          font-weight: 700;
        }
      }

      h3 {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      p {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0px;
        line-height: normal;
      }
    }
  }

  :global(.owl-carousel) {
    :global(.owl-dots) {
      margin-top: 7px;

      :global(.owl-dot) {
        :global(span) {
          margin: 5px 5px;
        }

        &:global(.active),
        &:hover {
          span {
            background: #ff5924;
          }
        }
      }
    }
  }
}

// latestPodcastSec
.latestPodcastSec {
  padding-bottom: 80px;

  .mainHeading_box {
    margin-bottom: 16px;

    h2 {
      color: #303030;
      font-size: 40px;
      font-weight: 400;
      line-height: 48px;

      span {
        color: #ff5924;
        font-weight: 700;
      }
    }
  }

  .video_main {
    display: flex;
    align-items: center;
    gap: 14px;
    border: 2px solid #d4d4d4;
    border-radius: 12px;
    padding: 20px;
    margin-top: 20px;

    .video_box {
      min-width: 194px;
      max-width: 194px;
      height: 194px;
      border-radius: 12px;
      overflow: hidden;

      video {
        width: 100%;
        height: 100%;
      }
    }

    .video_contentMain {
      .btnBadges {
        margin-bottom: 13px;

        span {
          border: 1px solid #d4d4d4;
          border-radius: 8px;
          display: inline-block;
          color: #8f8f8f;
          font-size: 12px;
          font-weight: 700;
          padding: 5px 9px;
          text-transform: uppercase;
        }
      }

      .content_box {
        text-decoration: none;
        color: #303030;

        &:hover {
          h3 {
            color: #ff5924;
          }
        }

        a {
          color: #ff5924;
        }

        h3 {
          font-size: 32px;
          font-weight: 700;
          line-height: 38px;
          color: #303030;
          margin-bottom: 16px;
          transition: 0.3s;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        p {
          color: #303030;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 0;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

// insightSec
.insightSec {
  padding-bottom: 80px;

  .mainHeading_box {
    margin-bottom: 16px;

    h2 {
      color: #303030;
      font-size: 40px;
      font-weight: 400;
      line-height: 48px;

      span {
        color: #ff5924;
        font-weight: 700;
      }
    }
  }

  .insightCard {
    display: block;
    text-decoration: none;
    color: #303030;
    border: 2px solid #d4d4d4;
    border-radius: 12px;
    padding: 20px;
    margin-top: 20px;
    transition: 0.3s;

    .btnBadges {
      margin-bottom: 13px;

      span {
        border: 1px solid #d4d4d4;
        border-radius: 8px;
        display: inline-block;
        color: #8f8f8f;
        background-color: #f1f1f1;
        font-size: 12px;
        font-weight: 700;
        padding: 5px 9px;
        text-transform: uppercase;
        transition: 0.3s;
      }
    }

    h3 {
      font-size: 32px;
      font-weight: 700;
      line-height: 38px;
      color: #303030;
      margin-bottom: 22px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      min-height: 78px;
    }

    p {
      color: #303030;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 0;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;

      a {
        color: #ff5924;
      }

      &.paraHeight {
        min-height: 144px;
        max-height: 144px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: #303030;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 0;
      }
    }

    .img_box {
      height: 250px;
      margin-bottom: 22px;
      border-radius: 12px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: 0.6s;
      }
    }

    &:hover {
      border: 2px solid #ff7a50;
      background: linear-gradient(193deg, #ffffff 6%, #ffeee9 100%);

      .btnBadges {
        span {
          border: 1px solid #ffded3;
          color: #ff7a50;
          background-color: #ffeee9;
        }
      }

      .img_box {
        img {
          transform: scale(1.3);
        }
      }
    }
  }
}

// readySec
.readySec {
  padding-bottom: 80px;

  .content_main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    background: #ffeee980;
    padding: 60px;
    border-radius: 16px;

    h2 {
      color: #303030;
      font-size: 40px;
      font-weight: 400;
      // line-height: 48px;
      margin-bottom: 0px;

      span {
        color: #ff5924;
        font-weight: 700;
      }
    }

    .btnBox {
      :global(.btn) {
        border: none;
        background-color: #ff5924;
        color: #fff;
        border-radius: 12px;
        font-size: 20px;
        font-weight: 600;
        padding: 18px 35px;
        display: inline-flex;
        align-items: center;
        gap: 7px;
        box-shadow: 0px 30px 30px 0px #ff59241a;
        text-decoration: none;
      }
    }
  }
}

@keyframes arrow {
  0% {
    width: 0%;
    opacity: 0;
  }

  100% {
    width: 100%;
    opacity: 1;
  }
}

// <<<<<<<<<<media query>>>>>>>>>

@media only screen and (max-width: 991px) {
  .loginSignUp_btn {
    :global(.btn) {
      font-size: 15px;
      font-weight: 500;
      padding: 6px 14px;
      height: 40px;
    }
  }

  // topBannerSec
  .topBannerSec {
    padding: 100px 0px 45px;

    .content_box {
      h1 {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;

        .arrowAnimate {
          svg {
            height: 23px !important;
          }
        }
      }

      p {
        font-size: 18px;
        margin-bottom: 25px;
      }

      :global(.btn) {
        font-size: 16px;
        padding: 12px 27px;
      }
    }

    .img_box {
      width: 270px;
      margin: auto;
      margin-top: 30px;
    }
  }

  .marqueeSec {
    padding-bottom: 40px;

    .mainBox {
      .headingBox {
        min-width: 130px;
        max-width: 130px;

        h2 {
          font-size: 14px;
        }
      }

      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  }

  .world_rocketSec {
    padding-bottom: 40px;

    .content_box {
      padding: 23px 17px;

      h2 {
        font-size: 26px;
        margin-bottom: 12px;
      }

      p {
        font-size: 16px;
      }

      .img2 {
        right: 310px;
        width: 130px;
      }

      .img3 {
        right: 6px;
        width: 75px;
      }
    }
  }

  .dayZeroSec {
    padding-bottom: 40px;

    .mainHeading_box {
      h2 {
        font-size: 26px;
      }

      p {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }

    .content_box {
      height: 220px;

      .moving {
        img {
          margin-bottom: 16px;
        }

        h3 {
          font-size: 23px;
          margin-bottom: 6px;
        }
      }
    }
  }

  .collabrationSec {
    padding-bottom: 40px;

    .content_box {
      margin-top: 20px;

      h2 {
        font-size: 26px;
        margin-bottom: 12px;
        line-height: normal;
      }

      ul {
        li {
          font-size: 16px;
        }
      }
    }

    .imgAnimation_box {
      width: 270px;
      margin: auto;
    }
  }

  .entrepreneurshipTabbingSec {
    padding-bottom: 30px;

    .mainHeading_box {
      margin-bottom: 18px;

      h2 {
        font-size: 26px;
        line-height: normal;
      }
    }

    :global(.nav) {
      :global(.nav-item) {
        :global(.nav-link) {
          padding: 18px 22px;

          figure {
            img {
              width: 66px;
              height: 66px;
            }

            figcaption {
              h3 {
                font-size: 23px;
                margin-bottom: 6px;
              }

              p {
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  .founderSec {
    padding-bottom: 40px;

    figure {
      flex-direction: column;
      gap: 30px;
      padding: 20px 22px;

      .imgBox {
        min-width: 170px;
        max-width: 170px;
      }

      figcaption {
        .labelling {
          font-size: 16px;
          line-height: 20px;
        }

        h2 {
          font-size: 26px;
          line-height: normal;
          margin: 10px 0px 16px;
        }

        h3 {
          font-size: 21px;
          margin-bottom: 2px;
        }

        p {
          font-size: 17px;
        }
      }
    }
  }

  .latestPodcastSec {
    padding-bottom: 40px;

    .mainHeading_box {
      margin-bottom: 0px;

      h2 {
        font-size: 26px;
        line-height: normal;
        margin-bottom: 0px;
      }
    }

    .video_main {
      flex-direction: column;
      align-items: flex-start;

      .video_box {
        min-width: 100%;
        max-width: 550px;
        margin: auto;
      }

      .video_contentMain {
        .content_box {
          h3 {
            font-size: 23px;
            line-height: normal;
            margin-bottom: 9px;
          }

          p {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .insightSec {
    padding-bottom: 40px;

    .mainHeading_box {
      margin-bottom: 0px;

      h2 {
        font-size: 26px;
        line-height: normal;
        margin-bottom: 0px;
      }
    }

    .insightCard {
      h3 {
        font-size: 23px;
        line-height: normal;
        min-height: auto;
      }
    }
  }

  .readySec {
    padding-bottom: 40px;

    .content_main {
      padding: 20px 22px;

      h2 {
        font-size: 26px;
        line-height: normal;
      }

      .btnBox {
        :global(.btn) {
          font-size: 16px;
          padding: 12px 27px;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .entrepreneurshipTabbingSec {
    :global(.nav) {
      :global(.nav-item) {
        :global(.nav-link) {
          figure {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
          }
        }
      }
    }
  }
}