@import '../../../../../assets/styles/index.scss';

.apply-program-applicant-details {
  @include flexCol;
  padding: 16px;
  gap: 16px;

  &__form {
    @include flexCol;
    gap: 16px;
  }
}

@media screen and (min-width: $desktop) {
  .apply-program-applicant-details {
    width: 400px;
    flex-shrink: 0;
  }
}
