@import '../../assets/styles/index.scss';

.set-new-password {
  @include flexCol;
  width: 100%;
  gap: 16px;

  &__heading {
    color: $neutral-1000;
  }
  &__caption {
    color: $neutral-1000;
  }
  &__form {
    @include flexCol;
    width: 100%;
    gap: 16px;

    &__verification-code {
      @include flexCol;
      gap: 12px;
      &__input label {
        font-size: 12.8px;
        line-height: 17.48px;
      }
      &__resend-code {
        @include flexRow;
        justify-content: space-between;
        color: $primary-600;
        &__link {
          cursor: pointer;
          text-decoration: $primary-200 underline 0.1px;
          &--disabled {
            color: $neutral-600;
            pointer-events: auto;
          }
        }
      }
    }

    &__hr {
      border: 1px solid $primary-200;
    }
  }
}
