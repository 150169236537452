@mixin hoverOrange {
  &:hover {
    background: $primary-200;
    color: $primary-600;
    svg path {
      stroke: $primary-600;
    }
  }
}

@mixin actionIconHoverBehavior {
  @include flexCenterContent;
  @include hoverTransition;
  @include hoverOrange;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
