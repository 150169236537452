.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
    row-gap: 8px;
    .cohortName{
      font-size: 16px;
      margin: 0;
    }
    .dates{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      row-gap: 8px;
      p{
        font-size: 14px;
        font-weight: 500;
        margin: 0;
      }
    }
  }