.BannerOuter {
  background-image: url('../../../assets//images//venture/venture_banner.png');
  padding: 15px;
  border-radius: 16px;
  border: 1px solid #ffe5d5;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right, bottom;

  h3 {
    font-size: 24px;
    font-weight: 800;
    color: #fff;
    width: 60%;
    margin: 0px 0px 5px;
  }
  p {
    font-size: 9px;
    color: #fff;
    font-weight: 500;
    width: 60%;
    margin: 0px 0px 10px;
  }
  button {
    font-size: 10.8px;
    line-height: 17.48px;
    letter-spacing: 0em;
    margin: 0;
    font-weight: 500;
    margin: 0;
    gap: 8px;
    height: 34px;
    padding: 0 16px;
    border-radius: 100px;
    background: #ffffff;
    color: #fa4616;
    border: 0px solid #fa4616;
  }
}

.venturesListedOuter {
  padding: 12px 18px;
  border-radius: 16px;
  border: 1px solid #ffe5d5;
  background: #fff;

  .listInnter {
    display: flex;
    justify-content: center;
    gap: 60px;
    margin-bottom: 30px;
  }
  p {
    width: 50%;
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
    color: #535353;
  }
  span {
    display: block;
    font-weight: 700;
    color: #1d1d1d;
    font-size: 16px;
    margin-top: 10px;
  }
  .listInnter:last-child {
    margin-bottom: 0px;
  }
}
