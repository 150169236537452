@import '../../../assets/styles/index.scss';

.no-registered-users {
  background: url('../../../assets/images/banner/nonRegisteredUsersBg.png') no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  @include flexCenterContent;

  &__body {
    @include flexCol;
    @include flexCenterContent;
    gap: 24px;
    padding: 24px;
    background: $white-100;
    border-radius: 16px;
    width: 90%;
    height: 90%;

    img {
      height: 50px;
    }

    &__title {
      color: $neutral-1100;
      @include semiBoldTypography;
      @include subHeadingOne;
    }

    button {
      width: 350px;
    }

    &__footer {
      @include flexRow;
      align-items: center;
      gap: 8px;
      color: $neutral-1000;

      &__sign-up {
        @include bodyTwo;
        @include boldTypography;
        color: $primary-700;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .no-registered-users {
    &__body {
      width: 500px;
      height: 500px;
    }
  }
}
