@import '../../../../../assets/styles/index.scss';

.program-details {
  @include flexCol;
  gap: 16px;
  color: $neutral-1100;

  &__form {
    @include flexCol;
    @include hideCalendarIcon;
    gap: 16px;

    &__header {
      @include flexRow;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;

      &__navigation {
        @include flexRow;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        button {
          width: 128px;
        }
      }
    }

    &__row {
      @include flexCol;
      gap: 16px;

      &__field {
        flex: 1;
      }
    }

    &__stages {
      @include flexCol;
      gap: 16px;

      input {
        @include hideInputNumberFieldArrow;
      }
    }

    &__add-field {
      width: 127px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .program-details {
    gap: 24px;

    &__form {
      gap: 24px;
      &__row {
        @include flexRow;
        gap: 24px;
      }
    }
  }
}
