@import '../../../../../assets/styles/index.scss';
.simple-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #ddd;
  }

  td {
    text-align: center;
  }
}
.program-application-section-view {
  @include flexCol;
  gap: 16px;

  &__title {
    color: $neutral-1100;
  }
&_printIcon {
  width: 4%
}
&_printIconContainer {
  align-items: end
}
  &__fields {
    @include flexCol;
    gap: 16px;

    &__field {
      @include flexCol;
      gap: 8px;

      &__value {
        color: $neutral-1000;
      }
    }
  }
}
