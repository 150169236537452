$muiSwitchWidth: 48px;
$muiSwitchThumbSize: 20px;
$muiSwitchThumbTranslate: $muiSwitchWidth - $muiSwitchThumbSize - 2;

.greenSwitch {
    position: relative;
    min-width: 52px;
    max-width: 52px;
    height: 23px;
    padding: 2px 9px;
    border-radius: 100px;
    cursor: pointer;
    overflow: hidden;
    background-color: #f1dada;
    transition: background-color 0.3s ease; // Smooth transition
  
    .toggleInput {
      display: none;
    }
  
    .toggleLabel {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  
    .toggleThumb {
      position: absolute;
      top: 2px;
      left: 4px;
      padding: 9px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transition: transform 0.3s ease, background-color 0.3s ease;
      cursor: default;
    }
  
    .toggleInput:checked ~ .toggleThumb {
      transform: translateX($muiSwitchThumbTranslate);
      background-color: #fff !important;
      cursor:default;
    }
  
    .toggleInput:checked ~ .toggleLabel {
      background-color: #fa4616 !important;
      cursor: pointer;
    }
  }
  