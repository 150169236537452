@import '../../../../assets/styles/index.scss';

.company-info {
  display: grid;
  grid-template-areas: 'about' 'founded' 'industry';
  grid-template-columns: 1fr;
  &__about {
    grid-area: about;
    word-break: break-all;
  }
  &__founded {
    grid-area: founded;
  }
  &__industry {
    grid-area: industry;
  }
  gap: 12px;
  color: $neutral-1000;
}

@media screen and (min-width: $tablet) {
  .company-info {
    grid-template-areas:
      'about about'
      'founded industry';
    grid-template-columns: 1fr 1fr;
  }
}
