@import '../../../../../../assets/styles/index.scss';

.view-program-submitted-applications {
  .search-submitted-applications {
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    &__search {
      @include flexCol;
      gap: 12px;
      width: 100%;

      button {
        width: 140px;
      }
    }

    &__filter {
      @include flexRow;
      align-items: center;
      gap: 8px;
      color: $neutral-1100;

      &__dropdown {
        position: relative;

        button {
          width: 140px;
        }

        .dropdown-component {
          min-width: 140px;
          top: calc(100% + 5px);
          right: 0px;
        }
      }
    }
  }

  @media screen and (min-width: $tablet) {
    .search-submitted-applications {
      &__search {
        @include flexRow;
        align-items: center;
        width: initial;

        &__input-field {
          width: 400px;
        }

        button {
          width: 140px;
        }
      }
    }
  }
}
