@import '../../assets/styles/index.scss';

.protected-layout {
  @include flexRow;

  &__right {
    @include flexCol;
    width: 100%;
    overflow: hidden;
    height: 100dvh;
    overflow-y: auto;
  }
}

@media screen and (min-width: $desktop) {
  .protected-layout {
    background: $background-100;
    // max-width: 1440px;
    width: 100%;
    margin: 0 auto;
  }
}
