@import '../../../assets/styles/index.scss';

@mixin editProfileGridStyle {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.add__service-modal {
  .modal-component {
    position: relative;
  }
  .modal-component__content {
    margin-bottom: 44px;
    overflow: hidden !important;
  }
}

.add__Service {
  min-height: 350px;
  
  &__form {
    @include flexCol;
    gap: 12px;

    &__container-crate-form {
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: calc(100vh - 220px);
    }

    &__buttons {
      @include flexRow;
      justify-content: center;
      gap: 8px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      background: #fff;
      button {
        width: 150px;
      }
    }
  }
}
