@import '../../assets/styles/index.scss';

.settings {
  overflow: hidden;
  height: 100%;
  @include mainPageScroll;

  &__body {
    @include flexCol;
    gap: 24px;
    padding: 16px;
    margin-top: 89px;

    &__sub-sections {
      @include flexCol;
      gap: 16px;

      &__menu {
        @include flexRow;
        gap: 8px;
        flex-wrap: wrap;
        display: none;

        &__item {
          height: 43px;
          border-radius: 8px;
          width: fit-content;
          padding: 8px;
        }
      }
      &__component {
        flex: 1;
      }
    }
  }
}

@media (max-width:768px) {
  .settings{
    &__body{
      margin-top: 160px;
    }
  }
}

@media screen and (min-width: $desktop) {
  .settings {
    &__body {
      padding: 32px;
      // width: 95%;
      width :  calc(100vw - (300px + (max(85px, calc((100vw - 1287px) / 2) - 39px) * 2) - 32px));

      &__sub-sections {
        @include flexRow;
        gap: 32px;

        &__menu {
          @include flexCol;
          width: 260px;
          padding: 16px;
          display: none;

          &__item {
            width: 100%;
            padding: 12px 16px;
          }
        }
      }
    }
  }
}
