@import '../../../../../assets/styles/index.scss';

.view-program-program-details {
  @include flexCol;
  gap: 16px;
  &__flex {
  display: flex;
  }
  &__field {
    @include flexCol;
    gap: 8px;

    &__label {
      color: $neutral-1100;
    }

    &__value {
      color: $neutral-1000;
      &_editor{
        img{
          max-width: 100%;
        }
        p, h1, h2, h3,h4, h5, span{
          margin-bottom: 0
        }
      }
    }

    &__files {
      @include flexRow;
      gap: 32px;
      flex-wrap: wrap;
    }
  }
}
